<template>
  <div>
    <div class="flex v-center" style="margin-bottom: 2em">
      <div class="input-with-icon right input-group" style="width: 40%; min-width: 5em;" v-if="filterable">
        <b-form-input v-model="filterStr" debounce="400" style="font-size: 1em"></b-form-input>
        <span>
          <b-icon-search></b-icon-search>
        </span>
      </div>
    </div>
    <b-table v-bind="[$attrs, $props]" ref="table" @refreshed="dataUpdated" @filtered="dataUpdated" :per-page="isPaginated ? perPage : 0" :current-page="currentPage" :filter="filterStr">
      <template v-for="(_, name) of $slots" v-slot:[name]>
        <slot :name="name"></slot>
      </template>
      <template v-for="(_, name) of $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </b-table>
    <pagination v-model="currentPage" v-if="isPaginated" :per-page="perPage" :total-rows="totalItems" first-number last-number></pagination>
  </div>
</template>

<script>
import {BTable, BFormInput} from "bootstrap-vue";
import Pagination from "@/components/Pagination";
import _ from "underscore";

export default {
  name: "PaginatedTable",
  components: {
    BTable,
    BFormInput,
    Pagination
  },
  props: {
    items: {
      type: [Array, Function],
      default: () => []
    },
    filterable: {
      type: Boolean,
      default: false
    },
    isPaginated: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentPage: 1,
      perPage: 25,
      totalItems: undefined,
      filterStr: undefined
    }
  },
  methods: {
    dataUpdated () {
      this.totalItems = _.size(this.$refs.table.filteredItems);
    }
  },
  watch: {
    items: {
      handler: function (newVal) {
        if (_.isArray(newVal)) this.totalItems = _.size(this.items);
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
