<template>
  <div class="platform flex">
    <div class="logo flex"><b-img v-if="platform.image" :src="getImageURLByType(platform.image.File, 0, 'PLATFORM')" fluid :alt="platform.abbr" height="12px" ></b-img></div>
    <span v-if="!iconOnly"> {{platform[field]}}</span>
  </div>
</template>

<script>
import {BImg} from 'bootstrap-vue';
import _ from 'underscore';

export default {
  name: 'PlatformComponent',
  components: {
    BImg
  },
  props: {
    platformId: {
      type: Number,
      required: true
    },
    iconOnly: {
      type: Boolean,
      default: false
    },
    field: {
      type: String,
      default: "name"
    }
  },
  computed: {
    platform () {
      return _.findWhere(this.platformsList, { id: this.platformId }) || {};
    }
  }
}
</script>

<style scoped>
.platform, .logo {
  align-items: center;
}

.platform {
  color: #BEBCBC;
  font-size: 0.9rem;
}

.logo {
  height: 100%;
  max-width: 25px;
  min-width: 20px;
  max-height: 90%;
  margin-right: 8px;
}

.logo > img {
  max-height: 25px;
}
</style>