<template>
  <div style="height: 100%; max-height: 100%">
    <ul class="nav nav-tabs dark">
      <li class="nav-item" v-for="tool in $options.tools" :key="tool.title">
        <router-link class="nav-link" active-class="active" exact-active-class="exact-active" v-slot="{href, route, navigate, isActive, isExactActive}" :to="{name: tool.to}" custom>
          <a :href="href" :class="{active: isActive, 'exact-active': isExactActive || $route.path === route.path}" @click="navigate" style="font-size: 1.2rem">{{tool.title}}</a>
        </router-link>
      </li>
    </ul>
    <div class="tab-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "DataCleanupTools",
  tools: [
    {
      title: "Regionification",
      to: "Regionification"
    },
    {
      title: "Pricification",
      to: "Pricification"
    },
    {
      title: "VGPCfication",
      to: "VGPCfication"
    },
    {
      title: "TGDB Queue",
      to: "TGDBQueue"
    },
    {
      title: "Data Queue",
      to: "DataQueue"
    },
    {
      title: "Recycle Bin",
      to: "DeletedItems"
    },
    {
      title: "Company Management",
      to: "CompaniesManager"
    }
  ]
}
</script>

<style scoped>
.tab-content {
  flex-grow: 1;
  max-height: calc(100% - 40px);
  height: calc(100% - 40px);
}
.nav-tabs {
  height: 40px;
}

::v-deep .browse-container > .g-container {
  max-width: none;
}
</style>
