<template>
  <b-modal v-bind="$attrs" v-on="$listeners" v-model="lValue" @hidden="modalHidden">
    <slot></slot>
    <template #modal-footer="{ ok, cancel }">
      <div class="error" style="font-size: 0.8em" v-if="saveError">{{saveError}}</div>
      <b-button v-if="!saveComplete" variant="secondary" @click="cancel()" :disabled="$refs.save ? $refs.save.loading : false">
        Cancel
      </b-button>
      <loading-button :variant="okVariant" :async-func="_asyncFunc" @saved.prevent="saved(ok)" :disabled="saveDisabled" ref="save">{{!saveComplete ? okTitle : 'Done'}}</loading-button>
    </template>
  </b-modal>
</template>

<script>
import {BModal, BButton} from "bootstrap-vue";
import LoadingButton from "@/components/LoadingButton";

export default {
  name: "AsyncSaveModal",
  components: {
    BModal,
    BButton,
    LoadingButton
  },
  props: {
    asyncFunc: {
      type: Function,
      required: true
    },
    okVariant: {
      type: String,
      default: "primary"
    },
    okTitle: {
      type: String,
      default: "Save"
    },
    saveDisabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: Boolean
    },
    closeOnOk: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      saveError: undefined,
      saveComplete: false
    }
  },
  computed: {
    lValue: {
      get() { return this.value; },
      set(lValue) {this.$emit('input', lValue)}
    }
  },
  methods: {
    modalHidden () {
      this.saveComplete = false
      this.$emit('hidden')
    },
    saved (okFunc) {
      if (!this.saveError) {
        if (this.closeOnOk || this.saveComplete) okFunc();
        if (!this.saveComplete) this.$emit('saved');
        this.saveComplete = true;
      }
    },
    _asyncFunc () {
      if (!this.saveComplete) {
        return this.asyncFunc()
          .catch(e => {
            console.error('error saving', e);
            this.saveError = "There was an issue saving. Check the logs for info";
          })
      } else return Promise.resolve()
    }
  }
}
</script>