<template>
  <div :class="['flex comparison-item', {'flex-col vertical': vertical, 'right': alignRight, 'editable': editable}]">
    <div style="flex-grow: 1; padding: 0 16px" class="flex-col">
      <div :class="['flex-col', { 'align-right': alignRight, 'align-center': vertical }]">
        <editable-field :editable="editable" :async="false" class="value"
          @save="updateField($event, {key: 'title'})"
          field-name="title"
          :field-value="getFieldValue({key: 'title'}, overriddenObj.title)"
          required>
            <div class="title" :style="{ color: fieldIsOverridden({key: 'title'}) ? 'var(--success-text)' : undefined }">{{getFieldValue({key: 'title'}, overriddenObj.title)}}</div>
        </editable-field>
        <div v-if="fieldIsOverridden({key: 'title'})" class="strike" style="font-size: 1rem">{{item.title}}</div>
      </div>
      <div :class="['flex', { 'align-right': alignRight, 'align-center': vertical }]" style="align-items: baseline" v-if="!hideAlts">
        <div class="grey italic" style="font-size: 0.75em">Alts:&nbsp;&nbsp;</div>
        <editable-field :editable="editable" :async="false" class="value"
          @save="updateField($event, {key: 'alt_titles'})"
          field-name="alt_titles"
          :field-value="getFieldValue({key: 'alt_titles'}, overriddenObj.alt_titles)"
          type="multi-text">
            <div class="grey italic" :style="{ color: fieldIsOverridden({key: 'alt_titles'}) ? 'var(--success-text)' : undefined }"><span v-html="getFieldDisplayValue({key: 'alt_titles'}, overriddenObj.alt_titles)"></span></div>
        </editable-field>
      </div>
      <div :class="['flex platform', { 'align-right': alignRight, 'align-center': vertical }]" v-if="item.platform_id">
        <editable-field :editable="editable" :async="false" class="value"
          required
          field-name="platform_id"
          :field-value="getFieldValue(PLATFORM_FIELD, overriddenObj.platform_id)"
          @save="updateField($event, PLATFORM_FIELD)"
          type="select"
          :select-config="{ options: platformsList }">
          <div :class="{ strike: fieldIsOverridden(PLATFORM_FIELD) }">
            <platform :platform-id="item.platform_id" :field="fieldIsOverridden(PLATFORM_FIELD) ? 'abbr' : 'name'"></platform>
          </div>
          <div v-if="fieldIsOverridden(PLATFORM_FIELD)" style="margin-left: 8px">
            <platform :platform-id="overriddenObj.platform_id" style="color: var(--success); font-weight: bold;"/>
          </div>
          <template v-slot:option="opt">
            <platform :platform-id="opt.id"></platform>
          </template>
          <template v-slot:singleLabel="opt">
            <platform :platform-id="opt.id" field="short"></platform>
          </template>
        </editable-field>
      </div>
      <div class="fields">
        <div v-for="(field, idx) in fields" :key="item.title + field.key + idx" :class="['field flex', field.class, { 'align-right': alignRight || showLabels}]">
          <span v-if="showLabels" class="label">
            {{field.label}}
            <span v-if="editable && getFieldIsRequired(field)" title="required">*</span>
          </span>
          <editable-field class="value flex" style="flex-grow: 1"
            :editable="editable" :async="false"
            @save="updateField($event, field)"
            :field-name="field.key.toString()"
            :field-value="getFieldValue(field, get(overriddenObj, field.key))"
            :type="field.type"
            :select-config="field.options ? { options: field.options(), value: field.value, label: field.idListField, getIconImg: field.getIconImg } : undefined"
            :required="getFieldIsRequired(field)">
            <div v-html="getFieldDisplayValue(field)" :class="['display-val', { strike: fieldIsOverridden(field) }]"></div>
            <div v-if="fieldIsOverridden(field)" v-html="getFieldDisplayValue(field, get(overriddenObj, field.key))" style="margin-left: 8px; color: var(--success-text)" class="bold primary override-val"></div>
            <template slot="edit-btn">
              <b-button variant="secondary" style="color: var(--background-color)" size="sm"><b-icon-pencil /></b-button>
            </template>
          </editable-field>
        </div>
      </div>
    </div>
    <div :style="[{'max-width': vertical ? undefined: '30%'}]">
      <g-img :image-object="item.image" fluid :src="item.image_url" hide-blur-hash />
    </div>
  </div>
</template>

<script>
import {BIconPencil} from "bootstrap-vue";
import GImg from "@/components/GImage";
import Platform from "@/components/Platform";
import EditableField from "@/components/EditableField";
import _ from "lodash";

export default {
  name: "ComparisonDisplayItem",
  components: {BIconPencil, GImg, Platform, EditableField},
  props: {
    item: {
      // expects: title, platform_id, image OR image_url, and values matching fields definition list
      type: Object,
      required: true
    },
    fields: {
      // expects list of objects defining fields: { key: ..., func (optional): ..., type (optional): ...}
      type: Array,
      required: true
    },
    alignRight: {
      type: Boolean,
      default: false
    },
    vertical: {
      type: Boolean,
      default: false
    },
    showLabels: {
      type: Boolean,
      default: false
    },
    editable: {
      type: Boolean,
      default: false
    },
    hideAlts: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      overriddenObj: {},
      editingIsValid: false,
      PLATFORM_FIELD: {
        key: 'platform_id',
        type: 'select',
        func: pId => { return this.parseListForMatchingId(this.platformsList, pId) }
      }
    }
  },
  mounted () {
    this.$emit('valid', this.getEditingValid());
  },
  methods: {
    getEditingValid () {
      let allRequired = _.reduce(this.fields, (result, field) => {
        let required = this.getFieldIsRequired(field);
        let val = this.getValueOrOverridden(field.key);
        return result && (!required || (val !== undefined && val !== null && (!_.isEmpty(val) || _.isNumber(val))))
      }, true);
      return !_.isEmpty(this.item.title) && allRequired;
    },
    getValueOrOverridden (key) {
      let val = this.get(this.item, key);
      let overriddenVal = this.get(this.overriddenObj, key);
      return overriddenVal !== undefined ? overriddenVal : val;
    },
    getFieldIsRequired (field) {
      if (!_.isFunction(field.required)) return field.required;
      else {
        return field.required(field, _.mapValues(this.item, (val, key) => {
          return this.getValueOrOverridden(key);
         }))
      }
    },
    get (object, key) {
      return _.get(object, key);
    },
    getValue (val, field) {
      let func = field.func;
      return func ? func(val) : val;
    },
    getFieldValue (field, value) {
      let val = value !== undefined ? value : _.get(this.item, field.key);
      return this.getValue(val, field);
    },
    getFieldDisplayValue (field, value) {
      let val = this.getFieldValue(field, value);
      if (val === null || val === "" || val === undefined || val === "none" || _.isNaN(val) || (_.isArray(val) && _.isEmpty(val)))
        return `<span class='italic ${this.editable && this.getFieldIsRequired(field) ? 'error' : 'grey'}'>${field.emptyPlaceholder || 'None'}</span>`
      if (field.type === "date") return this.$options.filters.date(val);
      else if (field.type === "select") return _.isObject(val) ? val[field.idListField || "name"] : val;
      else if (field.type === "multiselect") return _.map(val, field.idListField || "name").toString();
      else return val;
    },
    updateField (value, field) {
      this.overriddenObj = _.merge({}, _.set(this.overriddenObj, field.key, value));
      this.$emit('valid', this.getEditingValid());
      this.$emit('overridden', this.overriddenObj);
    },
    fieldIsOverridden (field) {
      const overriddenObj = this.overriddenObj;
      if (!_.has(overriddenObj, field.key)) return false;
      let overrideVal = _.get(overriddenObj, field.key);
      if (_.isArray(overrideVal)) {
        return !_.isEqual(this.getValue(overrideVal, field), this.getFieldValue(field));
      } else
        return this.getValue(overrideVal, field) !== this.getFieldValue(field);
    }
  }
}
</script>

<style lang="scss" scoped>
.comparison-item {
  &.editable .field {
    > .label, .display-val, .override-val {
      line-height: 1.75em;
    }
  }
  &.right {
    flex-direction: row-reverse;
  }
  &.vertical {
    flex-direction: column-reverse;
  }
  .align-right {
    .value {
      justify-content: right;
    }
  }
  &:not(.vertical) {
    .title {
      height: 1.325em;
    }
  }
  .title {
    font-size: 2em;
  }
  .platform {
    height: 40px;
  }
  .field {
    padding: 5px 8px;
    > .label {
      font-size: 0.7em;
      font-weight: bold;
      line-height: 1.75em;
    }
  }
  .fields .field:nth-child(odd) {
    background-color: var(--list-item-footer-bg);
  }
}

.vertical ::v-deep .img-fluid {
  max-height: 320px;
}
</style>
