import { render, staticRenderFns } from "./Regionification.vue?vue&type=template&id=4f981ea6&scoped=true"
import script from "./Regionification.vue?vue&type=script&lang=js"
export * from "./Regionification.vue?vue&type=script&lang=js"
import style0 from "./Regionification.vue?vue&type=style&index=0&id=4f981ea6&prod&lang=scss&scoped=true"
import style1 from "@vueform/slider/themes/default.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f981ea6",
  null
  
)

export default component.exports