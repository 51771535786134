
<template>
  <div :class="scoreClass">
    <h6>{{ _score }}</h6>
  </div>
</template>

<script>
import _ from "underscore";

export default {
  name: "MetaScore",
  props: {
    score: {
      type: Number
    }
  },
  computed: {
    scoreClass () {
      if (this.score >= 75)
        return 'good'; // Green color
      else if (this.score >= 50)
        return 'medium'; // Yellow color
      else if (_.isNumber(this.score))
        return 'bad'; // Red color
      else
        return 'none';
    },
    _score () {
      return _.isNumber(this.score) ? this.score : 'NA';
    }
  }
};
</script>

<style scoped>
div {
  padding: 5px;
  min-width: 35px;
  min-height: 35px;
  width: 35px;
  height: 35px;
  display: flex;
  border-radius: 4px;
}

h6 {
  margin: auto;
}

.good {
  background-color: #66CC33FF;
}

.medium {
  background-color: #FFCC33FF;
}

.bad {
  background-color: #FF0000FF;
}

.none {
  background-color: var(--grey);
}
</style>