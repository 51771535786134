<template>
  <div class="flex-col center">
    <div style="margin-top: 15px; max-width: 1200px">
      <h2 class="danger" style="margin: 20px 15px 10px"><b-icon icon="trash-fill" />  Deleted Items</h2>
      <browse :override-params="{state: ITEM_STATES.DELETED}" sort="modified_asc" searchable :filters-in-url="false"
        hide-display-type-options hide-categories-filter hide-filters
        category="all" :store="store" ref="browse">
        <template v-slot:afterListItem="{item}">
          <div class="grey italic">Last Modified: {{item.updated_at | date}}</div>
        </template>
        <template v-slot:inlineAfterListItem="{item}">
          <div class="flex-col h-100 actions">
            <b-button size="lg" variant="primary" style="flex-grow: 3" v-b-modal.confirm-undo @click="undoSelected = item">
              <div><b-icon icon="arrow-counterclockwise"/></div>
              Undo Delete
            </b-button>
            <b-button variant="danger" style="flex-grow: 1" disabled><b-icon icon="trash-fill" />  Delete Forever</b-button>
          </div>
        </template>
      </browse>
    </div>
    <b-modal id="confirm-undo" title="Are you sure you want to restore this item?" @ok="undoDelete(undoSelected)">
      <div v-if="undoSelected" style="margin-top: 15px">
        <list-item :item="undoSelected" display-type="grid" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import Browse from "@/views/Browse";
import ListItem from "@/components/EncyclopediaListItem";
import browsePageProps from '@/mixins/BrowsePageProps';
import {ITEM_STATES} from '@/store/constants.js'

export default {
  name: "RecycleBin",
  mixins: [browsePageProps],
  components: {
    Browse,
    ListItem
  },
  data () {
    return {
      ITEM_STATES: ITEM_STATES,
      undoSelected: undefined
    }
  },
  methods: {
    undoDelete (item) {
      this.$store.dispatch('updates/editState', { item_id: item.id, item_state: ITEM_STATES.GOOD })
      .then(() => {
        this.$refs.browse.getItems();
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .list > .items {
  row-gap: 0;
  .item-wrapper:not(:first-child) {
    margin-top: 30px;
  }
  .item {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}

::v-deep .item-wrapper {
  .item {
    .main {
      opacity: 0.9;
      background-color: #ccc;
      &, .platform, .label, .release-type > span {
        color: #666;
      }
      &:hover {
        cursor: default;
      }
    }
    .footer {
      display: none;
    }
  }
}

.actions .btn {
  white-space: nowrap;
  border-radius: 0;
  &:first-child {
    border-top-right-radius: 0.3rem;
  }
  &:last-child {
    border-bottom-right-radius: 0.3rem;
  }
}
</style>
