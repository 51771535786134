<template>
  <div style="margin-top: 15px">
    <browse ref="browse" :query="query" :sort="sort" :filters="filters" :override-params="overrideParams" :filters-in-url="false" hide-display-type-options hide-categories-filter searchable category="all" selectable-items store="encyclopedia">
      <template slot="extra-search-below">
        <auth-wrapper :required-level="$options.AUTH_LEVELS.ADMIN" class="flex" id="filterToggles">
          <b-form-checkbox v-model="missingCountryFilter" switch>Missing Country</b-form-checkbox>
          <b-form-checkbox v-model="missingPricingFilter" switch v-if="!missingCountryFilter">Missing Price Info</b-form-checkbox>
        </auth-wrapper>
      </template>
      <template slot="extra-search">
        <b-button v-b-modal.count-modal variant="info">How are we doing?</b-button>
      </template>
      <template slot="listItem" slot-scope="item">
        <region-list-item :item="item.scope" class="item" @selected="itemSelected"></region-list-item>
      </template>
      <template slot="extra">
        <b-button v-b-modal.editmodal class="edit-button" :disabled="selectedItems.length === 0" variant="info"><b-icon-pencil-fill/>&nbsp;&nbsp; Update Selected ({{selectedItems.length}})</b-button>
      </template>
      <template slot="extraFilters">
        <slider v-model="yearRange" :min="$options.MIN_YEAR" :max="$options.MAX_YEAR" style="margin: 25px 15px"></slider>
      </template>
    </browse>
    <b-modal id="editmodal" title="Update Selected Items" class="edit-modal" @ok="submitEdit" :ok-disabled="!anEditOptionSelected">
      <div class="label-value">
        <span class="label">Region:</span>
        <b-form-select v-model="country" :class="{empty: country === ''}">
          <option value="" selected style="font-style:italic">No Change</option>
          <option v-for="c in metadata.countries" :key="c.id" :value="c.id" style="font-style: normal">{{c.name}}</option>
        </b-form-select>
      </div>
      <div class="label-value">
        <span class="label">Release Type:</span>
        <b-form-select v-model="releaseType" :class="{empty: releaseType === ''}">
          <option value="" selected style="font-style:italic">No Change</option>
          <option v-for="r in metadata.release_types" :key="r.id" :value="r.id" style="font-style: normal">{{r.name}}</option>
        </b-form-select>
      </div>
      <auth-wrapper :required-level="$options.AUTH_LEVELS.ADMIN" class="label-value">
        <span class="label">Platform:</span>
        <b-form-select v-model="platform" :class="{empty: platform === ''}">
          <option value="" selected style="font-style:italic">No Change</option>
          <option v-for="p in platformOptions" :key="p.id" :value="p.id" style="font-style: normal">
            {{p.name}}
          </option>
        </b-form-select>
      </auth-wrapper>
    </b-modal>
    <b-modal id="count-modal" title="Total Remaining" hide-footer scrollable>
      <template #default="{visible}">
        <regionification-counts v-if="visible"></regionification-counts>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'underscore';
import Vue from 'vue';
import Browse from '@/views/Browse';
import browsePageProps from '@/mixins/BrowsePageProps';
import RegionListItem from '@/components/RegionEncyclopediaItem';
import {BButton, BIconPencilFill, BModal, BFormSelect, BFormCheckbox} from 'bootstrap-vue';
import RegionificationCounts from '@/views/tools/RegionificationCounts';
import AuthWrapper from '@/components/AuthWrapper';
import Slider from '@vueform/slider/dist/slider.vue2.js';

export default {
  name: 'RegionificationTool',
  mixins: [browsePageProps], // only for the props
  components: {
    Browse,
    RegionListItem,
    BButton,
    BIconPencilFill,
    BModal,
    BFormSelect,
    BFormCheckbox,
    RegionificationCounts,
    AuthWrapper,
    Slider
  },
  MIN_YEAR: 1950,
  MAX_YEAR: new Date().getFullYear(),
  data () {
    return {
      country: '',
      releaseType: '',
      platform: '',
      missingCountryFilter: true,
      missingPricingFilter: false,
      yearRange: [this.$options.MIN_YEAR, this.$options.MAX_YEAR]
    }
  },
  mounted () {
    // force initial load of items. fixes issue of pre-loaded items due to encyclopedia load (maybe should keep separate to not have this conflict?)
    this.loadItems();
  },
  computed: {
    selectedItems () {
      return _.where(this.itemsList, { selected: true });
    },
    anEditOptionSelected () {
      return this.country !== '' || this.releaseType !== '' || this.platform !== '';
    },
    platformOptions () {
      return _.sortBy(this.platformsList, "name");
    },
    overrideParams () {
      let p = {};
      if (this.missingCountryFilter) p.attr = 1;
      else if (this.missingPricingFilter) p.attr = 2;
      if (this.yearRange[0] != this.$options.MIN_YEAR || this.yearRange[1] != this.$options.MAX_YEAR) {
        let adjRange = [this.yearRange[0], this.yearRange[1] + 1]; // to INCLUDE max year selected
        adjRange = _.map(adjRange, year => { return new Date(Date.UTC(year)).getTime(); });
        adjRange[1] = adjRange[1] - 1; // 1 ms before next year to avoid edge cases
        p.drange = _.map(adjRange, timems => { return Math.floor(timems / 1000); }).toString();
      }
      return p;
    }
  },
  methods: {
    loadItems () {
      this.$refs.browse.getItems();
    },
    itemSelected (item) {
      if (item) Vue.set(item, 'selected', !item.selected);
    },
    submitEdit () {
      let itemIds = _.values(_.pluck(this.selectedItems, 'id'));
      let params = {
        items: itemIds,
        attrs: _.mapObject({country_id: this.country, release_type: this.releaseType, platform_id: this.platform}, val => { return val === '' ? undefined : val })
      }
      this.$store.dispatch('updates/editItems', params )
      .then(() => {
        this.country = '';
        this.releaseType = '';
        this.platform = '';
        this.loadItems();
      });
    }
  },
  watch: {
    overrideParams: {
      deep: true,
      handler (newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$nextTick(() => {
            this.loadItems();
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-button {
  position: fixed;
  bottom: 10%;
  right: 5%;
  box-shadow: 4px 2px 4px rgba(0, 0, 0, 0.45);
  z-index: 110;
}

.label-value {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  align-items: center;
  .label {
    min-width: 100px;
    width: 100px;
  }
  select.empty {
    font-style: italic;
  }
}

.search {
  padding: 15px;
  width: 50%;
}

#filterToggles {
  margin-top: 8px;
  > div {
    margin-right: 16px;
  }
  ::v-deep .custom-control-label {
    &::before {
      top: 0;
    }
    &::after {
      top: 2px;
    }
  }
}

::v-deep .slider-target {
  .slider-connect, .slider-tooltip {
    background: var(--gameye-blue);
    border-color: var(--gameye-blue);
  }
}
</style>
<style src="@vueform/slider/themes/default.css"></style>
