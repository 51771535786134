<template>
  <span>
    <custom-display
      v-if="fieldConfigObj"
      :value="value"
      :type="fieldType"
      :custom-component="fieldConfigObj.customComponent"
      :options="options"
      :options-label="fieldConfigObj.idListField"
      :options-value="fieldConfigObj.idListValue || 'id'"
      v-bind="props"></custom-display>
      <template v-else>{{value}}</template>
  </span>
</template>

<script>
import CustomDisplay from "@/components/CustomDisplay";
import {ITEM_FIELDS_MAP} from "@/store/constants.js";
import metadata from "@/mixins/MetadataMixin";

export default {
  name: "ItemFieldDisplay",
  mixins: [metadata],
  components: {
    CustomDisplay
  },
  props: {
    value: {
      required: true
    },
    fieldName: {
      type: String,
      required: true
    },
    itemCategoryNum: {
      type: Number,
      default: 0 //game
    },
    // Override the field type with this
    displayAs: {
      type: [String, Function]
    }
  },
  computed: {
    itemCategory () {
      return this.parseListForMatchingVal(this.assignableCategories, {value: this.itemCategoryNum})
    },
    fieldConfigObj () {
      return ITEM_FIELDS_MAP[this.itemCategory.name][this.fieldName];
    },
    options () {
      return _.isFunction(this.fieldConfigObj.options) ? this.fieldConfigObj.options(this) : this.fieldConfigObj.options;
    },
    props () {
      return this.fieldConfigObj.getProps ? this.fieldConfigObj.getProps(this.value) : undefined;
    },
    fieldType () {
      if (this.displayAs) {
        return _.isFunction(this.displayAs) ? this.displayAs(this.fieldConfigObj) : this.displayAs;
      } else {
        return this.fieldConfigObj.type
      }
    }
  }
}
</script>
