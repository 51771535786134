<template>
  <component :is="customComponent" v-bind="{...$props,...$attrs}">{{formattedValue}}</component>
</template>

<script>
import metadata from "@/mixins/MetadataMixin";
import _ from "underscore";
//TODO: this requires importing each custom element manually? is there a better way?
import Platform from "@/components/Platform";

export default {
  name: "CustomDisplay",
  mixins: [metadata],
  components: {
    Platform
  },
  props: {
    value: {
      required: true
    },
    type: {
      type: String,
      default: "text",
      validator: value => {
        return ['select', 'multiselect', 'date', 'date-time', 'boolean', 'text-area', 'text', 'multi-text', 'number'].includes(value);
      }
    },
    customComponent: {
      type: String,
      default: "span"
    },
    options: { // options, field, value
      type: Array
    },
    optionsLabel: {
      type: String,
      default: "name"
    },
    optionsValue: {
      type: String,
      default: "value"
    }
  },
  computed: {
    formattedValue () {
      if (!this.hasValue(this.value)) return this.value;
      switch (this.type) {
        case "date":
          return this.$options.filters["date-seconds"](this.value);
        case "date-time":
          return this.$options.filters["date-time-seconds"](this.value);
        case "boolean":
          return this.value ? 'Yes' : 'No';
        case "select":
          return this.parseListForMatchingId(this.options, this.value)[this.optionsLabel || 'label'];
        case "multiselect":
          return _.pluck(this.parseListForMatchingIds(this.options, this.value), this.optionsLabel || 'label');
        default: // text, number, text-area, multi-text
          return this.value;
      }
    }
  }
}
</script>
