<template>
  <b-modal id="accountCustomizationPopover" ref="modal" title="Login" size="lg" body-class="no-padding" v-bind="$attrs"
    centered hide-footer hide-header :visible="value" @change="$emit('input', $event)">
    <div :class="['content', {complete: currentStep >= steps.length}]">
      <template v-if="userVerified">
        <Transition name="content-transition">
          <div v-if="currentStep < steps.length">
            <b-button class="skip" @click="skipRemaining" variant="link" size="sm">Skip Profile Customization &gt;&gt;</b-button>
            <h2>Complete Your GAMEYE Profile</h2>
            <div class="flex-col center v-center" style="margin-left: -84px">
              <div>
                <placeholder-user-badge :display-name-override="displayName" :show-assigned-image="currentStep > 0" :editing-fields="currentlyEditingFields" :color-override="customColor" :image-override="customProfilePic" />
              </div>
              <div id="steps" class="flex center" style="margin-top: 12px">
                <div v-for="s in steps" :key="s" :class="['step flex center v-center',{complete: s < currentStep && stepIsComplete(s), current: s === currentStep}]">
                  <b-icon-three-dots v-if="s === currentStep" />
                  <b-icon-check-lg scale="0.9" v-if="s < currentStep && stepIsComplete(s)" />
                </div>
              </div>
            </div>
            <div class="flex center v-center">
              <div v-if="currentStep === 0">
                <div class="grey">Step 1</div>
                <h4>Choose a Display Name</h4>
                <div>A name for yourself which can be different from your username.</div>
                <div class="grey" style="font-size: 0.8em; margin-top: 8px">This will be shown with your profile, profile cards, and any other social aspects on the site.</div>
                <div style="margin-top: 40px">
                  <b-form-input v-model="displayName" placeholder="Display Name" size="sm" :state="displayNameIsValid !== false ? undefined : false" debounce="200"></b-form-input>
                  <div class="flex grey" style="font-variant: small-caps; justify-content: space-between; font-size: 0.7rem">
                    <div :class="{error: displayNameIsValid === false && !!displayName && displayName.length < $options.NAME_LENGTHS.MIN_DISPLAY_NAME}">Min: {{$options.NAME_LENGTHS.MIN_DISPLAY_NAME}}</div>
                    <div :class="{error: displayNameIsValid === false && !!displayName && displayName.length > $options.NAME_LENGTHS.MAX_DISPLAY_NAME}">Max: {{$options.NAME_LENGTHS.MAX_DISPLAY_NAME}}</div>
                  </div>
                </div>
              </div>
              <div v-if="currentStep === 1">
                <div class="grey">Step 2</div>
                <h4>Pick a Profile Image/Theme</h4>
                <div>An icon or image and color scheme to represent your profile.</div>
                <div class="grey" style="font-size: 0.8em; margin-top: 8px">This will be shown with your profile, profile cards, and any other social aspects on the site.</div>
                <user-theming style="margin-top: 8px" @profilePicChanged="customProfilePic = $event" @colorChanged="customColor = $event" @init="initTheme" />
              </div>
            </div>
            <div class="footer flex center">
              <loading-button variant="primary" style="padding: .25rem 3rem" size="sm" :disabled="!stepIsComplete(currentStep)" :async-func="saveAndContinue">Save &amp;{{currentStep < steps.length - 1 ? ' Continue' : ' Complete'}}</loading-button>
            </div>
          </div>
        </Transition>
      </template>
      <template v-else>
        <div>
          <div class="absolute-center text-center" style="grid-column: span 2; grid-row: span 2; width: 50%">
            <Transition name="fade">
              <div class="absolute-center w-100" v-if="!loggedInUser.verified">
                <b-icon icon="envelope" class="rounded-circle p-3" style="background-color: var(--gameye-blue)" />
                <h4>Check your inbox!</h4>
                <div>A verification link was sent to your email.</div>
                <hr style="margin: 24px 0">
                <div class="italic light-grey">
                  In order to use any profile features of GAMEYE, you need to confirm your email address.
                </div>
                <div style="margin-top: 24px">
                  <b-button block variant="primary" @click="$store.dispatch('getUserInfo')">Confirm Verification</b-button>
                </div>
                <div class="italic grey" style="font-size: 0.8rem; margin-top: 12px">
                  Didn't receive the email? Check your spam folder! Otherwise, you can 
                  <resend-verification-link style="font-size: 1rem">resend verification</resend-verification-link>.
                </div>
              </div>
            </Transition>
            <Transition name="fade">
              <div class="absolute-center w-100" v-if="loggedInUser.verified">
                <b-icon icon="check2" class="rounded-circle p-3" style="background-color: var(--success)" />
                <h4>Success!</h4>
                <div>You have been verified.</div>
                <div style="margin-top: 24px">
                  <b-button class="delay" block variant="link" @click="userVerified = true">Continue to Account Customization &gt;&gt;</b-button>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </template>
    </div>
    <Transition name="profile-logo-transition">
      <img class="absolute-center" src="/logo-outline-lg.png" v-if="currentStep < steps.length && userVerified" />
    </Transition>
    <Transition name="profile-logo-replace-transition">
      <div v-if="currentStep >= steps.length && userVerified">
        <icon-set class="absolute-center logo" icon="logo" height="280" width="280" />
        <h1 id="welcome" class="absolute-center">WELCOME TO GAMEYE</h1>
        <div id="preview" class="flex-col center absolute-center">
          <h4 style="margin-bottom: 32px">Nice, you completed your profile!</h4>
          <user-badge />
        </div>
        <div id="previewBtns" class="absolute-h-center" style="bottom: 56px">
          <b-button variant="primary" @click="closeModal">Continue to GAMEYE</b-button>
        </div>
      </div>
    </Transition>
  </b-modal>
</template>

<script>
import {BButton, BModal, BFormInput, BIconThreeDots, BIconCheckLg} from "bootstrap-vue";
import PlaceholderUserBadge from "@/components/PlaceholderUserBadge";
import UserBadge from "@/components/UserBadge";
import UserTheming from "@/components/UserTheming";
import LoadingButton from "@/components/LoadingButton";
import ResendVerificationLink from '@/components/ResendVerificationLink';
import {GAMEYE_BLUE, NAME_LENGTHS} from '@/store/constants.js';
import IconSet from '@/components/IconSet';

export default {
  name: "AccountCustomizationModal",
  components: {
    BButton,
    BModal,
    BFormInput,
    BIconThreeDots,
    BIconCheckLg,
    PlaceholderUserBadge,
    UserBadge,
    UserTheming,
    LoadingButton,
    ResendVerificationLink,
    IconSet
  },
  props: {
    value: {
      type: Boolean
    }
  },
  computed: {
    currentlyEditingFields () {
      if (this.currentStep === 0) return ["displayName"];
      else if (this.currentStep === 1) return ["userProfileImage", "userColor"];
      else return [];
    }
  },
  NAME_LENGTHS: NAME_LENGTHS,
  data () {
    return {
      displayName: undefined,
      displayNameIsValid: undefined,
      themeInitialized: false,
      steps: [0, 1],
      currentStep: 0,
      customProfilePic: null,
      customColor: null, //default value null means selecting default
      userVerified: false //local version. if loggedinuser is verified, but this isn't, it's a certain UI state
    }
  },
  created () {
    this.userVerified = this.loggedInUser.verified;
    if (this.loggedInUser.display_name) this.displayName = this.loggedInUser.display_name;
    this.steps.forEach(s => {
      if (this.stepIsComplete(s)) this.currentStep = s + 1;
    })
  },
  watch: {
    displayName (newVal) {
      let trimmed = !!newVal ? newVal.trim() : newVal;
      this.displayNameIsValid = !!trimmed && trimmed.length >= NAME_LENGTHS.MIN_DISPLAY_NAME && trimmed.length <= NAME_LENGTHS.MAX_DISPLAY_NAME;
    },
    value (newVal) {
      if (newVal && !this.userVerified) this.waitForVerified();
    }
  },
  methods: {
    async waitForVerified () {
      let baseInterval = 5000;
      let scaleUp = 100;
      let ct = 0;
      const wait = (ms) => new Promise((res) => setTimeout(res, ms));
      while (!this.userVerified && this.value) {
        let waitTime = baseInterval + Math.pow(ct, 2) * scaleUp;
        console.log('waiting for ' + waitTime + 'ms');
        await wait(waitTime);
        await this.$store.dispatch('getUserInfo');
        ct++;
      }
    },
    stepIsComplete (step) {
      if (step === 0) {
        return this.displayNameIsValid || !!this.loggedInUser.display_name && this.loggedInUser.user_blob;
      } else if (step === 1) {
        return this.themeInitialized
          || (this.loggedInUser.user_blob && this.loggedInUser.user_blob.color !== undefined && this.loggedInUser.user_blob.profile_pic !== undefined); //might need to figure this out, once there both values pre-populated
      }
    },
    saveAndContinue () {
      if (this.stepIsComplete(this.currentStep)) {
        let editedObj = {};
        if (this.currentStep === 0) {
          editedObj.display_name = this.displayName;
        } else if (this.currentStep === 1) {
          editedObj.user_blob = {
            color: this.customColor,
            profile_image_id: this.customProfilePic ? this.customProfilePic.id : null // default value to null to show they've done this step
          }
        }
        return this.$store.dispatch("editUserInfo", editedObj).then(() => {
          this.currentStep++;
        })
      }
    },
    initTheme ({color, profilePic}) {
      this.customProfilePic = profilePic;
      this.customColor = color;
      this.themeInitialized = true;
    },
    skipRemaining () {
      let saveObj = {
        user_blob: {}
      };
      // display name is in step 0, so it's not set yet
      if (this.currentStep === 0) saveObj.display_name = this.loggedInUser.username;
      if (this.currentStep <= 1) {
        if (!(this.loggedInUser.user_blob && this.loggedInUser.user_blob.profile_pic)) saveObj.user_blob.profile_image_id = null; // initialize
        if (!(this.loggedInUser.user_blob && this.loggedInUser.user_blob.color)) saveObj.user_blob.color = GAMEYE_BLUE;
      }
      this.$store.dispatch("editUserInfo", saveObj).then(() => {
        this.currentStep = this.steps.length;
      })
    },
    closeModal () {
      this.$refs.modal.hide();
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .modal-body {
  overflow: hidden;
}

.user-badge {
  box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
}

.skip {
  position: absolute;
  right: 12px;
  z-index: 2 !important;
  color: var(--gameye-blue-light);
}

.content {
  width: 100%;
  padding: 24px 16px;
  overflow: hidden;
  min-height: 500px;
  > div {
    min-height: 500px;
    position: relative;
    display: grid;
    column-gap: 48px;
    grid-template-columns: 400px auto;
    grid-template-rows: min-content auto min-content;
    > :not(img):not(.logo) {
      z-index: 1;
    }
  }
}

h2, .footer {
  grid-column-start: span 2;
}

img {
  transform: translate(-450px, -140px); // left: -50px, top: 85px
  width: 450px;
  height: 450px;
}

.logo {
  transform: translate(-500px, -350px) scale(calc(40 / 280));
}

#welcome {
  color: var(--gameye-blue);
  white-space: nowrap;
  letter-spacing: 1px;
  font-weight: bold;
  transform: translate(-410px, -235px) scale(0.6);
}

#steps {
  column-gap: 12px;
}

.step {
  width: 22px;
  height: 22px;
  border-radius: 11px;
  border: 1px solid var(--gameye-blue);
  background-color: #003166;
  box-shadow: 3px 3px 3px rgba(0,0,0,0.3);
  &.current, &.complete {
    background-color: var(--gameye-blue);
  }
}

.error {
  color: #d9666c;
}

.b-icon.rounded-circle {
  font-size: 6rem;
  margin-bottom: 1.5rem;
}

$transitionTime: 2.4s;

.profile-logo-transition-leave-active {
  animation: move-fade calc($transitionTime/2);
}
@keyframes move-fade {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 1;
    transform: translate(-50%, calc(-50% - 48px)) scale(calc(280/450));
  }
  100% {
    opacity: 0;
    transform: translate(-50%, calc(-50% - 48px)) scale(calc(280/450));
  }
}

.profile-logo-replace-transition-enter-active {
  animation: logo-fade-in $transitionTime;
}
@keyframes logo-fade-in {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

.profile-logo-replace-transition-enter-active .logo {
  animation: move-top $transitionTime;
}
@keyframes move-top {
  0% {
    transform: translate(-50%, calc(-50% - 48px));
  }
  80% {
    transform: translate(-50%, calc(-50% - 48px));
  }
}

.profile-logo-replace-transition-enter-active #welcome {
  animation: move-top $transitionTime, welcome-margin $transitionTime;
}
@keyframes welcome-margin {
  0% {
    margin-top: 200px;
  }
  80% {
    margin-top: 200px;
  }
  to {
    margin-top: 0;
  }
}

.profile-logo-replace-transition-enter-active #preview,
.profile-logo-replace-transition-enter-active #previewBtns {
  animation: late-fade-in $transitionTime;
}
@keyframes late-fade-in {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.content-transition-leave-active {
  transition: opacity calc($transitionTime * 3 / 8) linear;
}

.content-transition-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active .delay {
  animation: late-fade-in 2s;
}

</style>
