<template>
  <div style="padding: 15px 0; max-width: 1400px">
    <div class="flex" style="justify-content: end; margin-bottom: 18px">
      <custom-input type="select"
        :searchable="false"
        v-model="sortAsc"
        @input="getQueueItems"
        required
        :select-config="{value: 'value', returnValue: 'value', options: [{name: 'Date Added to Queue: Newest', value: false}, {name: 'Date Added to Queue: Oldest', value: true}]}">
      </custom-input>
    </div>
    <div style="margin-bottom: 12px">Showing {{queue.length}} of {{totalCount}}</div>
    <div class="flex-col" style="row-gap: 16px">
      <queue-item v-for="i in queue" :key="i.id" :item="{item_id: i.id, title: i.title, created_at: i.created_at, data: i}"
        queue-type="GAMEYE" :queue-id="i.id"
        :type-id="QUEUE_TYPE_IDS.GAMEYE.NEW"
        :source-link="$router.resolve({ name: 'Details', params: { id: i.id } }).href"
        :save-func="saveQueueChange" :decline-func="declineQueueChange"
        :remove-from-queue-func="deleteFromQueue"
        :can-select-changes="false" :can-merge-with-existing="false"
      ></queue-item>
      <div v-if="queue.length > 0 && queue.length < totalCount" style="margin: 12px 0">
        <b-button block variant="dark" @click="loadMore">Load More Results</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import {QUEUE_TYPE_IDS, ITEM_STATES} from "@/store/constants.js";
import CustomInput from "@/components/CustomInput";
import QueueItem from "@/components/QueueItem";
import _ from "underscore";
import Vue from "vue";

export default {
  name: "DataQueue",
  components: {QueueItem, CustomInput},
  data () {
    return {
      QUEUE_TYPE_IDS: QUEUE_TYPE_IDS,
      queue: [],
      totalCount: 0,
      curPage: 0,
      pageSize: 20,
      sortAsc: false
    }
  },
  created () {
    this.initQueue()
  },
  methods: {
    initQueue () {
      this.curPage = 0;
      this.queue = []
      this.getQueueItems()
    },
    getQueueItems () {
      this.$store.dispatch("encyclopedia/getItems", { params: {state: 3, limit: this.pageSize, offset: this.curPage * this.pageSize, order: 6, asc: this.sortAsc ? 1 : 0} }).then(response => {
        this.$store.dispatch("encyclopedia/getMultipleItemDetails", _.compact(_.pluck(response, "id"))).then(items => {
          
          this.queue = _.map(response, i => { return {...i, ...i.game_specific} })
          _.each(items.data.items, item => {
            let qi = _.findWhere(this.queue, {id: item.id})
            Vue.set(qi, "images", item.images);
            qi.created_at = item.created_at * 1000;
            qi.created_By = item.creator_user_id;
          })

          // this is only provided on first page load
          if (this.curPage === 0) this.totalCount = this.$store.state.encyclopedia.itemsTotalCount;
        })
      })
    },
    loadMore () {
      this.curPage += 1;
      this.getQueueItems();
    },
    saveQueueChange (item, typeId, qItem) {
      console.log("saving", qItem)
      return this.$store.dispatch("updates/editState", { item_id: qItem.id, item_state: ITEM_STATES.GOOD})
    },
    declineQueueChange (item) {
      console.log("declining", item)
      return this.$store.dispatch("updates/editState", { item_id: item.item_id, item_state: ITEM_STATES.DELETED })
    },
    // we don't need to do anything to remove from queue accept/decline already does it
    deleteFromQueue () { return Promise.resolve(); }
  }
}
</script>