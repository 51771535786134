import axios from "./axios";

export default {
  namespaced: true,
  actions: {
    editItem({}, params) {
      return axios.post('/edit_item', params).then(response => { return response.data });
    },
    editItems({}, params) {
      return axios.post("/batch_edit_item", params).then(response => {
        return response.data;
      })
    },
    addNewItem({}, newItem) {
      return axios.put("/add_item", newItem).then(response => {
        return response.data;
      });
    },
    deleteItem({dispatch}, itemId) {
      return dispatch('editState', { item_id: itemId, item_state: 2 });
    },
    editState({}, params) {
      return axios.post("/update_item_state", params);
    },
    updateLink({}, data) {
      return axios.post("/update_item_links", data);
    },
    removeLink({}, data) {
      return axios.delete("/update_item_links", {params: data});
    },
    addImage({}, data) {
      return axios.put("/item_image", data);
    },
    editImage({}, data) {
      return axios.post("/item_image", data);
    },
    deleteImage({}, params) {
      return axios.delete("/item_image", {params: params});
    }
  }
};