<template>
  <div id="footer" class="grid flex v-center">
    <div id="copyright" class="flex">
      <div>&#169; 2016-{{new Date().getFullYear()}} GAMEYE, INC.</div>
      <div id="links" class="flex">
        <a href="/legal/terms-of-use" target="_blank" class="plain">TERMS OF USE</a>
        <a href="/legal/privacy-policy" target="_blank" class="plain">PRIVACY POLICY</a>
      </div>
    </div>
    <div id="app-links" class="flex">
      <store-buttons style="height: 40px" />
    </div>
    <div id="socials" class="flex">
      <a href="https://www.facebook.com/GAMEYEVG" target="_blank" class="plain"><icon-set icon="facebook" /></a>
      <a href="https://twitter.com/GameyeVG" target="_blank" class="plain"><icon-set icon="twitter" /></a>
      <a href="https://www.instagram.com/gameyevg/" target="_blank" class="plain"><icon-set icon="instagram" /></a>
      <a href="https://discord.gg/gameye-752585384126316554" target="_blank" class="plain"><icon-set icon="discord" /></a>
    </div>
  </div>
</template>

<script>
import IconSet from "@/components/IconSet";
import StoreButtons from "@/components/StoreButtons";

export default {
  name: "NavFooter",
  components: {IconSet, StoreButtons}
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
#footer {
  width: 100%;
  padding: 16px 24px;
  background-color: var(--background-color);
  color: #656565;
  grid-template-columns: 1fr auto 1fr;
}
#copyright, #links {
  column-gap: 32px;
}
#copyright {
  order: 1;
}
#socials {
  column-gap: 12px;
  justify-content: end;
  order: 3;
}
#app-links {
  column-gap: 12px;
  order: 2;
  img:hover {
    cursor: pointer;
    .st0 {
      fill: #65656533;
    }
  }
}
a {
  color: currentColor;
  &:hover {
    color: var(--gameye-blue);
  }
}
@media (max-width: $smallWidth) {
  #footer {
    display: flex;
    flex-direction: column;
    padding: 12px;
    row-gap: 8px;
  }
  #app-links {
    order: 4;
    margin-top: 4px;
  }
}
@media (max-width: $mobileWidth) {
  #copyright {
    align-items: center;
    flex-direction: column;
    row-gap: 4px;
  }
}
</style>
