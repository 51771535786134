<template>
  <div>
    <div id="colorSelector" class="colors flex v-center center">
      <div class="color-outer" v-for="(c, i) in colorOptions" :key="c" :style="{'border-color': selectedColor === i ? 'white' : 'transparent'}">
        <div class="color" :style="{'background-color': c}" @click="selectColor(i)"></div>
      </div>
      <div id="colorPicker" :style="{'border-color': selectedColor === colorOptions.length ? 'white' : 'transparent', position: 'relative', cursor: 'pointer'}" @click="selectColor(colorOptions.length)">
        <color-picker v-model="customColor" hide-rgba hide-alpha placement="bottom-start" append-to-body @input="selectColor(colorOptions.length)">
          <b-icon-plus-lg slot="previewIcon" scale="0.65" class="absolute-center" :style="['width: 16px', {color: textColorFromBgColor(customColor.hex, '#2f3641')}]" />
        </color-picker>
      </div>
    </div>
    <div id="badgeSelector" style="margin-top: 8px; padding-bottom: 24px">
      <div class="text-center grey" style="font-size: 0.9em; margin-bottom: 8px">Choose Icon</div>
      <b-carousel :interval="null" :indicators="hasMultipleProfilePicPages" :controls="hasMultipleProfilePicPages">
        <b-carousel-slide v-for="t in profilePicMeta.types" :key="t.id">
          <template slot="img">
            <div class="flex" style="column-gap: 8px; row-gap: 4px; flex-wrap: wrap">
              <div class="flex v-center">
                <div id="blankPic" @click="selectProfilePic(null)" :class="['pic', {selected: emptyProfilePicSelected}]">
                  <b-avatar size="44px" rounded :src="$options.BLANK_SVG_URL" badge-top :badge="emptyProfilePicSelected" badge-offset="-0.4rem" :style="{'border-color': emptyProfilePicSelected ? `var(--gameye-blue)` : 'transparent'}" title="None">
                    <template slot="badge" v-if="emptyProfilePicSelected"><b-icon-check /></template>
                  </b-avatar>
                </div>
              </div>
              <div v-for="p in getProfilePicsByTypeId(t.id)" :key="p.id" @click="selectProfilePic(p.id)" :class="['pic', {selected: selectedProfilePic === p.id}]">
                <b-avatar size="44px" rounded :src="getImageURLByType(p.file, 1, 'PROFILE_PIC')" badge-top :badge="selectedProfilePic === p.id" badge-offset="-0.4rem" :style="{'border-color': selectedProfilePic === p.id ? `var(--gameye-blue)` : 'transparent'}" :title="p.description">
                  <template slot="badge" v-if="selectedProfilePic === p.id"><b-icon-check /></template>
                </b-avatar>
              </div>
            </div>
            <!--<div class="text-center italic" style="font-size: 0.7em">{{t.desc}} Collection</div>-->
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
  </div>
</template>

<script>
import ColorPicker from '@brettvd/customized-vue-colorpicker';
import {BIconPlusLg, BIconCheck, BCarousel, BCarouselSlide, BAvatar} from 'bootstrap-vue';
import {textColorFromBgColor} from '@/mixins/utils.js';
import {GAMEYE_BLUE} from '@/store/constants.js';
import _ from 'underscore';
import Vue from 'vue';

export default {
  name: 'UserTheming',
  components: {ColorPicker, BIconPlusLg, BIconCheck, BCarousel, BCarouselSlide, BAvatar},
  BLANK_SVG_URL: "data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='red' stroke-width='2'/><path d='M0 0 L100 100 ' stroke='red' stroke-width='2'/></svg>",
  data () {
    return {
      colorOptions: [
        GAMEYE_BLUE, '#FF0202', '#FF7C02', '#FFC702', '#FAFF02', '#80FF02', '#02FFD1', '#0295FF', '#9002FF', '#FF02C7'
      ],
      selectedColor: 0,
      customColor: { hex: '#000' },
      textColorFromBgColor: textColorFromBgColor,
      profilePicMeta: {},
      selectedProfilePic: undefined
    }
  },
  computed: {
    hasMultipleProfilePicPages () {
      return this.profilePicMeta.types && this.profilePicMeta.types.length > 1;
    },
    emptyProfilePicSelected () {
      return this.selectedProfilePic === undefined || this.selectedProfilePic === null;
    }
  },
  methods: {
    selectColor (i) {
      this.selectedColor = i;
      this.$emit('colorChanged', this.getSelectedColor());
    },
    getProfilePicsByTypeId (typeId) {
      return _.where(this.profilePicMeta.images, {profile_pic_type_id: typeId});
    },
    selectProfilePic (i) {
      this.selectedProfilePic = i;
      this.$emit('profilePicChanged', this.getSelectedProfilePic());
    },
    getSelectedColor () {
      if (this.selectedColor === undefined || this.selectedColor === null) return undefined;
      return this.selectedColor === this.colorOptions.length ? this.customColor.hex : this.colorOptions[this.selectedColor]
    },
    getSelectedProfilePic () {
      return this.selectedProfilePic !== null ? _.findWhere(this.profilePicMeta.images, {id: this.selectedProfilePic}) : this.selectedProfilePic;
    }
  },
  created () {
    if (this.userColor) {
      if (_.contains(this.colorOptions, this.userColor)) this.selectedColor = _.indexOf(this.colorOptions, this.userColor);
      else {
        this.customColor.hex = this.userColor;
        this.selectedColor = this.colorOptions.length;
      }
    }
    this.$store.dispatch('meta/getProfilePics').then(({data}) => {
      Vue.set(this.profilePicMeta, 'types', data.types);
      Vue.set(this.profilePicMeta, 'images', data.images);
      if (this.userProfileImage !== undefined && this.userProfileImage !== null) {
        let i = _.findWhere(this.profilePicMeta.images, { file: this.userProfileImage });
        if (i) this.selectedProfilePic = i.id;
        else console.error("Profile pic that's selected doesn't exist");
      }
      this.$emit('init', { color: this.getSelectedColor(), profilePic: this.getSelectedProfilePic() });
    })
  }
}
</script>

<style lang="scss" scoped>
#colorSelector, #badgeSelector {
  background-color: #61626B;
  border-radius: 8px;
}
#colorSelector {
  padding: 3px 12px;
  column-gap: 3px;
  .color-outer {
    padding: 1px;
    border-radius: 10px;
    border: 1px solid transparent;
  }
  .color {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    cursor: pointer;
  }
  #colorPicker {
    padding: 1px;
    border-radius: 5px; //based on current library border-radius for preview
    border: 1px solid transparent;
  }
}

#badgeSelector {
  padding: 8px 0;
  .pic {
    cursor: pointer;
    .b-avatar {
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
    }
  }
}

.carousel {
  ::v-deep .carousel-inner {
    padding-left: 15%;
    padding-right: 15%;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  ::v-deep .carousel-indicators {
    position: static;
  }
}

</style>
