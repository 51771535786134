import axios from "./axios";
import itemCache from "./itemCache";
import _ from "lodash";

export default _.merge(
  {},
  itemCache,
  {
    namespaced: true,
    actions: {
      getItems(context, data) {
        return axios.get("/vgpc/mod_queue", { params: data.params }).then(response => {
          context.commit("setItems", response.data.records);
          if (_.isNumber(response.data.full_count)) context.commit("setItemsCount", response.data.full_count);
          return response.data.records;
        });
      },
      appendItems(context, data) {
        return axios.get("/vgpc/mod_queue", { params: data.params }).then(response => {
          context.commit("addItems", response.data.records);
          if (_.isNumber(response.data.full_count)) context.commit("setItemsCount", response.data.full_count);
          return response.data.records;
        });
      },
      getVgpcPreview({}, id) {
        return axios.get("/vgpc/preview_entry", { params: { vgpc_id: id } }).then(response => {
          return response.data;
        });
      },
      linkToGameye({}, data) {
        return axios.post("/vgpc/link_entry", data);
      }
    }
  }
)