<template>
  <div style="margin-top: 15px">
    <browse ref="browse" :query="query" :sort="sort" :filters="filters" :override-params="{attr: 2}" searchable :filters-in-url="false" hide-display-type-options hide-categories-filter category="games" selectable-items :store="store">
      <template slot="extra-search">
        <b-button v-b-modal.count-modal variant="info" style="margin-bottom: 15px">How are we doing?</b-button>
      </template>
    </browse>
    <b-modal id="count-modal" title="Total Remaining" hide-footer scrollable>
      <template #default="{visible}">
        <pricification-counts v-if="visible"></pricification-counts>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from 'underscore';
import Browse from '@/views/Browse';
import browsePageProps from '@/mixins/BrowsePageProps';
import {BModal} from 'bootstrap-vue';
import PricificationCounts from '@/views/tools/PricificationCounts';

export default {
  name: "PricificationTool",
  mixins: [browsePageProps],
  components: {
    Browse,
    BModal,
    PricificationCounts
  },
  data () {
    return {
      country: '',
      releaseType: ''
    }
  },
  mounted () {
    // manually override
    this.$refs.browse.navToItem = id => {
      const route = this.$router.resolve({ name: 'Details', params: { id: id } });
      window.open(route.href, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  padding: 15px;
  width: 50%;
}
</style>
