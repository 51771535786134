<template>
  <div>
    <div class="flex">
      <div class="flex" style="flex-wrap: wrap"><slot></slot></div>
      <div v-if="canEdit" :id="`${fieldName}-edit`" style="margin-left: 15px; cursor: pointer" class="v-h-center">
        <slot name="edit-btn"><b-icon-pencil /></slot>
      </div>
    </div>
    <edit-field-popover v-if="canEdit" v-bind="[$attrs, $props]" :target="`${fieldName}-edit`" ref="popover" :field-value="cFieldVal" :save-func="getEditFieldFunc()" @save="$emit('save', $event, fieldName)">
      <template v-for="(_, name) of $slots" v-slot:[name]>
        <slot :name="name"></slot>
      </template>
      <template v-for="(_, name) of $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
    </edit-field-popover>
  </div>
</template>

<script>
import EditFieldPopover from '@/components/EditFieldPopover';
import { BIconPencil } from 'bootstrap-vue';

export default {
  name: "EditableField",
  components: {
    EditFieldPopover,
    BIconPencil
  },
  props: {
    fieldName: {
      type: String,
      required: true
    },
    fieldValue: {},
    editable: {
      type: Boolean,
      default: false
    },
    requiredUserLevel: {
      type: Number
    },
    target: {
      required: false // override from "extends" component
    },
    options: {
      type: Array
    }
  },
  computed: {
    _requiredUserLevel () {
      return this.requiredUserLevel || 0;
    },
    cFieldVal () {
      return (this.fieldValue !== null && this.fieldValue !== undefined) ? this.fieldValue : '';
    },
    canEdit () {
      return this.editable && this.loggedInUser?.verified && this.checkUserLevel(this._requiredUserLevel);
    }
  },
  methods: {
    getEditFieldFunc () {
      return (newVal, selectConfig) => {
        return this.$attrs['save-func'](newVal, this.fieldName, selectConfig);
      }
    }
  }
}
</script>
