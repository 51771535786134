<template>
  <div>
    <div style="display: grid; grid-template-columns: 1fr 3fr 1fr">
      <div style="grid-column: 1"></div>
      <div style="grid-column: 2">
        <div>
          <b-button variant="primary" v-b-modal.invite-user-modal>Invite User</b-button>
        </div>
        <div style="width: 100%; display: flex">
          <div class="search" style="margin-right: 20px">
            <div class="header">search</div>
            <div class="input-with-icon right input-group">
              <b-form-input v-model="lSearch" @keydown.enter.native.stop="loadUsers"></b-form-input>
              <span class="close" @click="clearSearch">
                <b-icon-x-circle-fill v-show="lSearch !== ''"></b-icon-x-circle-fill>
              </span>
            </div>
          </div>
          <div class="search" style="flex-grow: 1">
            <div class="header">filter by role</div>
            <b-form-select v-model="fRole" @change="loadUsers">
              <option :value="null">-- Any Role --</option>
              <option v-for="role in metadata.roles" :value="role.id" :key="role.id">{{role.name}}</option>
            </b-form-select>
          </div>
        </div>
        <div class="header user-row">
          <div>id</div>
          <div>username</div>
          <div>display name</div>
          <div>roles</div>
        </div>
        <div v-for="user in users" :key="user.id" class="user-row">
          <div>{{user.id}}</div>
          <div>{{user.username}}</div>
          <div>{{user.display_name}}</div>
          <div class="roles">
            <pill v-for="role in user.role_ids" :key="'' + user.id + role" :text="parseListForMatchingId(metadata.roles, role).name" tag="span"></pill>
          </div>
          <div style="text-align: right">
            <b-icon-pencil-fill class="action" @click="showUserModal(user)"></b-icon-pencil-fill>
          </div>
        </div>
        <div class="w-100" style="margin-top: 12px">
          <pagination v-model="curPage" :per-page="perPage" :total-rows="totalUsers" @change="pageUpdated" />
        </div>
      </div>
    </div>
    <b-modal ref="edit-user-modal" @ok="saveUser" @cancel="clearEditUser">
      <div style="display: grid; grid-template-columns: 1fr 3fr;" class="edit-user-modal">
        <div class="label">username:</div>
        <div class="value">{{editUser.username}}</div>
        <div class="label">display name:</div>
        <div class="value">{{editUser.display_name}}</div>
        <div class="label">roles:</div>
        <div class="value roles">
          <div v-for="role in editUser.role_ids" :key="'edit' + editUser.id + role" class="role">
            <pill :text="parseListForMatchingId(metadata.roles, role).name" tag="span"></pill>
            <span v-if="canRemoveRole(role)" class="remove" @click="removeRole(role)">-</span>
          </div>
          <div class="add-role">
            <span class="add" @click="addingRole=true" v-if="!addingRole">+</span>
            <div v-else>
              <b-form-select v-model="roleToAdd" :options="rolesNotAddedYet" value-field="id" text-field="name"></b-form-select>
              <b-button variant="success" @click="addRole(roleToAdd);addingRole=false">Add</b-button>
              <b-button @click="addingRole=false">Cancel</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="invite-user-modal" ref="invite-user-modal" @ok="inviteUser" @cancel="clearInvite">
      <div style="display: grid; grid-template-columns: 1fr 3fr">
        <div class="label">Email:</div>
        <b-form-input v-model="inviteEmail" type="email"></b-form-input>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Pill from '@/components/Pill';
import {BFormSelect,BFormInput,BIconPencilFill,BModal,BButton} from 'bootstrap-vue';
import Pagination from  '@/components/Pagination';
import _ from 'underscore';

export default {
  name: 'UserManager',
  components: {Pill, BFormSelect, BFormInput, BIconPencilFill, BModal, BButton, Pagination},
  data () {
    return {
      users: [],
      lSearch: '',
      fRole: undefined,
      editUser: {},
      addingRole: false,
      roleToAdd: undefined,
      inviteEmail: undefined,
      curPage: 1,
      perPage: 20,
      totalUsers: undefined
    }
  },
  computed: {
    rolesNotAddedYet () {
      return _.reject(this.metadata.roles, r => { return _.contains(this.editUser.role_ids || [], r.id)});
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.loadUsers();
    });
  },
  methods: {
    loadUsers () {
      this.getUsers().then((response) => {
        this.users = response.users;
        if (this.curPage === 1) this.totalUsers = response.count;
      })
    },
    getUsers () {
      return this.$store.dispatch('searchForUsers', {limit: this.perPage, offset: (this.curPage - 1) * this.perPage, handle: this.lSearch, role: this.fRole});
    },
    pageUpdated () {
      this.$nextTick(() => {
        this.loadUsers();
      })
    },
    clearSearch () {
      this.lSearch = '';
    },
    showUserModal (user) {
      this.editUser = JSON.parse(JSON.stringify(user));
      this.$refs['edit-user-modal'].show();
    },
    removeRole (roleId) {
      this.editUser.role_ids = _.reject(this.editUser.role_ids, r => { return r === roleId });
    },
    addRole (roleId) {
      if (!this.editUser.role_ids) this.editUser.role_ids = [];
      this.editUser.role_ids.push(roleId);
    },
    saveUser () {
      let data = {user_id: this.editUser.id, roles: this.editUser.role_ids};
      this.$store.dispatch('editUserRoles', data)
      .then(() => {
        _.findWhere(this.users, {id: this.editUser.id}).role_ids = this.editUser.role_ids;
      });
    },
    clearEditUser () {},
    canRemoveRole (roleId) {
      let role = _.findWhere(this.metadata.roles, { id: roleId });
      let roleLevel = _.findWhere(this.metadata.auth_levels, { id: role.auth_id });
      return this.$store.getters.getUserLevel > roleLevel.level;
    },
    inviteUser () {
      console.log('inviting user', this.inviteEmail);
      this.$store.dispatch('inviteUser', {email: this.inviteEmail})
      .catch(err => {
        alert(err);
      })
    },
    clearInvite () {
      this.inviteUser = undefined;
    }
  }
}
</script>

<style lang="scss" scoped>
.user-row {
  font-size: 1.4em;
  width: 100%;
  display: inline-grid;
  grid-template-columns: 2fr 3fr 3fr 8fr 1fr;
  background-color: var(--list-item-bg);
  padding: 5px 15px;
  margin-bottom: 10px;
  .action {
    cursor: pointer;
  }
}

.header {
  font-size: 1em;
  color: #ccc;
  background-color: transparent;
}

.search {
  margin: 20px 0;
  width: 50%;
}

.roles {
  display: flex;
  > div {
    margin-right: 5px;
  }
}

.edit-user-modal .roles {
  flex-direction: column;
  .role {
    display: flex;
  }
  .remove,
  .add {
    font-size: 1.5rem;
    line-height: 1rem;
    cursor: pointer;
  }
  .remove {
    color: red;
    margin-left: 7px;
  }
  .add {
    color: green;
  }
  .role,
  .add-role {
    margin-bottom: 5px;
  }
  .add-role {
    select {
      width: auto;
      height: auto;
    }
    button {
      padding: 3px 4px;
      margin-left: 4px;
      font-size: 0.9rem;
    }
  }
}

</style>
