<template>
  <div style="padding: 15px 0; max-width: 1400px">
    <div class="flex v-center" style="justify-content: space-between; margin-bottom: 18px">
      <div class="flex v-center" style="gap: 8px">
        <b-icon icon="funnel-fill" />
        <custom-input type="multiselect"
          required
          :searchable="false"
          v-model="typesToShow"
          @input="loadQueue"
          :select-config="{value: 'value', returnValue: 'value', options: [{name: 'Edits', value: QUEUE_TYPE_IDS.TGDB.EDIT}, {name: 'New Items', value: QUEUE_TYPE_IDS.TGDB.NEW}, {name: 'Deletes', value: QUEUE_TYPE_IDS.TGDB.DELETE}]}">
        </custom-input>
      </div>
      <div>
        <custom-input type="select"
          :searchable="false"
          v-model="sortAsc"
          @input="loadQueue"
          required
          :select-config="{value: 'value', returnValue: 'value', options: [{name: 'Date Added to Queue: Newest', value: false}, {name: 'Date Added to Queue: Oldest', value: true}]}">
        </custom-input>
      </div>
    </div>
    <div style="margin-bottom: 12px">Showing {{queue.length}} of {{totalCount}}</div>
    <div class="queue flex-col">
      <tgdb-queue-item v-for="i in queue" :key="i.id" :item="i"></tgdb-queue-item>
      <div v-if="queue.length > 0 && queue.length <= totalCount" style="margin: 12px 0">
        <b-button block variant="dark" @click="loadMore">Load More Results</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
import CustomInput from "@/components/CustomInput";
import {default as tgdbQueueItem} from "@/components/TGDBQueueItem";
import metadata from "@/mixins/MetadataMixin";
import {QUEUE_TYPE_IDS} from "@/store/constants.js";

export default {
  name: "TGDBQueue",
  mixins: [metadata],
  components: {
    CustomInput,
    tgdbQueueItem
  },
  data () {
    return {
      queue: [],
      totalCount: 0,
      curPage: 0,
      pageSize: 20,
      sortAsc: false,
      QUEUE_TYPE_IDS: QUEUE_TYPE_IDS,
      typesToShow: [QUEUE_TYPE_IDS.TGDB.EDIT, QUEUE_TYPE_IDS.TGDB.NEW, QUEUE_TYPE_IDS.TGDB.DELETE],
      error: false,
      errorMsg: ""
    }
  },
  created () {
    this.loadQueue();
  },
  methods: {
    loadQueue () {
      this.curPage = 0;
      this.queue = [];
      this.addToQueue();
    },
    addToQueue () {
      return this.$store.dispatch("queue/getTGDBQueue", {type_ids: this.typesToShow, limit: this.pageSize, offset: this.curPage * this.pageSize, ascending: this.sortAsc}).then(response => {
        var queue = _.map(response.data.records, r => {
          r.data = !_.isEmpty(r.json_data) ? JSON.parse(r.json_data) : {};
          return r;
        });
        if (_.isEmpty(queue)) return;
        // this is only provided on first page load
        if (this.curPage === 0) this.totalCount = response.data.full_count;
        Promise.all([
            this.$store.dispatch("encyclopedia/getMultipleItemDetails", _.compact(_.pluck(queue, "item_id"))),
            this.$store.dispatch("queue/getTGDBQueue", {type_ids: [QUEUE_TYPE_IDS.TGDB.IMAGE], limit: this.pageSize * 2, other_ids: _.compact(_.pluck(queue, "other_id"))})
        ]).then(responses => {
          let detailsResponse = responses[0];
          let imagesResponse = responses[1];
          let items = detailsResponse.data.items;
          let images = imagesResponse.data.records;

          _.each(queue, g => {
            g.oldData = _.findWhere(items, {id: g.item_id});
            // maybe temporary?
            if (g.oldData) {
              g.oldData.title = _.findWhere(g.oldData.titles, {type: 0}).title;
              _.extend(g.oldData, g.oldData.game_specific);
              g.title = g.oldData.title;

              // pluck/filter down GAMEYE's title structure to match TGDB's
              var alt_titles = _.pluck(_.filter(g.oldData.titles, function (titleData) {
                  return titleData.type !== 0;
              }), 'title');
              if (alt_titles.length === 0)
                g.oldData.alt_titles = null
              else
                g.oldData.alt_titles = alt_titles

            } else {
              g.title = g.data.title;
              if (g.item_id) {
                // This means it thinks it exists in GAMEYE, but we couldn't find it
                g.oldData = {};
                g.error = `This entry no longer exists in GAMEYE DB: ${g.item_id}`;
              }
            }
            
            _.extend(g.data, g.data.game_specific);

            g.imagesInQueue = _.findWhere(images, {other_id: g.other_id});
            if (g.imagesInQueue) {
              g.imagesInQueue.data = JSON.parse(g.imagesInQueue.json_data || "{}");
              if (!g.title) g.title = g.imagesInQueue.data.title;
            }
            
          })
          this.queue = [...this.queue, ...queue];
        })
      })
    },
    loadMore () {
      this.curPage += 1;
      this.addToQueue();
    }
  }
}
</script>

<style lang="scss" scoped>
.queue {
  row-gap: 16px;
  width: 100%;
}
</style>
