<template>
  <div>
    <b-button variant="primary" @click="addRow" style="margin-bottom: 8px">Add New</b-button>
    <paginated-table v-bind="[$attrs, $props]" :fields="_fields">
      <template v-for="(_, name) of $slots" v-slot:[name]>
        <slot :name="name"></slot>
      </template>
      <template v-for="(_, name) of $scopedSlots" v-slot:[name]="data">
        <slot :name="name" v-bind="data"></slot>
      </template>
      <template v-slot:cell(editCol)="{item}">
        <b-button :disabled="editing" @click="editRow(item)" size="sm" variant="secondary">Edit</b-button>
        <b-button v-if="canRemoveRows" @click="removeRow(item)" size="sm" variant="danger" style="margin-left: 8px">Remove</b-button>
      </template>
    </paginated-table>
    <b-modal v-model="editing">
      <div v-if="editing">
        <div v-for="(f, idx) in editableFields" :key="idx" class="flex v-center" style="margin-bottom: 8px">
          <span style="margin-right: 8px">{{ f.label || f.key }}:</span>
          <custom-input v-model="editedObj[f.key]" :type="f.type" :select-config="{options: f.options, value: f.value, label: f.idListField, getIconImg: f.getIconImg, getIcon: f.getIcon, returnValue: true}" :required="!f.optional" :imageType="f.imageType"></custom-input>
        </div>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <div class="error" style="font-size: 0.8em" v-if="saveError">{{saveError}}</div>
        <b-button variant="secondary" @click="cancel()">
          Cancel
        </b-button>
        <loading-button variant="primary" :async-func="save" @saved.prevent="saved(ok)" :disabled="saveDisabled" ref="save">Save</loading-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import PaginatedTable from "@/components/PaginatedTable";
import CustomInput from "@/components/CustomInput";
import LoadingButton from "@/components/LoadingButton";
import {BModal} from "bootstrap-vue";
import _ from "underscore";

export default {
  name: "EditableTable",
  components: {PaginatedTable, CustomInput, LoadingButton, BModal},
  data () {
    return {
      editedObj: undefined,
      editing: false,
      saveError: undefined
    }
  },
  props: {
    editUrlBase: {
      type: String,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    canRemoveRows: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    _fields () {
      return [...this.fields, ...[{key: "editCol", label: "", class: "edit-col"}]]
    },
    editableFields () {
      return _.filter(this.fields, f => { return !f.idField && !f.notEditable }) // images handled inline due to endpoint design (for now)
    },
    saveDisabled () {
      return false;
    }
  },
  methods: {
    addRow () {
      this.editedObj = {};
      this.saveError = undefined;
      this.editing = true;
    },
    editRow (rowItem) {
      this.editedObj = JSON.parse(JSON.stringify(rowItem));
      this.saveError = undefined;
      this.editing = true;
    },
    removeRow (rowItem) {
      this.$store.dispatch("meta/removeValue", { baseUrl: this.editUrlBase, id: rowItem.id });
    },
    save () {
      //TODO: save old row item and only save updated fields
      let value;
      // Assume if there is an image field, we are saving as a form
      if (_.every(this.fields, f => { return f.type !== 'image' }))
        value = this.editedObj;
      else {
        value = new FormData();
        _.each(this.editedObj, (val, key) => {
          let f = _.findWhere(this.fields, {key: key});
          if (f && (f.type !== 'image' || val instanceof File))
            value.append(key, val);
        });
      }
      return this.$store.dispatch(`meta/${this.editedObj.id ? 'edit' : 'add'}Value`, { baseUrl: this.editUrlBase, value: value })
        .then(() => {
          this.editing = false;
          this.editedObj = undefined;
          this.$emit('updated');
        })
        .catch(e => {
          console.error('error saving', e);
          this.saveError = "There was an issue saving. Check the logs for info"
        });
    },
    saved (okFunc) {
      if (!this.saveError) okFunc();
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .edit-col {
  width: 192px;
}
</style>
