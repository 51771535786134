<template>
  <div id="gimg"
    :class="{overflow: allowOverflow}"
    style="position: relative">
    <blur-hash-image v-bind="$attrs" v-if="hasBlurHash" v-on="$listeners"
      :src="url"
      :hash="imageObject.BlurHash"
      :width="blurHashWidth"
      :render-width="drawWidth"
      :height="blurHashHeight"
      :render-height="drawHeight"></blur-hash-image>
    <b-img-lazy v-else-if="$attrs.lazy" :src="$attrs.src || url" v-bind="$attrs" v-on="$listeners" :blank="!url && !$attrs.src" :blank-color="blankColor" :width="drawWidth" :height="drawHeight"/>
    <b-img v-else
      :src="$attrs.src || url"
      v-bind="$attrs"
      v-on="$listeners"
      :blank="!url && !$attrs.src"
      :blank-color="blankColor"
      :width="drawWidth"
      :height="drawHeight"
      :style="imgStyleObj"
    ></b-img>
  </div>
</template>

<script>
import BlurHashImage from "@/components/BlurHashImage";
import {BImg, BImgLazy} from "bootstrap-vue";
import ImageHelperMixin from "@/mixins/ImageHelperMixin";

export default {
  name: "GImage",
  components: {
    BlurHashImage,
    BImg,
    BImgLazy
  },
  mixins: [ImageHelperMixin],
  props: {
    imageObject: {
      type: Object,
      validator: obj => {
        // Allows no image def, shows placeholder
        return obj ? !!obj.Width && !!obj.Height && !!obj.File : true;
      }
    },
    imageSizeIdx: {
      type: Number,
      default: 1
    },
    drawWidth: {
      type: Number
    },
    drawHeight: {
      type: Number
    },
    imageType: {
      type: String,
      default: 'IMAGE'
    },
    blankColor: {
      type: String,
      default: '#5e6066'
    },
    hideBlurHash: {
      type: Boolean,
      default: false
    },
    allowOverflow: {
      type: Boolean,
      default: false
    },
    center: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    imgStyleObj () {
      if (!this.allowOverflow && this.center) {
        return {
          width: 'auto',
          height: 'auto',
          'max-height': '100%',
          'max-width': '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: `translate(-50%, -50%)`
        };
      } else if (this.allowOverflow && this.center) {
        return {
          width: 'auto',
          height: 'auto',
          'max-height': this.widerThanTall ? '100%' : 'unset',
          'max-width': this.widerThanTall ? 'unset' : '100%',
          position: 'absolute',
          top: this.widerThanTall ? 0 : '50%',
          left: this.widerThanTall ? '50%' : 0,
          transform: `translate${this.widerThanTall ? 'X' : 'Y'}(-50%)`
        }
      } else {
        return undefined;
      }
    },
    widerThanTall () {
      return this.imageObject ? this.imageObject.Width > this.imageObject.Height : undefined;
    },
    height () {
      if (this.allowOverflow && this.imageObject) {
        return this.widerThanTall ? "100%" : "auto";
      } else {
        return this.drawHeight;
      }
    },
    width () {
      if (this.allowOverflow && this.imageObject) {
        return this.widerThanTall ? "auto" : "100%";
      } else {
        return this.drawWidth;
      }
    },
    hasBlurHash () {
      return !!this.imageObject && !!this.imageObject.BlurHash && !this.hideBlurHash;
    },
    url () {
      let src = this.imageObject ? this.imageObject.File : this.$attrs.url;
      return src ? this.getImageURLByType(src, this.imageSizeIdx, this.imageType) : '';
    },
    blurHashWidth () {
      return this.getBlurHashWidth(this.imageObject.Height, this.imageObject.Width, this.drawHeight, this.drawWidth);
    },
    blurHashHeight () {
      return this.getBlurHashHeight(this.imageObject.Height, this.imageObject.Width, this.drawHeight, this.drawWidth);
    }
  }
}
</script>

<style lang="scss" scoped>
#gimg {
  height: 100%;
  width: 100%;
  text-align: center;
  &.overflow {
    overflow: hidden;
  }
}
</style>
