<template>
  <div style="margin-top: 15px; max-width: 1000px">
    <editable-table :fields="fields" edit-url-base="/internal/companies" :items="allCompanies" filterable dark :per-page="25"
      no-provider-paging no-provider-sorting no-provider-filtering sort-by="id" :filter-included-fields="['name']" :filter-function="findByName"
      @updated="$store.dispatch('meta/getCompanies', true)"></editable-table>
  </div>
</template>

<script>
import EditableTable from "@/components/EditableTable";

export default {
  name: "CompaniesManager",
  components: {
    EditableTable
  },
  computed: {
    allCompanies () {
      return this.$store.state.meta.allCompanies;
    }
  },
  data () {
    return {
      fields: [
        {
          key: "id",
          idField: true,
          sortable: true
        },
        {
          key: "name",
          sortable: true
        }
      ]
    }
  },
  created () {
    this.$store.dispatch('meta/getCompanies', true);
  },
  methods: {
    findByName (row, filter) {
      return row.name.toLowerCase().includes(filter.toLowerCase());
    }
  }
}
</script>