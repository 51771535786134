<template>
  <div style="display: flex" :class="['item-wrapper', displayType.toLowerCase() + '-type']">
    <slot name="before"></slot>
    <div class="item" :class="{selected: selected}">
      <div class="main" @click="clicked">
        <div class="img-col">
          <g-image blur-hash center
            :image-object="item.image"
            :draw-width="160"
            :draw-height="160"></g-image>
        </div>
        <div class="details-col g-container">
          <div class="flex">
            <div class="title">
              <div class="overflow-wrapper">
                <component :is="directLink ? 'a' : 'span'" :href="directLink" @click.prevent="" :title="item.title">{{item.title}}</component>
              </div>
              <div class="attrs">
                <pill v-if="item.release_date" :text="item.release_date | year" tag="span"></pill>
                <div v-if="item.country_id && metadata" class="flag">
                  <flag-icon style="margin-left: 5px; font-size: 15px" :country="metadata.countries[item.country_id]"></flag-icon>
                </div>
              </div>
            </div>
            <div class="flex">
              <platform :platform-id="item.platform_id" :field="displayType === 'Grid' ? 'short' : 'name'"></platform>
              <div class="platform v-h-center release-type" v-if="displayType !== 'Grid' && item.release_type">
                <span>({{parseListForMatchingId(metadata.release_types, item.release_type).name}})</span>
              </div>
            </div>
            <div class="description"><p>{{item.overview}}</p></div>
            <div v-if="displayType === 'Grid'" class="values">
              <pill class="value" :text="dev.name" v-for="dev in itemDevs" :key="dev.id" :variant="searchGroupMeta.Developer.variant"></pill>
            </div>
          </div>
        </div>
        <div class="prices-col flex-col">
          <div class="pills">
            <div class="label"><div>Genre:</div></div>
            <div class="values">
              <pill class="value" :text="genre.name" v-for="genre in itemGenres" :key="genre.id" :variant="searchGroupMeta.Genre.variant"></pill>
            </div>
          </div>
          <div class="pills">
            <div class="label">Developer:</div>
            <div class="values">
              <pill class="value" :text="dev.name" v-for="dev in itemDevs" :key="dev.id" :variant="searchGroupMeta.Developer.variant"></pill>
            </div>
          </div>
          <div class="pills">
            <div class="label">Publisher:</div>
            <div class="values">
              <pill class="value" :text="pub.name" v-for="pub in itemPubs" :key="pub.id" :variant="searchGroupMeta.Publisher.variant"></pill>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="img-col">
          <div style="height: 100%; margin-left: 25px; display: flex; align-items: center; line-height: 15px">
            <div class="icon" style="margin-top: -2px" title="Metacritic score"><icon-set icon="metacritic" height="13px"></icon-set></div>
            <div class="value" style="margin-left: 8px">
              <span v-if="item.metascore">{{item.metascore}}/100</span>
              <span v-else>-</span>
            </div>
          </div>
        </div>
        <div class="details-col"></div>
        <div v-if="!userData" class="prices-col">
          <div class="price"><price-pill icon="loose" title="Loose" :price="item.price ? item.price.Loose : undefined" round compact></price-pill></div>
          <div class="price"><price-pill icon="cib" title="CIB" :price="item.price ? item.price.CIB : undefined" round compact></price-pill></div>
          <div class="price"><price-pill icon="new" title="New" :price="item.price ? item.price.New : undefined" round compact></price-pill></div>
        </div>
        <div v-else class="prices-col">
          <!-- <pill class="value" :text="dev.name" v-for="dev in itemDevs" :key="dev.id" :variant="searchGroupMeta.Developer.variant"></pill> -->
          <tag-pill v-for="tag in userData.tags" :text="tag.tag" tag="span" :key="tag.uuid" :text-color="tagTextColorForID(tag.color_id)" :background-color="tagColorForID(tag.color_id)"></tag-pill>
          <pill :text="userData.ownership_mask_string" tag="span"></pill>
          <div class="price"><price-pill :title="userData.ownership_mask_string" :price="userData.calculated_price ? userData.calculated_price : undefined" round compact></price-pill></div>
        </div>
      </div>
    </div>
    <slot name="after"></slot>
  </div>
  
</template>

<script>
import GImage from '@/components/GImage';
import Pill from '@/components/Pill';
import Platform from '@/components/Platform';
import PricePill from '@/components/PricePill';
import IconSet from '@/components/IconSet';
import listItemMixin from '@/mixins/EncyclopediaListItemDataMixin';
import FlagIcon from '@/components/FlagIcon';
import TagPill from '@/components/TagPill';

export default {
  name: "EncyclopediaListItem",
  components: {
    Pill,
    GImage,
    Platform,
    PricePill,
    IconSet,
    FlagIcon,
    TagPill
  },
  mixins: [listItemMixin],
  props: {
    displayType: {
      type: String,
      required: true
    },
    directLink: {
      // adds href to item so you can right click (only if defined)
      type: String
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.item,
.item .main {
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.item-wrapper {
  &.grid-type {
    .main .prices-col,
    .footer .img-col,
    .description {
      display: none;
    }
    .main .title {
      font-size: 1.25em;
      flex-direction: column;
      > div {
        margin-left: 0;
        margin-top: 8px;
      }
    }
    .prices-col {
      min-width: 75%;
      .price .value {
        font-size: 0.8em;
      }
      .price .label {
        font-size: 0.8em;
      }
    }
    .details-col {
      padding-top: 10px;
    }
  }
  &.list-type {
    width: 100%;
    .details-col {
      margin: 0 10px;
    }
    .prices-col {
      width: 400px;
      min-width: 400px;
    }
  }
}

.item {
  border-radius: 12px;
  flex-direction: column;
  overflow: hidden;
  box-shadow: 3px 0 3px rgba(0,0,0,0.35);
  width: 100%;
  .main {
    width: 100%;
    height: 160px;
    background-color: var(--list-item-bg);
    z-index: 1;
    overflow: hidden;
    flex-direction: row;
    .title {
      display: flex;
      align-items: flex-start;
      font-size: 1.25rem;
      line-height: 1.25rem;
      margin-bottom: 5px;
      > .overflow-wrapper {
        white-space: pre-wrap;
        overflow: hidden;
        line-clamp: 2;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        box-orient: vertical;
        -webkit-box-orient: vertical;
      }
      a {
        color: currentColor;
      }
      > .attrs {
        margin-left: 10px;
        height: 1.25rem;
        display: flex;
        flex-wrap: nowrap;
        &:first-child {
          margin-left: 20px;
        }
        .pill,
        .flag {
          display: flex;
          align-items: center;
          font-size: 0.65em;
        }
      }
    }
    &:hover {
      background-color: #555760;
    }
    .img-col {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .details-col {
      padding-top: 17.5px;
      .platform {
        margin: 2px 0 15px 0;
        font-size: 0.8rem;
      }
      .description {
        font-size: 0.85rem;
        color: #F3F3F3;
        p {line-height: 1.5;}
      }
      .platform, .description {
        p {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  .img-col {
    width: 160px;
    min-width: 160px;
  }
  .prices-col {
    padding: 15px 10px;
    .pills {
      flex-grow: 1;
      padding: 2px 4px;
      display: grid;
      grid-template-columns: 1fr 3fr;
      align-items: flex-start;
      z-index: 2;
      margin-bottom: 7.5px;
      .label,
      .values .value {
        margin-top: 2.5px;
        margin-bottom: 2.5px;
      }
      .label {
        color: #BEBCBC;
        margin-right: 1em;
        text-align: right;
        line-height: 1.25em;
        padding: 0.25em 0;
      }
    }
    .label {
      font-size: 0.8rem;
    }
    .value {
      font-size: 1rem;
    }
    .value, .label {
      display: inline-block;
    }
  }
  .details-col {
    flex-grow: 1;
    padding-left: 10px;
    > .flex {
      flex-direction: column;
    }
    > .title {
      font-weight: bold;
    }
    .release-type > span {
      border-left: 1px solid white;
      padding-left: 0.5em;
      margin-left: 0.5em;
      color: #BEBCBC;
    }
  }
}

.values {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 2.5px;
}

.main:hover {
  cursor: pointer;
}

.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: var(--list-item-footer-bg);
  height: 35px;
  .prices-col .pills {
    display: grid;
    .pill {
      min-width: 100px;
      width: 100%;
    }
  }
}

.footer > .prices-col {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
  align-items: center;
  overflow: hidden;
  position: relative;
  padding: 0 15px 0 30px;
  .price {z-index: 1;}
}
</style>
