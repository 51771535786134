<template>
  <div :class="['pill', variant]">
    <div v-if="icon" style="width: 20px; display: flex; flex-direction: column; align-items: center">
      <icon-set :icon="icon" height="16px" :title="title || icon" style="margin-top: -1px"></icon-set>
    </div>
    <span class="value">{{price ? formatCentsToUSD(price, round, compact) : '-'}}</span>
  </div>
</template>

<script>
import IconSet from '@/components/IconSet';
import utilsMixin from '@/mixins/utilsMixin';

export default {
  name: 'PricePill',
  mixins: [utilsMixin],
  components: {
    IconSet
  },
  props: {
    icon: {
      type: String
    },
    price: {
      type: Number
    },
    round: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: ''
    },
    title: {
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
.pill {
  font-size: 14px;
  display: flex;
  background-color: #0D91FF;
  border-radius: 5px;
  padding: 4px 8px;
  &.transparent {
    background-color: transparent;
  }
}

.value {
  margin-left: 5px;
}
</style>