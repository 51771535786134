<template>
<div style="display: flex" :title="country ? country.name : 'None'">
  <b-img v-if="src" :src="src" :alt="country.abbr" style="height: 1em"></b-img>
  <span v-else style="font-size: 0.8em; padding: 2px 4px; border-radius: 2px; background-color: #99999999; height: 1rem">{{country ? country.abbr : 'N/A'}}</span>
</div>
</template>

<script>
import imgMixin from '@/mixins/ImageHelperMixin';
import {BImg} from 'bootstrap-vue';
export default {
  name: 'FlagIcon',
  mixins: [imgMixin],
  components: {BImg},
  props: {
    country: {
      type: Object
    },
    size: {
      type: Number,
      default: 0
    }
  },
  computed: {
    src () {
      return !!this.country && this.country.file ? this.getImageURLByType(this.country.file, this.size, 'FLAG') : undefined;
    }
  }
}
</script>

<style lang="scss">

</style>
