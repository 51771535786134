<template>
  <div v-if="userAllowedToSee" :class="$vnode.data.staticClass" :style="$vnode.data.staticStyle">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "AuthWrapper",
  props: {
    requiredLevel: {
      type: Number,
      required: true
    },
    allowedOverride: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    userAllowedToSee () {
      return this.allowedOverride || this.checkUserLevel(this.requiredLevel);
    }
  }
}
</script>
