import Vue from "vue";
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import App from "./App.vue";
import "./main.css";
import router from "./router";
import store from "./store";
import api from "@/store/axios.js";
import { updateAuthToken } from "@/mixins/utils.js";
import moment from 'moment';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import ImageHelperMixin from "@/mixins/ImageHelperMixin";
import MetadataMixin from "@/mixins/MetadataMixin";
import { IMAGE_TYPES_BASE_URL_META_KEYS, AUTH_LEVELS } from "@/store/constants";

// Firebase App (the core Firebase SDK) is always required and must be listed first
import { initializeApp } from "firebase/app";
import { getAnalytics, isSupported } from "firebase/analytics";
import { getPerformance } from "firebase/performance";
import { getIdTokenResult, getAuth } from "firebase/auth";
import _ from "lodash";

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

const getUTCDateStringFromDate = (date) => {
  return moment(date).utc().format('DD-MMM-YYYY');
};

const getDateStringFromDate = (date) => {
  return moment(date).format('DD-MMM-YYYY');
};
const getDateStringFromDateHMS = (date) => {
  return moment.utc(date).local().format('DD-MMM-YYYY HH:mm:ss');
};

const parseDate = (val) => {
  if(!val) return val;
  let date;
  if (_.isString(val)) {
    if (!isNaN(val)) date = new Date(Number.parseInt(val));
    else date = new Date(Date.parse(val));
  } else {
    date = new Date(val);
  }
  return date;
}

// @TODO: this is all super confusing. It should clearly mark what it expects via the name
// i.e., a Date object, Timestamp (seconds) object, Timestamp (milliseconds) object, etc...

Vue.filter('date-seconds', function (val) {
  if (!val) return val;
  return getUTCDateStringFromDate(new Date(val*1000));
});

Vue.filter('date-time-seconds', function (val) {
  if (!val) return val;
  return moment(new Date(val*1000)).utc().format('DD-MMM-YYYY, HH:mma')
})

Vue.filter('date', function (val) {
  if(!val) return val;
  let date = parseDate(val);
  return getUTCDateStringFromDate(date);
})

Vue.filter('date-local', function (val) {
  if(!val) return val;
  let date = parseDate(val);
  return getDateStringFromDate(date);
})

Vue.filter('date-local-hms', function (val) {
  if(!val) return val;
  let date = parseDate(val);
  return getDateStringFromDateHMS(date);
})

Vue.filter('year', function (val) {
  if (!val) return val;
  return moment(new Date(val*1000)).format('YYYY');
})

Vue.filter('removeEmailDomain', function (val) {
  if (!val) return val;
  return val.substring(0, val.lastIndexOf("@"));
});

Vue.filter('capitalize', (val) => {
  if (!val) return val;
  return val.charAt(0).toUpperCase() + val.slice(1);
});

Vue.filter('formattedNumber', (val) => {
  if (!val || !_.isNumber(val)) return val;
  return new Intl.NumberFormat().format(val);
})

Vue.mixin(ImageHelperMixin);

Vue.mixin(MetadataMixin);

Vue.mixin({
  IMAGE_TYPES_BASE_URL_META_KEYS: IMAGE_TYPES_BASE_URL_META_KEYS,
  AUTH_LEVELS: AUTH_LEVELS,
  computed: {
    loggedInUser () {
      return this.$store.state.user && this.$store.state.fbUser ? {...(this.$store.state.fbUser || {}), ...this.$store.state.user} : undefined;
    },
    userColor () {
      if (!this.loggedInUser) return undefined;
      return this.loggedInUser.user_blob ? this.loggedInUser.user_blob.color : undefined;
    },
    userProfileImage () {
      if (!this.loggedInUser) return undefined;
      return this.loggedInUser.user_blob ? this.loggedInUser.user_blob.profile_pic : undefined;
    }
  },
  methods: {
    checkUserLevel (requiredLevel) {
      const level = this.$store.getters.getUserLevel;
      return level >= requiredLevel;
    },
    values (obj) { return _.values(obj); },
    map (collection, it) { return _.map(collection, it); },
    hasValue (val) { return !_.isNull(val) && !_.isUndefined(val); },
    isEmpty (val) { return _.isEmpty(val); }
  }
})

// Firebase initialization
var firebaseConfig = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MSG_SENDER_ID,
  appId: process.env.VUE_APP_FB_APP_ID,
  measurementId: process.env.VUE_APP_FB_MEASUREMENT_ID
};
const firebase = initializeApp(firebaseConfig);
isSupported().then(yes => {
  if (yes) {
    const analytics = getAnalytics(firebase);
    // add any events here
  }
});
const performance = getPerformance(firebase);

// auth interceptor for all api calls
api.interceptors.request.use(function (config) {
  const auth = getAuth();
  if (auth.currentUser) {
    // Gets token from localstorage. But if it's expired, it refreshes it before 
    return getIdTokenResult(auth.currentUser)
    .then(result => {
      let oldToken = JSON.parse(localStorage.getItem('auth')).token;
      if (result.token !== oldToken) updateAuthToken({token: result.token, expires: result.expirationTime});
      config.headers["Authorization"] = `Bearer ${result.token}`;
      return Promise.resolve(config);
    });
  }
  return config;
});

new Vue({
  router,
  store,
  render: function(h) {
    return h(App);
  }
}).$mount("#app");
