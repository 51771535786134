import axios from "./axios";
import {getJsonFromHead} from "@/mixins/utils.js";
import _ from "underscore";

const META_TYPES = {
  metadata: {
    axios: () => axios.get("/meta"),
    headerKey: "metadata"
  },
  platforms: {
    axios: () => axios.get("/platforms"),
    headerKey: "platforms"
  },
  companies: {
    axios: () => axios.get("/companies"),
    headerKey: "companies"
  },
  genres: {
    axios: () => axios.get("/genres"),
    headerKey: "genres"
  },
  popularGames: {
    axios: () => axios.get("/popular_games"),
    headerKey: "popular_games"
  },
  itemCounts: {
    axios: () => axios.get("/item_counts"),
    headerKey: "item_counts"
  }
}

const chooseDataSourcePromise = (metaTypeObj) => {
  let headSrc = getJsonFromHead(metaTypeObj.headerKey);
  if (headSrc && !_.isEmpty(headSrc)) {
    console.log(`Got ${metaTypeObj.headerKey} from header`, headSrc)
    return Promise.resolve({data: headSrc});
  }
  else return metaTypeObj.axios();
}

export default {
  namespaced: true,
  state: () => ({
    metadata: {},
    developers: [],
    publishers: [],
    genres: [],
    allCompanies: [],
    platformsData: {},
    allMetaLoaded: false
  }),
  mutations: {
    setMetadata(state, metadata) {
      metadata.countries_list = JSON.parse(JSON.stringify(metadata.countries));
      metadata.countries = _.object(_.pluck(metadata.countries, 'id'), metadata.countries);
      state.metadata = metadata;
    },
    setDevelopers(state, comps) {
      state.developers = comps;
    },
    setPublishers(state, comps) {
      state.publishers = comps;
    },
    setAllCompanies(state, comps) {
      state.allCompanies = comps;
    },
    setGenres(state, genres) {
      state.genres = genres;
    },
    setPlatformsData(state, platformsData) {
      state.platformsData = platformsData;
    },
    setMetaLoaded(state) {
      state.allMetaLoaded = true;
    }
  },
  actions: {
    getPlatforms(context) {
      return chooseDataSourcePromise(META_TYPES.platforms).then((response) => {
        context.commit("setPlatformsData", response.data);
        return response.data;
      });
    },
    getMetadata(context) {
      if (!_.isEmpty(context.state.metadata)) return;
      return chooseDataSourcePromise(META_TYPES.metadata).then((response) => {
        context.commit("setMetadata", response.data);
        return response.data;
      });
    },
    getCompanies(context, keepAllCompanies) {
      return chooseDataSourcePromise(META_TYPES.companies).then((response) => {
        let devs = JSON.parse(JSON.stringify(_.where(response.data.companies, comp => { return _.contains(response.data.developers, comp.id); })));
        context.commit("setDevelopers", _.object(_.pluck(devs, 'id'), devs));
        let pubs = JSON.parse(JSON.stringify(_.where(response.data.companies, comp => { return _.contains(response.data.publishers, comp.id); })));
        context.commit("setPublishers", _.object(_.pluck(pubs, 'id'), pubs));

        if (keepAllCompanies) context.commit("setAllCompanies", response.data.companies);

        return response.data;
      });
    },
    getGenres(context) {
      return chooseDataSourcePromise(META_TYPES.genres).then((response) => {
        context.commit("setGenres", _.object(_.pluck(response.data, 'id'), _.values(response.data)));
        return response.data;
      });
    },
    getAllMeta(context) {
      if (!context.state.allMetaLoaded) {
        return Promise.all(
          [context.dispatch('getPlatforms'),
          context.dispatch('getMetadata'),
          context.dispatch('getCompanies'),
          context.dispatch('getGenres')]
        )
        .then(() => {
          context.commit("setMetaLoaded");
        })
      }
    },
    getProfilePics () {
      return axios.get('/internal/profile_pics');
    },
    editValue ({}, {baseUrl, value}) {
      return axios.post(`${baseUrl}/update_metadata`, value);
    },
    addValue ({}, {baseUrl, value}) {
      return axios.put(`${baseUrl}/add`, value);
    },
    removeValue({}, {baseUrl, id}) {
      return axios.delete(`${baseUrl}/remove`, {params: { id: id }});
    },
    // I'm not sure this really makes sense in metadata
    getPopularGames() {
      return chooseDataSourcePromise(META_TYPES.popularGames).then((response) => {
        return response.data;
      })
    },
    getCategoryCounts() {
      return chooseDataSourcePromise(META_TYPES.itemCounts).then(response => {
        return response.data;
      });
    }
  }
}