<template>
  <div style="height: 100%; max-height: 100%">
    <transition>
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  name: "ToolsHome"
}
</script>