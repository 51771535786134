import axios from "./axios";

export default {
  namespaced: true,
  actions: {
    getTGDBQueue({}, data) {
      return axios.post("/mod_queue", data);
    },
    deleteFromTGDBQueue({}, id) {
      return axios.delete("/mod_queue", {params: {id: id}});
    }
  }
}