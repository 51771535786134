<template>
  <div class="search-bar">
    <div :class="['input-with-icon right input-group', `size-${size}`]" :style="{'background-color': `var(--input-${variant}-bg)`}">
      <b-form-input v-model="lSearch" @keydown.enter.native.stop="searched" :placeholder="placeholder"></b-form-input>
      <span>
        <b-button :variant="lSearch || showButton ? 'primary' : 'link'" size="sm" :disabled="!(lSearch || showButton)" @click="searched">
          <b-icon-search></b-icon-search>
          <span v-if="lSearch || showButton">&nbsp;&nbsp;Search</span>
        </b-button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchBar",
  props: {
    //variant: 'dark' or 'grey'
    variant: {
      type: String,
      default: "dark"
    },
    placeholder: {
      type: String
    },
    //size: 'sm' or 'lg'
    size: {
      type: String,
      default: "lg"
    },
    showButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      lSearch: ''
    }
  },
  methods: {
    searched () {
      this.$emit('searched', this.lSearch);
    }
  },
  watch: {
    '$route.query.q': {
      handler: function(s) {
        this.lSearch = s;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 16px;
  .input-with-icon {
    &.size-lg {
      border-radius: 24px;
      > span {
        height: 34px;
        .btn {
          border-radius: 22px;
          padding-left: 24px;
          padding-right: 24px;
          font-size: 0.85rem;
          .b-icon {
            height: 0.85rem;
          }
        }
      }
      input.form-control {
        height: 34px;
      }
    }
    &.size-sm {
      border-radius: 20px;
      > span {
        .btn {
          border-radius: 18px;
          font-size: 0.75rem;
        }
      }
    }
    > span {
      right: 2px;
      .btn {
        &.btn-link {
          color: inherit;
          cursor: default;
          width: 30px;
        }
        &:not(.btn-link) > .b-icon {
          font-size: 0.8rem;
        }
        height: 100%;
        box-shadow: none;
        transition: all ease 0.4s;
      }
    }
  }
  > .input-group {
    padding: 2px 5px;
    input.form-control {
      background-color: transparent;
      border: 0;
      border-radius: 0;
      border-color: #707070;
      color: #fff;
    }
  }
}
</style>