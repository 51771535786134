<template>
  <div>
    <div id="privacyPolicy">
      <div class="text grey">I have read the <a href="/legal/privacy-policy" target="_blank">Privacy Policy</a> and agree to it</div>
      <b-form-checkbox v-model="acceptedPrivacyPolicy">I Agree</b-form-checkbox>
    </div>
    <div id="agreement">
      <div class="text grey">I have read the <a href="/legal/terms-of-use" target="_blank">Terms of Service</a> and agree to it</div>
      <b-form-checkbox v-model="acceptedAgreement">I Agree</b-form-checkbox>
    </div>
  </div>
</template>

<script>
import {BFormCheckbox} from "bootstrap-vue";

export default {
  name: "UserPolicies",
  components: {BFormCheckbox},
  data () {
    return {
      acceptedPrivacyPolicy: false,
      acceptedAgreement: false
    }
  },
  computed: {
    acceptedBoth () {
      return this.acceptedAgreement && this.acceptedPrivacyPolicy;
    }
  },
  watch: {
    acceptedBoth (newVal, oldVal) {
      if (oldVal !== newVal) this.$emit("input", newVal);
    }
  }
}
</script>

<style lang="scss" scoped>
#privacyPolicy, #agreement {
  margin-bottom: 32px;
  .text {
    margin-bottom: 4px;
  }
}
</style>
