<template>
  <div class="r-item">
    <div :class="['main', { selected: selected }]" @click="clicked">
      <div class="img">
        <g-image blur-hash fluid
          :image-object="item.image"
          :draw-width="185"
          :draw-height="210"
          :image-size-idx="2"
        ></g-image>
      </div>
      <div class="details">
        <div style="font-size: 1.2em">{{item.title}}</div>
        <div class="flex">
          <platform :platform-id="item.platform_id"></platform>
          <div v-if="item.release_type" class="platform v-h-center" style="margin-left: 1em;">
            <span>({{parseListForMatchingId(metadata.release_types, item.release_type).name}})</span>
          </div>
        </div>
        <div>
          <pill v-if="item.release_date" :text="item.release_date | year" tag="span"></pill>
          <pill v-if="item.country_id" :text="parseListForMatchingId(metadata.countries, item.country_id).abbr" tag="span"></pill>
          <pill v-if="item.has_vgpc"><img src="/logo-pricecharting-small.png" alt="" style="height: 1em; margin-top: -2px" title="Has VGPC Link"></pill>
        </div>
        <slot name="extra-content"></slot>
      </div>
      <div class="overlay" style="position: absolute; height: 100%; width: 100%; z-index: 40; background-color: rgba(255,255,255,0.2)">
        <b-icon-check-2 style="height: 100%; width: 100%; font-size: 80px"></b-icon-check-2>
      </div>
    </div>
    <div style="width: 100%; text-align: right; padding: 5px">
      <router-link :to="{ name: 'Details', params: { id: item.id } }" target="_blank">
        View Details <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
      </router-link>
    </div>
  </div>
</template>

<script>
import GImage from '@/components/GImage';
import Platform from '@/components/Platform'
import Pill from '@/components/Pill'
import ItemData from '@/mixins/EncyclopediaListItemDataMixin';
import {BIconCheck2, BIconBoxArrowUpRight} from 'bootstrap-vue';

export default {
  name: 'RegionEncyclopediaItem',
  mixins: [ItemData],
  components: {
    GImage,
    Platform,
    Pill,
    BIconCheck2,
    BIconBoxArrowUpRight
  }
}
</script>

<style lang="scss" scoped>
.r-item {
  padding-bottom: 15px;
  .main {
    position: relative;
    height: 100%;
    width: 250px;
    display: flex;
    flex-direction: column;
    background-color: #2b2d32;
    box-shadow: 3px 0 3px rgba(0,0,0,0.35);
    cursor: pointer;
    &:not(.selected) {
      .overlay {
        display: none;
      }
    }
    .details {
      padding: 5px;
      > div {
        margin-bottom: 5px;
        .pill {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
