<template>
  <div class="flex-col" style="height: 100%">
    <div class="flex">
      <div class="selected-col">
        <div class="vgpc-selected">
          <div class="close" v-show="vgpcSelected" @click="deselectVgpc()"><b-icon icon="x"/></div>
          <comparison-display-item v-if="vgpcSelected" :item="vgpcSelected" :fields="getFields('vgpc')" align-right hide-alts />
          <div class="flex-col v-center center" v-else style="height: 100%">
            <img src="/logo-pricecharting-new.png" alt="" style="height: 48px; opacity: 0.2; margin-bottom: 16px">
            <div class="grey" style="font-size: 1.2em">
              Select Item Below to View
            </div>
          </div>
        </div>
        <div style="width: 100%; text-align: right; padding: 5px; height: 16px">
          <a v-if="vgpcSelected" :href="vgpcSelected.vgpc_url" target="_blank">
            View on VGPC <b-icon icon="box-arrow-up-right" ></b-icon>
          </a>
        </div>
      </div>
      <div class="selected-labels text-center">
        <div class="title">
          <b-button id="vgpcLinkBtn" :variant="!!geSelected && !!vgpcSelected && geSelected.has_vgpc ? 'warning' : 'success'" :disabled="!(!!vgpcSelected && !!geSelected)" @click="showLinkConfirmModal = true">
            <div class="flex center">
              <b-icon-link scale="0.6"/>
              &nbsp;<span v-if="!linkingItems">Link</span><b-spinner small v-else />&nbsp;
              <b-icon-link  scale="0.6"/>
            </div>
          </b-button>
          <div v-if="!!geSelected && !!vgpcSelected && geSelected.has_vgpc" class="italic flex text-warning" style="font-size: 0.6rem; margin-top: 4px">
            <b-icon icon="exclamation-triangle-fill" />
            <div>&nbsp;Existing VGPC Link&nbsp;</div>
            <b-icon icon="exclamation-triangle-fill" />
          </div>
          <div v-if="linkItemError" class="italic text-danger" style="font-size: 0.6rem; margin-top: 4px">
            Error: {{linkItemError.data}}
          </div>
        </div>
        <div class="platform"></div>
        <div v-for="field in fields" class="field" :key="field.label + 'label'">
          <span>{{field.label}}</span>
        </div>
      </div>
      <div class="selected-col">
        <div class="right-selected" style="position: relative;">
          <div class="close" v-show="geSelected" @click="deselectGe()"><b-icon icon="x"/></div>
          <div id="vgpcLinkIndicator" v-if="geSelected" :class="{ 'has-link': geSelected.has_vgpc }">
            <span v-if="geSelected.has_vgpc"><b-icon icon="check2" scale="1.4"/>&nbsp;&nbsp;</span>
            <span v-else>No </span>
            VGPC
          </div>
          <comparison-display-item v-if="geSelected" :item="geSelected" :fields="getFields('ge')" hide-alts />
          <div class="flex-col v-center center" v-else-if="!vgpcSelected" style="height: 100%">
            <div class="text-center" style="opacity: 0.2; margin-bottom: 16px">
              <div style="margin-bottom: 0.75rem"><icon-set icon="logo" height="40px"></icon-set></div>
              <div><icon-set icon="logo-text" height="20px"></icon-set></div>
            </div>
            <div class="grey" style="font-size: 1.2em">
              Select Item Below to View
            </div>
          </div>
          <div class="overlay flex-col v-center center" v-else>
            <h5 style="margin-bottom: 16px">No matching game in GAMEYE below?</h5>
            <b-button id="addBtn" @click="addToGameyeClicked" variant="primary">
              <b-icon icon="plus-square-dotted" />&nbsp;&nbsp;&nbsp;Add to GAMEYE
            </b-button>
            <hr style="width: 25%; min-width: 80px; border-color: var(--input-grey-text)"/>
            <b-button id="skipBtn" @click="skipSelectedVgpc" variant="danger">
              <b-icon icon="x-square-fill" />&nbsp;&nbsp;Skip this Game
            </b-button>
          </div>
        </div>
        <div style="width: 100%; text-align: right; padding: 5px; height: 16px">
          <router-link v-if="geSelected" :to="{ name: 'Details', params: { id: geSelected.id } }" target="_blank">
            View Details <b-icon icon="box-arrow-up-right"></b-icon>
          </router-link>
        </div>
      </div>
    </div>
    <div class="flex" style="min-height: 64px; max-height: 64px">
      <div style="width: 46%; margin: 16px" class="flex center">
        <img src="/logo-pricecharting-new.png" alt="" style="height: 48px">
      </div>
      <div style="width: 8%"></div>
      <div style="width: 46%; margin: 16px" class="flex center">
        <div><icon-set icon="logo" height="40px" style="margin-right: 16px"></icon-set></div>
        <div><icon-set icon="logo-text" height="20px"></icon-set></div>
      </div>
    </div>
    <div class="flex" style="flex-grow: 1; max-height: calc(100% - 416px)">
      <div class="vgpc-search" ref="vgpcScrollContainer">
        <browse ref="vgpcBrowse" hide-filters hide-display-type-options hide-title :sorting-options="$options.vgpcSortOptions" hide-categories-filter
            :category="vgpcCategory.name" :filters-in-url="false" :override-params="vgpcParams"
            selectable-items select-only-one store="vgpc" searchable :scroll-parent="$refs.vgpcScrollContainer">
          <template v-slot:listItem="item">
            <VGPCListItem :item="item.scope" class="vgpc-item" @selected="vgcpItemSelected"></VGPCListItem>
          </template>
          <template v-slot:extra-search>
            <div class="flex" style="gap: 0.5rem">
              <!--<multiselect v-model="vgpcCategory" :options="categories" placeholder="Category" track-by="name"
                select-label="" deselect-label="" selected-label="" :allow-empty="false"
                @select="vgpcDataChanged" open-direction="below">
                <template v-slot:option="{option}">
                  <div class="text-nowrap">
                    <icon-set class="icon unfilled" style="margin-right: 8px" :icon="option.icon" width="16px" height="16px"></icon-set>
                    <span>{{option.label}}</span>
                  </div>
                </template>
                <template v-slot:singleLabel="{option}">
                  <div class="text-nowrap">
                    <icon-set class="icon unfilled" style="margin-right: 8px" :icon="option.icon" width="16px" height="16px"></icon-set>
                    <span>{{option.label}}</span>
                  </div>
                </template>
              </multiselect>-->
              <multiselect v-model="vgpcPlatform" placeholder="Platform" :options="platformsList" track-by="id"
                select-label="" deselect-label="" selected-label="" :custom-label="platformSearchString"
                @input="updatePlatformFilters($event, 'vgpc')" open-direction="below">
                <template v-slot:option="props">
                  <platform :platform-id="props.option.id"></platform>
                </template>
                <template v-slot:singleLabel="props">
                  <platform :platform-id="props.option.id" field="short"></platform>
                </template>
              </multiselect>
            </div>
          </template>
          <template v-slot:extra-results v-if="vgpcIdsSkipped && vgpcIdsSkipped.length > 0">
            <span class="grey italic">{{vgpcIdsSkipped.length}} Total Skipped&nbsp;&nbsp;</span>
            <b-button variant="link" size="sm" @click="clearSkippedVgpcs" style="padding: 0">clear all</b-button>
          </template>
        </browse>
      </div>
      <div style="width: 8%">
        <div v-if="vgpcSelected" style="padding: 10px 5px" class="text-center">
          <b-button variant="dark" size="sm" @click="autoFillGameyeSearch">
            Autofill&nbsp;<b-icon-arrow-right-short size="sm"/>
          </b-button>
          <div class="grey italic" style="font-size: 0.9em; margin: 4px 0">for</div>
          <div>{{vgpcSelected.title}}</div>
        </div>
      </div>
      <div class="ge-search" ref="geScrollContainer">
        <browse ref="geBrowse" hide-filters hide-display-type-options hide-title hide-sort-options hide-categories-filter
            :category="geCategory.name" :filters-in-url="false"
            selectable-items select-only-one store="encyclopedia" searchable :scroll-container="$refs.geScrollContainer">
          <template v-slot:listItem="item">
            <region-list-item :item="item.scope" class="ge-item" @selected="geItemSelected"></region-list-item>
          </template>
          <template v-slot:extra-search>
            <div class="flex" style="gap: 0.5rem">
              <multiselect v-model="geCategory" :options="categories" placeholder="Category" track-by="name"
                select-label="" deselect-label="" selected-label="" :allow-empty="false"
                @select="updateFilters('ge')" open-direction="below">
                <template v-slot:option="{option}">
                  <div class="text-nowrap">
                    <icon-set class="icon unfilled" style="margin-right: 8px" :icon="option.icon" width="16px" height="16px"></icon-set>
                    <span>{{option.label}}</span>
                  </div>
                </template>
                <template v-slot:singleLabel="{option}">
                  <div class="text-nowrap">
                    <icon-set class="icon unfilled" style="margin-right: 8px" :icon="option.icon" width="16px" height="16px"></icon-set>
                    <span>{{option.label}}</span>
                  </div>
                </template>
              </multiselect>
              <multiselect v-model="gePlatform" placeholder="Platform" :options="platformsList" track-by="id"
                select-label="" deselect-label="" selected-label="" :custom-label="platformSearchString"
                @select="updatePlatformFilters($event, 'ge')" open-direction="below">
                <template v-slot:option="props">
                  <platform :platform-id="props.option.id"></platform>
                </template>
                <template v-slot:singleLabel="props">
                  <platform :platform-id="props.option.id" field="short"></platform>
                </template>
              </multiselect>
            </div>
          </template>
        </browse>
      </div>
    </div>
    <b-modal v-model="showAddModal" size="xl" title="Add Entry to GAMEYE" hide-footer header-close-variant="light" :busy="savingNewItem">
      <template #default="{ cancel }">
        <div class="flex compare-cols">
          <div style="flex-grow: 1">
            <div>
              <div id="oldVgpc">
                <div class="item-header">
                  <img src="/logo-pricecharting-new.png" alt="" style="height: 48px">
                </div>
                <comparison-display-item v-if="vgpcSelected" :item="vgpcSelected" :fields="getNewVgpcItemFields('vgpc')" vertical hide-alts />
              </div>
            </div>
          </div>
          <div class="v-h-center" style="font-size: 4em; margin-right: 56px">
            <b-icon-arrow-right-short />
          </div>
          <div class="flex-col" style="position: relative; flex-grow: 3; align-items: center">
            <div class="flex-col" style="align-items: center">
              <div id="newGameye">
                <div class="item-header flex center">
                  <div><icon-set icon="logo" height="40px" style="margin-right: 16px"></icon-set></div>
                  <div><icon-set icon="logo-text" height="20px"></icon-set></div>
                  <div class="grey italic" style="margin-left: 16px">(Preview)</div>
                </div>
                <comparison-display-item v-if="addPreview" editable :item="addPreview" :fields="getNewVgpcItemFields('ge')" vertical show-labels ref="newVgpcItem" @valid="newVgpcValidityUpdated" />
                <div v-else class="flex-col center" style="min-height: 160px;">
                  <b-spinner label="Generating New Entry Preview" />
                </div>
              </div>
              <div class="flex-col" style="margin: 32px 0 16px">
                <b-button variant="primary" style="padding: 16px 64px" :disabled="!addPreview || savingNewItem || !newItemValid" @click="addNewVgpc">
                  <b-spinner v-show="savingNewItem" small />
                  Add to GAMEYE
                </b-button>
                <div class="error" v-if="newItemError">{{newItemError}}</div>
                <div class="flex center"><b-button style="margin-top: 16px" @click="cancel()" :disabled="savingNewItem">Cancel</b-button></div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="showLinkConfirmModal" hide-header @ok="linkVgpcToGameye" :ok-variant="geSelected && geSelected.has_vgpc ? 'warning' : 'success'" :ok-title="geSelected && geSelected.has_vgpc ? 'Yes, Override' : 'Yes, Link'">
      <b-alert :show="geSelected && geSelected.has_vgpc" variant="warning"><b-icon icon="exclamation-triangle-fill" />&nbsp;Warning: you are overriding an existing VGPC Link</b-alert>
      Are you sure you want to link this (left) VGPC entry to this (right) GAMEYE entry?
    </b-modal>
  </div>
</template>

<script>
import { BIcon, BModal, BSpinner, BIconArrowRightShort, BButton, BIconLink, BAlert } from "bootstrap-vue";
import Browse from '@/views/Browse';
import VGPCListItem from  "@/components/VGPCListItem";
import RegionListItem from '@/components/RegionEncyclopediaItem';
import IconSet from '@/components/IconSet';
import _ from 'lodash';
import ComparisonDisplayItem from '@/components/ComparisonDisplayItem.vue';
import Platform from '@/components/Platform';
import Multiselect from "vue-multiselect";
import { VGPC_GENRE_MAPPINGS, CATEGORIES } from '@/store/constants.js';
import Vue from 'vue';

export default {
  name: "VGPCfication",
  components: {
    BIcon,
    BModal,
    BSpinner,
    BIconArrowRightShort,
    BButton,
    BIconLink,
    BAlert,
    Browse,
    VGPCListItem,
    RegionListItem,
    IconSet,
    ComparisonDisplayItem,
    Platform,
    Multiselect
  },
  // hard-coded copy from browse component. could prob make more generic/not copied
  vgpcSortOptions: [
    {
      text: 'Title: A - Z',
      value: 'title_asc',
      dbVal: 0,
      asc: 1
    },
    {
      text: 'Title: Z - A',
      value: 'title_desc',
      dbVal: 0,
      asc: 0
    },
    {
      text: 'Added Date: Newest',
      value: 'date_asc',
      dbVal: 6,
      asc: 0
    },
    {
      text: 'Added Date: Oldest',
      value: 'date_desc',
      dbVal: 6,
      asc: 1
    }
  ],
  data () {
    return {
      vgpcSelected: undefined,
      vgpcPlatform: undefined,
      vgpcCategory: _.find(CATEGORIES, {name: "games"}),
      geSelected: undefined,
      gePlatform: undefined,
      geCategory: _.find(CATEGORIES, {name: "games"}),
      fields: [
        {
          label: "Region",
          vgpc: "region",
          ge: "country_id",
          geFunc: cId => { return this.getFieldFromIdList(cId, this.metadata.countries, "abbr"); },
          geType: "select",
          idListField: "abbr",
          options: () => { return _.values(this.metadata.countries) },
          required: true
        },
        {
          label: "Release Date",
          vgpc: "release_date",
          vgpcFunc: Date.parse,
          ge: "release_date",
          geFunc: d => { return d*1000 },
          type: "date"
        },
        {
          label: "Publisher",
          vgpc: "publisher",
          ge: "pubs",
          geFunc: pIds => { return this.getFieldFromIdList(pIds, this.publishers); },
          geType: "multiselect",
          options: () => { return _.values(this.publishers) }
        },
        {
          label: "Developer",
          vgpc: "developer",
          ge: "devs",
          geFunc: dIds => { return this.getFieldFromIdList(dIds, this.developers); },
          geType: "multiselect",
          options: () => { return _.values(this.developers) }
        },
        {
          label: "Genre",
          vgpc: "genre",
          ge: "genres",
          geIdList: this.genres,
          geFunc: gIds => { return this.getFieldFromIdList(gIds, this.genres); },
          geType: "multiselect",
          options: () => { return _.values(this.genres) },
          required: (f, item) => {
            return item.category_id === _.find(this.categories, { name: "games" }).value;
          }
        },
        {
          label: "ESRB",
          vgpc: "esrb",
          ge: ["game_specific", "rating_id"],
          geFunc: pId => { return this.getFieldFromIdList(pId, this.metadata.parental_ratings); },
          geType: "select",
          options: () => { return this.metadata.parental_ratings },
          idListField: "name",
          getIconImg: opt => { return opt.file ? this.getImageURLByType(opt.file, 0, 'RATINGS') : undefined; },
          required: (f, item) => {
            return item.category_id === _.find(this.categories, { name: "games" }).value;
          }
        },
        {
          label: "Players",
          vgpc: "players",
          ge: ["game_specific", "num_players"],
          type: "number"
        }
      ],
      showAddModal: false,
      loadingAddPreview: false,
      savingNewItem: false,
      newItemError: undefined,
      newItemValid: false,
      linkingItems: false,
      showLinkConfirmModal: false,
      linkItemError: undefined,
      addPreview: undefined,
      vgpcSearch: '',
      vgpcSortSelected: undefined,
      vgpcIdsSkipped: []
    }
  },
  computed: {
    vgpcParams () {
      return !_.isEmpty(this.vgpcIdsSkipped) ? {exclude_ids: this.vgpcIdsSkipped.toString()} : undefined
    }
  },
  created () {
    if (localStorage.vgpcSkipped) this.vgpcIdsSkipped = JSON.parse(localStorage.vgpcSkipped);
  },
  watch: {
    vgpcIdsSkipped: {
      handler(newVal) { localStorage.vgpcSkipped = JSON.stringify(newVal); },
      deep: true
    }
  },
  methods: {
    newVgpcValidityUpdated (val) {
      this.newItemValid = val;
    },
    vgcpItemSelected (item, isSelected) {
      this.vgpcSelected = isSelected ? item : undefined;
      this.addPreview = undefined;
      this.newItemError = undefined;
      this.linkItemError = undefined;
      this.geSelected = undefined;
    },
    geItemSelected (item, isSelected) {
      this.geSelected = isSelected ? item : undefined;
    },
    getFieldFromIdList (val, idList) {
      if (_.isArray(val)) return _.values(this.parseObjectKeysForMatchingIds(idList, val));
      else return this.parseListForMatchingId(idList, val);
    },
    getFields (type) {
      return _.map(this.fields, f => {
        return {
          label: f.label,
          key: f[type],
          func: f[type + 'Func'],
          type: f[type + 'Type'] || f.type,
          idListField: f.idListField,
          options: f.options,
          required: f.required,
          getIconImg: f.getIconImg
        };
      })
    },
    getNewVgpcItemFields (type) {
      return [
        ...[
          {
            label: "Category",
            key: "category_id",
            type: "select",
            func: cId => { if (cId !== undefined) return this.parseListForMatchingVal(this.assignableCategories, {value: cId}) },
            options: () => { return this.assignableCategories; },
            idListField: "label",
            value: "value",
            required: true
          },
          {
            label: "Release Type",
            key: "release_type",
            type: "select",
            func: rId => { return this.getFieldFromIdList(rId, this.metadata.release_types); },
            options: () => { return this.metadata.release_types; },
            required: true
          }
        ],
        ...this.getFields(type),
      ]
    },
    getPossibleGenres (genre) {
      let genres = this.parseListForMatchingNameIgnoreCase(this.genres, genre);
      if (!_.isEmpty(genres)) return [genres.id];
      else if (_.has(VGPC_GENRE_MAPPINGS, genre)) {
        let genreStrings = VGPC_GENRE_MAPPINGS[genre];
        genres = this.parseListForMatchingNamesIgnoreCase(this.genres, genreStrings);
        return _.values(_.map(genres, 'id'));
      }
    },
    addToGameyeClicked () {
      this.loadingAddPreview = true;
      this.showAddModal = true;
      this.$store.dispatch('vgpc/getVgpcPreview', this.vgpcSelected.vgpc_id).then(preview => {
        this.addPreview = {
          ...preview,
          ...{
            image_url: this.vgpcSelected.image_url,
            genres: this.getPossibleGenres(this.vgpcSelected.genre)
          }
        };
        this.loadingAddPreview = false;
      });
    },
    platformSearchString ({name, short, abbr}) {
      return `${name} - ${short} - ${abbr}`;
    },
    updatePlatformFilters (newVal, src) {
      let filterObj = {}
      if (newVal !== null && newVal !== undefined) filterObj = {Platform: [_.extend(newVal, _.omit(this.searchGroupMeta.Platform, 'items'))]};
      this.$refs[`${src}Browse`].filtersChanged(filterObj);
    },
    updateFilters (src) {
      this.$nextTick(() => {
        this.$refs[`${src}Browse`].updatedFilters();
      })
    },
    addNewVgpc () {
      if (!this.addPreview || _.isEmpty(this.addPreview)) return;
      let mergeCustom = (objVal, srcVal) => {
        if (_.isArray(objVal)) {
          return srcVal;
        }
      }
      let mergedObj = _.mergeWith(
        {},
        this.addPreview,
        this.$refs.newVgpcItem.overriddenObj,
        mergeCustom
      );
      let addObj = _.merge(
        {},
        mergedObj,
        {
          alt_titles: [],
          country_id: mergedObj.country_id || null,
          supports_co_op: null,
          vgpc_id: this.vgpcSelected.vgpc_id,
          vgpc_url: this.vgpcSelected.vgpc_url,
          metacritic_link: null,
          game_specific: mergedObj.game_specific ? {
            rating_id: mergedObj.game_specific.rating_id === -1 ? null : mergedObj.game_specific.rating_id
          } : undefined
        }
      );
      this.savingNewItem = true;
      this.$store.dispatch("updates/addNewItem", addObj).then(() => {
        this.vgpcDataChanged();
        this.geDataChanged();
        this.showAddModal = false;
      }).catch(e => {
        this.newItemError = "Unable to save new item. Check logs for details";
        console.error(e);
      }).finally(() => {
        this.savingNewItem = false;
      });
    },
    linkVgpcToGameye () {
      if (!this.vgpcSelected || !this.geSelected) return;
      this.linkingItems = true;
      this.$store.dispatch("updates/editItem", { item_id: this.geSelected.id, item_edit: { vgpc_url: this.vgpcSelected.vgpc_url } })
      .then(() => {
        this.vgpcDataChanged();
        this.geDataChanged();
      }).catch(e => {
        this.linkItemError = e.response;
      }).finally(() => {
        this.linkingItems = false;
      })
    },
    vgpcDataChanged () {
      Vue.nextTick(() => {
        this.$refs.vgpcBrowse.getItems();
      })
      this.vgpcSelected = undefined;
    },
    geDataChanged () {
      this.$refs.geBrowse.getItems();
      this.geSelected = undefined;
    },
    autoFillGameyeSearch () {
      this.$refs.geBrowse.search = this.vgpcSelected.title;
      this.gePlatform = this.parseListForMatchingId(this.platformsList, this.vgpcSelected.platform_id);
      this.geSelected = undefined;
      // forces reload, including search update
      this.updatePlatformFilters(this.gePlatform, "ge");
    },
    skipSelectedVgpc () {
      this.vgpcIdsSkipped.push(this.vgpcSelected.vgpc_id);
      this.deselectVgpc();
      this.vgpcDataChanged();
    },
    clearSkippedVgpcs () {
      this.vgpcIdsSkipped = [];
      this.vgpcDataChanged();
    },
    deselectVgpc () {
      this.vgcpItemSelected(undefined, false);
      this.$refs.vgpcBrowse.deselectAllItems();
    },
    deselectGe () {
      this.geItemSelected(undefined, false);
      this.$refs.geBrowse.deselectAllItems();
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  width: 192px;
  margin: 16px 32px 16px 0;
}

.selected-col {
  flex-grow: 1;
  max-width: calc(50% - 75px);
}

.vgpc-selected,
.right-selected {
  height: 100%;
  background-color: var(--list-item-bg);
  border-radius: 4px;
  position: relative;
  .field:nth-child(odd) {
    background-color: var(--list-item-footer-bg);
  }
  .close {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
  }
}

.selected-col,
.selected-labels {
  margin: 16px 0;
}

.vgpc-selected,
.right-selected,
.selected-labels {
  padding: 32px 16px;
  height: 304px;
  .title {
    font-size: 2em;
    height: 1.325em;
  }
  .platform {
    height: 40px;
  }
  .comparison-item {
    height: 100%;
  }
}

.right-selected.vgpc > div:not(.overlay) {
  opacity: 0.5;
}

.field {
  padding: 5px 8px;
}

.vgpc-search, .ge-search {
  width: 46%;
  overflow: auto;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  align-items: center;
}

#addBtn {
  padding: 24px 16px;
}

.compare-cols {
  > div {
    display: flex;
    justify-content: center;
  }

  #oldVgpc {
    transform: scale(75%);
    opacity: 0.6;
  }

  #newGameye {
    width: 544px;
  }

  #oldVgpc, #newGameye {
    border-radius: 4px;
    padding: 8px;
    background-color: var(--background-color);
    > .item-header {
      height: 48px;
      margin-bottom: 8px;
      text-align: center;
      img {
        height: 48px;
      }
    }
    > .comparison-item {
      background-color: #404248;
      padding: 16px;
    }
  }
}

.browse-container ::v-deep {
  .g-container {
    padding: 0;
  }
  .head{
    position: sticky;
    top: 0;
    padding-top: 10px;
    padding-bottom: 15px;
    margin-bottom: 10px;
    z-index: 50;
    background-color: var(--background-color);
  }
}

.multiselect ::v-deep {
  min-height: unset;
  height: 30px;
  .multiselect__select {
    height: 30px;
    width: 30px;
    &::before {
      top: 70%;
    }
  }
  .multiselect__tags {
    padding-top: 4px;
    padding-right: 30px;
    min-height: unset;
    height: 30px;
    .multiselect__placeholder {
      margin-bottom: 6px;
    }
    .multiselect__input {
      margin-bottom: 5px;
    }
  }
  .multiselect__content-wrapper {
    .multiselect__option {
      white-space: normal;
    }
  }
}

#vgpcLinkIndicator {
  position: absolute;
  transform: rotate(270deg) translateX(-100%);
  top: 12px;
  left: 8px;
  transform-origin: left;
  padding: 4px;
  margin-left: calc(4px - 2px);
  background-color: #3f4146;
  font-size: 0.9em;
  &.has-link {
    background-color: var(--success);
  }
  &:not(.has-link) {
    color: transparentize(white, 0.5);
  }
}

::v-deep .alert-warning {
  color: rgb(251, 215, 112);
  background-color: rgb(61, 46, 0);
  border-color: rgb(123, 92, 0);
}

.ge-search .multiselect {
  width: fit-content;
}
</style>
