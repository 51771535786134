<template>
  <div class="input-with-icon input-group">
    <b-form-input id="new-password" v-model="password" :type="showPass ? 'text' : 'password'" placeholder="Password" @focus="newPassFocused = true"></b-form-input>
    <b-popover target="new-password" :show="shouldShowPasswordHint" triggers="" ref="newPassHint">
      <div><b-icon-check v-if="passwordLongEnough"></b-icon-check><b-icon-x v-else></b-icon-x> At least 8 characters long</div>
      <div><b-icon-check v-if="passwordHasLowerCase"></b-icon-check><b-icon-x v-else></b-icon-x> Lowercase letter</div>
      <div><b-icon-check v-if="passwordHasUpperCase"></b-icon-check><b-icon-x v-else></b-icon-x> Uppercase letter</div>
      <div><b-icon-check v-if="passwordHasDigit"></b-icon-check><b-icon-x v-else></b-icon-x> A number</div>
    </b-popover>
    <span>
      <b-icon-lock></b-icon-lock>
    </span>
    <div class="pw-toggle">
      <b-button variant="link" @click="toggleShowPassword" tabindex="-1">
        <span v-if="showPass"><b-icon-eye-slash height="0.9em"></b-icon-eye-slash></span>
        <span v-else><b-icon-eye height="0.9em"></b-icon-eye></span>
      </b-button>
    </div>
  </div>
</template>

<script>
import { BFormInput, BButton, BIconLock, BIconEye, BIconEyeSlash, BPopover, BIconX, BIconCheck } from 'bootstrap-vue';

export default {
  name: "PasswordField",
  components: {
    BFormInput,
    BButton,
    BIconLock,
    BIconEye,
    BIconEyeSlash,
    BPopover,
    BIconX,
    BIconCheck
  },
  props: {
    value: {
      type: String
    }
  },
  data () {
    return {
      password: undefined,
      showPass: false,
      newPassFocused: false
    }
  },
  computed: {
    shouldShowPasswordHint () {
      return !this.passwordState && this.newPassFocused;
    },
    passwordState () {
      return this.passwordLongEnough && this.passwordHasLowerCase && this.passwordHasUpperCase && this.passwordHasDigit;
    },
    passwordPopulated () {
      return this.password !== null && this.password !== undefined && this.password !== ''
    },
    passwordLongEnough () {
      return this.passwordPopulated && this.password.length >= 8;
    },
    passwordHasLowerCase () {
      return this.passwordPopulated && this.password.toUpperCase() !== this.password;
    },
    passwordHasUpperCase () {
      return this.passwordPopulated && this.password.toLowerCase() !== this.password;
    },
    passwordHasDigit () {
      return this.passwordPopulated && /\d/.test(this.password);
    }
  },
  watch: {
    password (val) {
      this.$emit('input', val);
    }
  },
  methods: {
    toggleShowPassword () {
      this.showPass = !this.showPass;
    },
  }
}
</script>

<style scoped>
.bi-x {
  color: red;
}

.bi-check {
  color: #28a745;
}

.pw-toggle {
  text-align: right;
  font-size: 0.8rem;
  color: #707070;
  position: absolute;
  right: 5px;
  display: flex;
  height: 30px;
  z-index: 10;
}

.pw-toggle > button {
  color: #707070;
  text-decoration: none;
}

.pw-toggle > button:focus {
  box-shadow: none;
}

.pw-toggle > button:hover {
  color: #333;
}

button.btn.btn-link {
  margin: 0;
  padding: 0;
  width: auto;
  font-size: 0.8rem;
}
</style>
