import _ from 'underscore';
import {CATEGORIES} from '@/store/constants.js';
import { COLLECTION_TYPES, GE_TAG_COLORS, GE_TAG_TEXT_COLORS } from '../store/constants';
import { BACKLOG_PLAY_STATUS, BACKLOG_TEXT, BACKLOG_COLORS, BACKLOG_TEXT_COLORS } from '../store/backlogPlayStatuses';

export default {
  computed: {
    searchGroupMeta () {
      return {
        Platform: { groupId: 1, color: '#17a2b8', variant: 'info', queryKey: 'platforms', items: this.platformsList },
        Countries: { groupId: 6, color: '#343a40', variant: 'dark', queryKey: 'country', items: this.metadata.countries },
        Regions: { groupId: 5, color: '#343a40', variant: 'dark', queryKey: 'country', items: this.metadata.regions, queryValFunc: (vals) => { return _.reduce(vals, (list, obj) => { return _.union(list, obj.country_ids)}, []).join(); } },
        Genre: { groupId: 2, color: '#ffc107', variant: 'warning', queryKey: 'genre', items: this.genres },
        ReleaseType: { groupId: 7, color: '#28a745', variant: 'success', queryKey: 'release_type', items: this.metadata.release_types },
        Developer: { groupId: 3, color: '#6c757d', variant: 'secondary', queryKey: 'developer', items: this.developers },
        Publisher: { groupId: 4, color: '#6c757d', variant: 'secondary', queryKey: 'publisher', items: this.publishers }
      }
    },
    platformsList () {
      return this.$store.state.meta.platformsData ? this.$store.state.meta.platformsData.platforms : [];
    },
    platformGroupsList () {
      return this.$store.state.meta.platformsData ? this.$store.state.meta.platformsData.group_types : [];
    },
    platformGroupsMetadataList () {
      return this.$store.state.meta.platformsData ? this.$store.state.meta.platformsData.meta_types : [];
    },
    categories () {
      return CATEGORIES;
    },
    collectionTypes () {
      return COLLECTION_TYPES;
    },
    assignableCategories () {
      return _.sortBy(_.flatten(_.map(
        _.reject(this.categories, c => { return c.value === undefined }),
        c => { return c.children || c }
      )), 'value');
    },
    metadata () {
      return this.$store.state.meta.metadata;
    },
    developers () {
      return this.$store.state.meta.developers;
    },
    publishers () {
      return this.$store.state.meta.publishers;
    },
    genres () {
      return this.$store.state.meta.genres;
    },
    genresList () {
      return _.values(this.genres);
    },
    detailsLoaded () {
      return this.$store.state.meta.allMetaLoaded;
    }
  },
  methods: {
    parseObjectKeysForMatchingIds(obj, ids) {
      let picked = _.pick(obj, ids);
      return _.isEmpty(picked) ? undefined : picked;
    },
    parseListForMatchingId(list, id) {
      return _.findWhere(list, {id: id})
    },
    parseListForMatchingVal(list, matchObj) {
      return _.findWhere(list, matchObj);
    },
    parseListForMatchingIds(list, ids) {
      return _.filter(list, i => { return _.contains(ids, i.id); });
    },
    parseListForMultipleMatchingVal(list, matchObj) {
      return _.where(list, matchObj);
    },
    parseListForMatchingName(list, name) {
      return _.findWhere(list, {name: name});
    },
    parseListForMatchingField(list, field, val) {
      return _.findWhere(list, {[field]: val});
    },
    parseListForMatchingNameIgnoreCase(list, name) {
      return _.find(list, i => { return name.toLowerCase() === i.name.toLowerCase() });
    },
    parseListForMatchingNamesIgnoreCase(list, names) {
      return _.map(names, name => { return this.parseListForMatchingNameIgnoreCase(list, name) });
    },
    parseListForNamesString(list) {
      return list ? _.values(_.pluck(list, "name")).join(", ") : null;
    },
    parseIntsFromEditList(list) {
      // Remove "[" and "]" at beginning of string
      list = list.substring(1, list.length-1);
      var splitValues = list.split(",")
      
      var ints = []
      for (let i = 0; i < splitValues.length; i++) {
          var splitValue = splitValues[i]
          if (splitValue != "") {
              ints.push(parseInt(splitValue))
          }
      }
      return ints
    },
    platformsInGroup(groupName) {
      // @TODO: Why the fuck do I have to stringify and then parse it for this to work??
      let group = _.findWhere(JSON.parse(JSON.stringify(this.platformGroupsList)), {name: groupName})
      return _.filter(this.platformsList, platform => { return _.contains(platform.groups, group.id); });
    },
    tagColorForID(id) {
      return GE_TAG_COLORS[id]
    },
    tagTextColorForID(id) {
      return GE_TAG_TEXT_COLORS[id]
    },
    backlogColorForID(id) {
      return BACKLOG_COLORS[id]
    },
    backlogTextColorForID(id) {
      return BACKLOG_TEXT_COLORS[id]
    },
    backlogTextForID(id) {
      return BACKLOG_TEXT[id]
    },
    backlogDurationApplicableForID(id) {
      switch (id) {
        case BACKLOG_PLAY_STATUS.FINISHED:
        case BACKLOG_PLAY_STATUS.COMPLETED_100_PERCENT:
        case BACKLOG_PLAY_STATUS.PAUSED:
        case BACKLOG_PLAY_STATUS.DROPPED:
        case BACKLOG_PLAY_STATUS.PLAYING:
          return true;
      }
      return false;
    },
    backlogEndApplicableForID(id) {
      switch (id) {
        case BACKLOG_PLAY_STATUS.FINISHED:
        case BACKLOG_PLAY_STATUS.COMPLETED_100_PERCENT:
        case BACKLOG_PLAY_STATUS.PAUSED:
        case BACKLOG_PLAY_STATUS.DROPPED:
          return true;
      }
      return false;
    },
    backlogBeginDateLabelForID(id) {
      if (id === BACKLOG_PLAY_STATUS.WANT_TO_PLAY)
        return "Planned Start:";
      return "Started:";
    },
    backlogEndDateLabelForID(id) {
      switch (id) {
        case BACKLOG_PLAY_STATUS.FINISHED:
        case BACKLOG_PLAY_STATUS.COMPLETED_100_PERCENT:
          return "Finished:";
        case BACKLOG_PLAY_STATUS.PAUSED:
          return "Paused:";
        case BACKLOG_PLAY_STATUS.DROPPED:
          return "Dropped:"
      }
      return "Backlog End Date Label (error) for ID:" + id
    },
    assignableCategoryIDToCategory (categoryID) {
      // SUPER DIRTY, I guess we need to check if the category, or one of it's children contain the "value" == categoryID
      // Not sure how to do this idiomatically
      // @TODO:
      if (categoryID == 2 || categoryID == 5) // controllers and accessories folded into "peripherals"
        return this.parseListForMatchingVal(this.categories, {name: 'peripherals'})
      return this.parseListForMatchingVal(this.assignableCategories, {value: categoryID})
    }
  }
}