import _ from 'underscore';

export default {
  methods: {
    formatCentsToUSD(val, compact, rounded) {
      if (val <= 0) return '-';
      let fVal = +(val / 100) * this.$store.state.meta.metadata.exchange_rates[this.$store.state.currency];
      let stringConfig = { style: "currency", currency: this.$store.state.currency}
      if (compact) _.extend(stringConfig, {notation: 'compact'});
      if (rounded) _.extend(stringConfig, {minimumFractionDigits: 0, maximumFractionDigits: (compact && fVal >= 1000) ? 1 : 0})
      return val > 0 ? (fVal).toLocaleString("en-US", stringConfig) : '-';
    }
  }
}