
export const BACKLOG_PLAY_STATUS = {
    WANT_TO_PLAY: 0,
    PLAYING: 1,
    FINISHED: 2,
    COMPLETED_100_PERCENT: 3,
    PAUSED: 4,
    DROPPED: 5,
    NOT_PLAYED: 6
}

export const BACKLOG_TEXT = [
    "Want To Play",
    "Playing",
    "Finished",
    "Completed 100%",
    "Paused",
    "Dropped",
    "Not Played"
]

export const BACKLOG_COLORS = [
    "#BF5AF2",
    "#0A84FF",
    "#34C759",
    "#FFCC00",
    "#FF9500",
    "#818181",
    "#393939"
]

export const BACKLOG_TEXT_COLORS = [
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF",
  "#393939",
  "#FFFFFF",
  "#FFFFFF",
  "#FFFFFF"
]