<template>
  <div style="position: relative; height: 100%; width: 100%" :style="[cssVars]" class="blur-hash-img">
    <transition-group mode="in-out" name="fade" style="height: 100%; width: 100%; display: flex; align-items: center; justify-content: center" tag="div">
      <blur-hash-canvas :hash="hash" :punch="punch" key="canvas" :width="renderWidth" :height="renderHeight" v-if="showingBlurHash"/>
      <b-img :fluid="fluid" :src="src" v-bind="$attrs" @load="onLoaded" v-show="imageLoaded" key="image" :width="width - 5" :height="height - 5" :style="imgStyleObj"></b-img>
    </transition-group>
  </div>
</template>

<script>
import BlurHashCanvas from '@/components/BlurHashCanvas';
import { BImg } from 'bootstrap-vue';

export default {
  name: 'BlurHashImage',
  components: {
    BlurHashCanvas,
    BImg
  },
  props: {
    hash: {
      type: String,
      required: true
    },
    punch: {
      type: Number,
      default: 1
    },
    src: {
      type: String,
      required: true
    },
    transitionDuration: {
      type: Number,
      default: 100
    },
    height: {
      type: [Number, String],
      default: undefined
    },
    width: {
      type: [Number, String],
      default: undefined
    },
    fluid: {
      type: Boolean,
      default: false
    },
    renderWidth: {
      type: [Number, String],
      default: undefined
    },
    renderHeight: {
      type: [Number, String],
      default: undefined
    },
    hideBlurHashWhenLoaded: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      imageLoaded: false
    }
  },
  watch: {
    hash (newVal, oldVal) {
      if (newVal !== oldVal) this.imageLoaded = false;
    }
  },
  computed: {
    showingBlurHash () {
      return !(this.imageLoaded && this.hideBlurHashWhenLoaded);
    },
    imgStyleObj () {
      return {
        'min-height': !this.showingBlurHash ? `${this.height - 5}px` : undefined,
        'min-width': !this.showingBlurHash ? `${this.width - 5}px` : undefined,
      }
    }
  },
  methods: {
    onLoaded() {
      this.imageLoaded = true
    },
    cssVars() {
      return {
        '--transition-duration': this.transitionDuration > 0 ? `${this.transitionDuration / 1000}s` : 'none'
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity var(--transition-duration);
}
.fade-enter, .fade-leave-to{
  opacity: 0;
}

.blur-hash-img canvas {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 85%;
}

.blur-hash-img img {
  max-height: calc(100% - 10px);
  max-width: calc(100% - 10px);
  z-index: 10;
  position: relative;
  width: auto;
}
</style>