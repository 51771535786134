<template>
  <b-popover :target="target" :placement="placement" variant="dark" :custom-class="'link-edit ' + type" ref="popover" @show="initData(fieldValue)">
    <template #title></template>
    <div class="flex-col">
      <slot name="pre"></slot>
      <div class="flex link-edit">
        <multiselect v-if="type === 'select' || type === 'multiselect'" :multiple="type === 'multiselect'" v-model="lFieldValue" :options="selectConfig.options" :label="selectConfig.label || 'name'" :track-by="selectConfig.value || 'id'" select-label="" deselect-label="" selected-label="" :allow-empty="!required">
          <template v-for="name in overriddenSelectSlots" v-slot:[name]="{option}">
            <slot :name="name" v-bind="option">
              <div class="flex" :key="name">
                <b-img v-if="getOptionIcon(option)" :src="getOptionIcon(option)" :title="option[selectConfig.label]" height="20" style="margin-right: 0.5em"/>
                <span>{{option[selectConfig.label]}}</span>
              </div>
            </slot>
          </template>
        </multiselect>
        <b-form-datepicker v-else-if="type === 'date'" v-model="lFieldValue" dark :required="required" no-flip show-decade-nav />
        <div v-else-if="type === 'multi-text'" style="flex-grow: 1" class="multi-text">
          <div v-for="(v, i) in lFieldValue" :key="i" class="input flex center">
            <b-form-input v-model="lFieldValue[i]" type="text" required></b-form-input>
            <b-icon-x-lg class="error pointer" title="remove" style="margin-left: 4px" @click="removeMultiTextAt(i)" />
          </div>
          <div v-if="!lFieldValue || lFieldValue.length === 0" class="italic grey">None</div>
          <b-button variant="link" size="sm" @click="addMultiTextVal" :disabled="!!lFieldValue && lFieldValue.length >= 5">+ add</b-button>
        </div>
        <div v-else-if="type === 'boolean'" style="flex-grow: 1">
          <b-form-radio-group v-model="lFieldValue" buttons>
            <b-form-radio button :value="true" :button-variant="lFieldValue ? 'primary' : 'outline-primary'">Yes</b-form-radio>
            <b-form-radio button :value="false" :button-variant="lFieldValue ? 'outline-secondary' : 'secondary'">No</b-form-radio>
          </b-form-radio-group>
        </div>
        <div v-else-if="type === 'text-area'" style="flex-grow: 1">
          <textarea v-model="lFieldValue" style="width: 100%" rows="12" />
        </div>
        <b-form-input v-else v-model="lFieldValue" :number="type === 'number'" style="flex-grow: 1" :type="type" min="1" :required="required" v-on:keyup.enter="clickSave()"></b-form-input>
        <div @click.prevent="clearValue" v-if="clearBtn && fieldValue && lFieldValue === fieldValue" style="margin-left: 5px">
          <slot name="clear-btn">
            <b-button variant="outline-danger">Clear</b-button>
          </slot>
        </div>
        <div><loading-button variant="outline-success" style="margin-left: 5px; width: 80px;" :async-func="async ? saveField : undefined" @click="clicked" @saved.prevent="saved" :disabled="saveDisabled" ref="save">Save</loading-button></div>
        <div><b-button variant="outline-secondary" style="margin-left: 5px; width: 80px;" @click="cancel">Cancel</b-button></div>
      </div>
      <div class="error" v-if="saveError">{{saveError}}</div>
      <div class="success" v-if="successfulSave">Saved!</div>
    </div>
  </b-popover>
</template>

<script>
import { BPopover, BFormInput, BFormDatepicker, BFormRadioGroup, BFormRadio, BButton, BIconXLg, BImg } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import LoadingButton from "@/components/LoadingButton";
import _ from "underscore";

export default {
  name: "EditFieldPopover",
  components: {
    BPopover,
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BButton,
    BIconXLg,
    BImg,
    Multiselect,
    LoadingButton
  },
  props: {
    target: {
      type: String,
      required: true
    },
    fieldValue: {
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    placement: {
      type: String,
      default: "top"
    },
    saveFunc: {
      type: Function
    },
    clearBtn: {
      type: Boolean,
      default: false
    },
    selectConfig: {
      // Expects: options, key (optional), value (optional)
      type: Object
    },
    required: {
      type: Boolean,
      default: false
    },
    async: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    saveDisabled () {
      return this.fieldValue === this.lFieldValue
        || (this.required && (this.lFieldValue === undefined || this.lFieldValue === null || this.lFieldValue === ''))
        || _.isArray(this.lFieldValue) && (_.isEmpty(this.lFieldValue) && !this.fieldValue || !_.every(this.lFieldValue, val => { return !_.isEmpty(val); }))
        || _.isArray(this.lFieldValue) && _.isEqual(this.fieldValue, this.lFieldValue);
    },
    overriddenSelectSlots () {
      return _.filter(['option', 'singleLabel'], s => { return this.selectConfig.getIconImg || _.has(this.$scopedSlots, s); });
    }
  },
  data () {
    return {
      lFieldValue: '',
      saveError: undefined,
      successfulSave: false
    }
  },
  watch: {
    fieldValue: {
      handler (newVal) {
        this.initData(newVal);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    initData (val) {
      let newVal = JSON.parse(JSON.stringify(val || this.fieldValue));
      if (this.type === "date") {
        let d = new Date(newVal);
        let month = `${d.getUTCMonth()+1}`.padStart(2, "0")
        let day = `${d.getUTCDate()}`.padStart(2, "0")
        this.lFieldValue = `${d.getUTCFullYear()}-${month}-${day}`;
      } else
        this.lFieldValue = newVal;
    },
    getConvertedValue () {
      let val;
      if (this.type === 'date') val = new Date(this.lFieldValue).getTime() / 1000;
      else if (this.type === 'select') val = _.get(this.lFieldValue, this.selectConfig.value || 'id');
      else if (this.type === 'multiselect') val = _.map(this.lFieldValue, v => { return _.get(v, this.selectConfig.value || 'id'); })
      else val = this.lFieldValue;
      if(val === undefined) val = null; // specific to make clearing work.. not sure if this will break anything
      return val;
    },
    clickSave () {
      this.$refs.save.$refs.btn.click();
    },
    saveField () {
      this.saveError = undefined;
      this.successfulSave = false;
      if (this.async)
        return this.saveFunc(this.getConvertedValue(), this.selectConfig)
          .catch(e => {
            this.saveError = e.response.data;
            throw e;
          });
    },
    clicked () {
      if (!this.async) this.saved();
    },
    close () {
      this.successfulSave = false;
      this.$refs.popover.$emit('close');
    },
    saved () {
      this.successfulSave = true;
      let val = this.getConvertedValue();
      if (this.async)
        setTimeout(() => {
          this.close();
        }, 1500);
      else {
        this.close();
      }
      this.$emit('save', val);
    },
    cancel () {
      this.lFieldValue = this.fieldValue;
      this.saveError = undefined;
      this.close();
    },
    updateFieldValue (val) {
      this.lFieldValue = val;
    },
    clearValue () {
      this.lFieldValue = null;
    },
    addMultiTextVal () {
      if (!this.lFieldValue) this.lFieldValue = [];
      this.lFieldValue.push(''); 
    },
    removeMultiTextAt (index) {
      this.lFieldValue.splice(index, 1);
    },
    getOptionIcon (opt) {
      return opt.icon || this.selectConfig.getIconImg(opt);
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
.link-edit > input,
.link-edit > button {
  font-size: 0.8em;
}

.error {
  color: var(--error-text);
}

.success {
  color: var(--success-text);
}

.multi-input > div.input:not(:last-child) {
  margin-bottom: 3px;
}
</style>
