<template>
    <div class="backlog_record">

        <label>Backlog Record</label>

        <!-- Row 1 -- tag representing play status -->
        <div class="play_status_row">
            <tag-pill :text="backlogTextForID(record.play_status_id)" tag="span" :text-color="backlogTextColorForID(record.play_status_id)" :background-color="backlogColorForID(record.play_status_id)"></tag-pill>
        </div>
        <!-- Row 2 -- start/end dates 
             If no dates available hide
         -->
        <div class="dates_row">
          <div class="start_date" v-if="startDateApplicable">
            <label>{{ backlogBeginDateLabelForID(record.play_status_id) + " " + formatTimestamp(record.started_at) }}</label>
          </div>
          <div class="end_date" v-if="endDateApplicable">
            <label>{{ backlogEndDateLabelForID(record.play_status_id) + " " + formatTimestamp(record.ended_at) }}</label>
          </div>
        </div>

        <!-- Row 3 -- duration if exists -- input is in minutes -->
        <div class="duration" v-if="durationApplicable">
          <div class="label">Duration:</div>
          <div class="value">
            <editable-field :editable="false" field-name="duration" :field-value="record.duration" type="number">
              {{duration}}
            </editable-field>
          </div>
        </div>

        <!-- Row 4 -- notes if exists -->
        <div class="notes" v-if="(record.notes) && record.notes != ''">
          <editable-field :editable="false" field-name="notes" :field-value="record.notes" type="text-area">
            <p ref="Notes">{{this.record.notes}}</p>
          </editable-field>
        </div> 

    </div>
</template>

<script>
import TagPill from '@/components/TagPill';
import EditableField from "@/components/EditableField";
import moment from 'moment';
export default {
  name: "BacklogRecord",
  components: {
    TagPill,
    EditableField
  },
  props: {
      record: {
        type: Object,
        required: true
      }
  },
  computed: {
    durationApplicable () {
      return (this.record.duration) && this.backlogDurationApplicableForID(this.record.play_status_id);  
    },
    duration () {
      return this.record.duration ? (Number.parseInt(this.record.duration) / 3600) + " hours" : "???";
    },
    endDateApplicable () {
      return (this.record.ended_at) && this.backlogEndApplicableForID(this.record.play_status_id);
    },
    startDateApplicable () {
      return (this.record.started_at);
    }
  },
  methods: {
    formatTimestamp (timestamp) {
      return moment(new Date(timestamp*1000)).utc().format('DD-MMM-YYYY')
    }
  }
}
</script>

