<template>
  <div :class="['user-badge', baseClass]" :style="{'border-color': _userColor}">
    <div :class="['user-info-cell flex', {interactive: this.interactive}]" :style="styleObj">
      <b-avatar
        id="userProfileImage"
        alt=""
        size="44px"
        rounded
        :text="avatarText"
        :style="avatarStyleObj"
        :src="_userProfileImage ? getImageURLByType(_userProfileImage.file, 1, 'PROFILE_PIC') : undefined"
        :badge="notificationCount > 0 ? notificationCount.toString() : undefined"
        badge-variant="warning" badge-top badge-offset="-4px">
      </b-avatar>
      <div style="flex-grow: 1; margin-left: 8px; min-width: 0" class="flex">
        <div class="names" style="flex-grow: 1">
          <div :class="{editable: editable}" id="displayName" :title="displayName" v-if="!editable || !editing">
            {{displayName}}
            <span v-if="editable">&nbsp;&nbsp;<a href @click.prevent="editing = true" style="font-size: 0.7rem">change</a></span>
          </div>
          <div id="displayNameInput" v-else>
            <b-form-input v-model="newValues.displayName"
              @input="displayNameChanged"
              size="sm" style="height: 1.5em" debounce="200"
              :state="(newValues.displayName.trim().length < $options.NAME_LENGTHS.MIN_DISPLAY_NAME || newValues.displayName.trim().length > $options.NAME_LENGTHS.MAX_DISPLAY_NAME) ? false : undefined"></b-form-input>
          </div>
          <div id="handle">@{{userName}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BAvatar, BFormInput} from "bootstrap-vue";
import {textColorFromBgColor, parseRGBFromHex} from "@/mixins/utils.js";
import {NAME_LENGTHS} from "@/store/constants.js";

export default {
  name: "UserBadge",
  components: {BAvatar, BFormInput},
  NAME_LENGTHS: NAME_LENGTHS,
  computed: {
    displayName () {
      if (this.displayNameOverride) return this.displayNameOverride;
      return this.loggedInUser.display_name ? this.loggedInUser.display_name : this.loggedInUser.username;
    },
    userName () {
      return this.loggedInUser.username ? this.loggedInUser.username.toLowerCase() : '';
    },
    _userColor () {
      if (this.colorOverride) return this.colorOverride;
      return this.userColor;
    },
    _userProfileImage () {
      // null is a valid value
      if (this.imageOverride !== undefined) return this.imageOverride;
      else if (this.userProfileImage === null) return null;
      return this.userProfileImage ? {file: this.userProfileImage} : undefined;
    },
    styleObj () {
      if (!this._userColor) return {};
      let userRGB = parseRGBFromHex(this._userColor);
      return {
        '--badge-bg-color': `rgba(${userRGB.r},${userRGB.g},${userRGB.b}, 0.05)`,
        '--badge-hover-bg-color': `rgba(${userRGB.r},${userRGB.g},${userRGB.b}, 0.7)`
      }
    },
    avatarText () {
      return this.userName ? this.userName[0].toUpperCase() : ' '
    },
    avatarStyleObj () {
      return {
        'background-color': this._userColor,
        'color': textColorFromBgColor(this._userColor, '#2f3641'),
        'border': `1px solid ${this._userColor}`
      };
    }
  },
  props: {
    displayNameOverride: {
      type: String
    },
    colorOverride: {
      type: String
    },
    imageOverride: {
      type: Object
    },
    interactive: {
      type: Boolean,
      default: false
    },
    notificationCount: {
      type: Number,
      default: 0
    },
    editable: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.newValues.displayName = this.displayName;
  },
  data () {
    return {
      textColorFromBgColor: textColorFromBgColor,
      editing: false, // only can edit display name for now
      newValues: {
        displayName: undefined
      },
      baseClass: '' // added for inheritence
    }
  },
  methods: {
    displayNameChanged (newVal) {
      let val = newVal.trim();
      this.$emit('displayNameUpdated', val.length >= NAME_LENGTHS.MIN_DISPLAY_NAME && val.length <= NAME_LENGTHS.MAX_DISPLAY_NAME ? val : null);
    }
  }
}
</script>

<style lang="scss" scoped>
.user-badge {
  background-color: var(--list-item-footer-bg);
  color: white;
  border-radius: 4px;
  border: 1px solid transparent;
  width: 100%;
  max-width: 240px;
  min-width: 192px;
}
.user-info-cell {
  text-align: left;
  background-color: var(--badge-bg-color);
  padding: 8px;
  ::v-deep .b-avatar-img {
    border-radius: 2px; // fixing a weird gap when adding border to avatar image
  }
  &.interactive {
    cursor: pointer;
    &:hover {
      background-color: var(--badge-hover-bg-color);
    }
  }
  .btn {
    font-size: 0.8rem;
    padding-top: 1.5px;
    padding-bottom: 1.5px;
    background-color: var(--gameye-blue-faded-30);
    &:hover {
      background-color: var(--gameye-blue);
      border-color: var(--gameye-blue-faded-30);
    }
    .icon {
      text-align: center;
      margin-right: 8px;
    }
    svg {
      width: 0.8rem;
    }
  }
  #handle {
    font-size: 0.7rem;
    color: #9f9f9f;
  }
  #displayName {
    font-size: 1rem;
    line-height: 1.4rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    &.editable {
      background-color: #59595970;
      border-radius: 2px;
      padding: 0 4px;
    }
  }
  .names {
    min-width: 0;
  }
}
</style>
