<template>
  <div>
    <div class="tabs">
      <image-carousel v-bind="$attrs"
        :images="availableImages" :previousImages="availablePrevImages"
        :selectable="selectable" :edit-mode="editMode"
        :allow-image-overflow="allowImageOverflow"
        @addNew="addNewImage($event)" @edit="editImage($event)"
        @delete="confirmDelete" @replace="replaceImage($event)"
        @selectChanged="imageSelectChanged"></image-carousel>
    </div>
    <async-save-modal v-if="newImageFunc" title="Add New Image" v-model="showAddModal" :async-func="uploadImage" :save-disabled="!imageToUpload">
      <div>
        <image-selector v-model="imageToUpload"></image-selector>
      </div>
    </async-save-modal>
    <async-save-modal v-if="editImageFunc" title="Edit Image" v-model="showEditModal" :async-func="saveEditImage" ok-title="Save" :save-disabled="!editingType || (editingType === prevEditingType && editingAttribution === prevEditingAttribution)">
      <g-image fluid hide-blur-hash
        :image-object="imageToEdit"
        :src="imageToEdit ? imageToEdit.url : undefined"
        :draw-width="200"
        :draw-height="200"
        :punch="0.5"
        :image-size-idx="2"
        style="margin-bottom: 16px">
      </g-image>
      <div class="flex">
        <div style="margin-right: 1rem">Image Type:</div>
        <multiselect :multiple="false" v-model="editingType" :options="imageTypes" select-label="" deselect-label="" selected-label="" :allow-empty="false">
          <template v-slot:option="option">
            <div class="flex">
              <span>{{option.option}}</span>
              <div style="margin-left: 0.5em; color: var(--warning)" v-if="getTypeObj(option.option).max === 1 && typeHasImages(option.option)">
                <b-icon icon="exclamation-triangle-fill" />
                <span> Existing single value</span>
              </div>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="flex" style="padding-top: 16px;">
        <div style="margin-right: 1rem; margin-top: 16px; margin-bottom: 16px;">Image Attribution:</div>
        <input type="text" v-model="editingAttribution" placeholder="image attribution" />
      </div>
      <div style="margin-top: 8px" class="flex v-center" v-if="editingType && (editingType !== prevEditingType && editingAttribution !== prevEditingAttribution) && getTypeObj(editingType).max === 1 && typeHasImages(editingType)">
        <div style="color: var(--warning)" class="flex">
          <div><b-icon icon="exclamation-triangle-fill" /></div>
          <div style="margin-left: 8px">There's an existing image for this type which will be overwritten with this change</div>
        </div>
      </div>
    </async-save-modal>
    <async-save-modal v-if="replaceImageFunc" title="Replace Image" v-model="showReplaceModal" :async-func="saveReplaceImage" ok-title="Save" size="lg" :save-disabled="!imageToUpload">
      <div class="flex v-center">
        <g-image fluid hide-blur-hash
          :image-object="imageToEdit"
          :src="imageToEdit ? imageToEdit.url : undefined"
          :draw-width="200"
          :draw-height="200"
          :punch="0.5"
          :image-size-idx="2"
          style="min-width: 200px">
        </g-image>
        <div style="padding: 32px">
          <b-icon icon="arrow-right" />
        </div>
        <div style="min-width: 250px;">
          <image-selector v-model="imageToUpload"></image-selector>
        </div>
      </div>
    </async-save-modal>
    <async-save-modal v-if="deleteImageFunc" title="Delete Image" v-model="showConfirmDeleteModal" :async-func="deleteImage" ok-title="Yes, Delete Image" ok-variant="danger">
      <g-image fluid hide-blur-hash
        :image-object="imageToDelete"
        :draw-width="200"
        :draw-height="200"
        :punch="0.5"
        :image-size-idx="2"
        style="margin-bottom: 16px">
      </g-image>
      Are you sure you want to permanently delete this image?
    </async-save-modal>
  </div>
</template>

<script>
import ImageCarousel from "@/components/ImageCarousel";
import ImageHelperMixin from "@/mixins/ImageHelperMixin";
import ImageSelector from "@/components/ImageSelector";
import GImage from "@/components/GImage";
import Multiselect from "vue-multiselect";
import AsyncSaveModal from "@/components/AsyncSaveModal";
import _ from "underscore";
import l_ from "lodash";

export default {
  name: "ImageGallery",
  mixins: [ImageHelperMixin],
  components: {
    ImageCarousel,
    ImageSelector,
    GImage,
    Multiselect,
    AsyncSaveModal
  },
  props: {
    images: {
      type: Object,
      required: true
    },
    imagesOf: {
      // used for alt titles of images
      type: String,
      required: true
    },
    previousImages: {
      // used to compare images (in queue). expects same format as images
      type: Object
    },
    allowImageOverflow: {
      type: Boolean,
      default: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    newImageFunc: {
      type: Function
    },
    replaceImageFunc: {
      type: Function
    },
    editImageFunc: {
      type: Function
    },
    deleteImageFunc: {
      type: Function
    }
  },
  data () {
    return {
      showAddModal: false,
      addingType: undefined,
      imageToUpload: undefined,
      showEditModal: false,
      prevEditingType: undefined,
      editingType: undefined,
      editingAttribution: undefined,
      prevEditingAttribution: undefined,
      imageToEdit: undefined,
      showConfirmDeleteModal: false,
      imageToDelete: undefined,
      showReplaceModal: false
    }
  },
  computed: {
    availableImageTypes () {
      let populatedTypes = _.filter(this.imageTypes, val => {
        return this.typeHasImages(val);
      });
      return this.editMode ? _.uniq([...populatedTypes, ...this.imageTypes]) : populatedTypes;
    },
    availableImages () {
      return this.mapImagesToTextType(this.images);
    },
    availablePrevImages () {
      return this.mapImagesToTextType(this.previousImages)
    }
  },
  methods: {
    mapImagesToTextType (images) {
      if (images) {
        return _.object(this.availableImageTypes, _.map(this.availableImageTypes, type => {
          return this.getImagesByType(type, images);
        }))
      }
    },
    getImagesByType (type, images) {
      let imgTypeIdx = this.getTypeObj(type).type;
      return _.map(images[imgTypeIdx], imgObj => {
        return _.extend(imgObj, { altTitle: type + " image of " + this.imagesOf });
      })
    },
    typeHasImages (type) {
      let typeId = this.getTypeObj(type).type;
      return !!this.images[typeId] && _.size(this.images[typeId]) > 0;
    },
    getTypeObj (type) {
      return this.metadata.image_types[type];
    },
    addNewImage (type) {
      this.imageToUpload = undefined;
      this.addingType = type;
      this.showAddModal = true;
    },
    uploadImage () {
      return this.newImageFunc({ image: this.imageToUpload, type: this.getTypeObj(this.addingType).type });
    },
    editImage ({image, type, attribution}) {
      this.imageToEdit = JSON.parse(JSON.stringify(image));
      this.editingType = JSON.parse(JSON.stringify(type));
      this.prevEditingType = type;
      this.editingAttribution = JSON.parse(JSON.stringify(attribution));
      this.prevEditingAttribution = attribution;
      this.showEditModal = true;
    },
    saveEditImage () {
      return this.editImageFunc({ image: this.imageToEdit, type: this.getTypeObj(this.editingType).type, attribution: this.editingAttribution });
    },
    confirmDelete ({image}) {
      this.imageToDelete = image;
      this.showConfirmDeleteModal = true;
    },
    deleteImage () {
      // delete image
      return this.deleteImageFunc(this.imageToDelete.ID);
    },
    replaceImage ({image, type}) {
      this.imageToUpload = undefined;
      this.imageToEdit = image;
      this.editingType = type;
      this.showReplaceModal = true;
    },
    saveReplaceImage () {
      return this.replaceImageFunc({ image_to_replace: this.imageToEdit, type: this.getTypeObj(this.editingType).type, image: this.imageToUpload });
    },
    imageSelectChanged (selected) {
      // value passed to carousel is mapped via cat name, not id. switching back..
      let conv = (val, k) => { return this.getTypeObj(k).type; }
      let imgsToAdd = l_.mapKeys(selected.add, conv);
      let imgsToRemove = l_.mapKeys(selected.remove, conv);
      this.$emit("selectedChanged", {add: imgsToAdd, remove: imgsToRemove});
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .tabs {
  .nav-tabs {
    border-bottom: none;
    row-gap: 8px;
    > li.nav-item > a {
      border-radius: 0;
      color: white;
      font-size: 1rem;
      border: none;
      background-color: #4F5058;
      border-top: 2px solid transparent;
      margin-right: 0.25em;
      &.active {
        background-color: #202133;
        border-top-color: white;
      }
      &.no-images {
        opacity: 0.5;
        font-style: italic;
      }
    }
  }
  .tab-content {
    padding: 32px;
  }
}
</style>
