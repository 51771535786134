<template>
  <canvas ref="canvas" :width="currentWidth" :height="currentHeight" class="blur" style="width: 100%; height: 100%;" />
</template>

<script>
import { decode } from 'blurhash'

export default {
  props: {
    hash: { type: String, required: true },
    width: { type: [Number, String], default: undefined },
    renderWidth: { type: [Number, String], default: 20 },
    height: { type: [Number, String], default: undefined },
    renderHeight: { type: [Number, String], default: 20},
    punch: { type: Number, default: 1 }
  },
  watch: {
    updateCanvas() {
      this.$nextTick( () => {
        this.draw()
      })
    }
  },
  methods: {
    watchers() {
      return [this.hash, this.width, this.height, this.punch]
    },
    draw() {
      const pixels = decode(this.hash, this.renderWidth, this.renderHeight, this.punch)
      const canvas = this.$refs.canvas
      const ctx = canvas.getContext('2d')
      const imageData = ctx.createImageData(this.renderWidth, this.renderHeight)
      imageData.data.set(pixels)
      
      ctx.putImageData(imageData, 0, 0);
      ctx.globalCompositeOperation = 'copy';
      // now we can draw ourself over ourself.
      if (this.width && this.height) {
        ctx.drawImage(canvas,
          0,0, imageData.width, imageData.height, // grab the ImageData part
          0,0, canvas.width, canvas.height // scale it
        );
      }
    },
    parseNumber (number) {
      return typeof number === 'number' ? number : parseInt(number, 10)
    }
  },
  computed: {
    currentWidth() {
      return this.parseNumber(this.width)
    },
    currentHeight() {
      return this.parseNumber(this.height)
    },
    currentPunch() {
      return this.parseNumber(this.punch)
    },
    currentRenderWidth() {
      return this.parseNumber(this.renderWidth ? this.renderWidth : this.currentWidth)
    },
    currentRenderHeight() {
      return this.parseNumber(this.renderHeight ? this.renderHeight : this.currentHeight)
    }
  },
  mounted() {
    this.draw()
  }
}
</script>