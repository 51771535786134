<template>
  <div>
    <div>
      <h5>Run Date Updates</h5>
      <b-form-checkbox-group
        v-model="selectedTriggerOpts"
        :options="dataTriggerOptions"
        class="mb-3"
        value-field="id"
        text-field="name"
        stacked
      ></b-form-checkbox-group>
      <b-button :disabled="!dataTriggerOptsSelected" variant="primary" @click="triggerUpdate">Trigger Update</b-button>
    </div>
    <div style="margin-top: 2em" class="metadata-management">
      <h5 style="margin-bottom: 1em">Manage Metadata</h5>
      <b-tabs content-class="tab-content">
        <b-tab title="Countries">
          <editable-table :items="metadata.countries_list" :fields="dataConfig.countries.fields" filterable
            sort-by="id" edit-url-base="/internal/countries" dark no-provider-paging no-provider-sorting no-provider-filtering
            :filter-included-fields="['name', 'abbr', 'iso_code']" @updated="$store.dispatch('meta/getMetadata')">
            <template #cell(file)="data">
              <flag :country="data.item"></flag>
            </template>
          </editable-table>
        </b-tab>
        <b-tab title="Genres">
          <editable-table :fields="dataConfig.genres.fields" edit-url-base="/internal/genres" :items="genresList" dark @updated="$store.dispatch('meta/getGenres')"></editable-table>
        </b-tab>
        <b-tab title="Companies">
          <editable-table :fields="dataConfig.companies.fields" edit-url-base="/internal/companies" :items="allCompanies" filterable dark :per-page="25"
            no-provider-paging no-provider-sorting no-provider-filtering sort-by="id" :filter-included-fields="['name']" :filter-function="fastNameFilter"
            @updated="$store.dispatch('meta/getCompanies', true)"></editable-table>
        </b-tab>
        <b-tab title="Platforms">
          <b-tabs>
            <b-tab title="By Platform">
              <editable-table :fields="dataConfig.platforms.fields" edit-url-base="/internal/platforms" :items="platformsList" sort-by="id" filterable dark :filter-included-fields="['name', 'short', 'abbr']">
                <template #cell(image)="data">
                  <platform v-if="data.item.id" :platform-id="data.item.id" icon-only />
                </template>
                <template #cell(groups)="data">
                  <div v-for="group in data.value" :key="`${data.item.id}-${group}`">
                    {{parseListForMatchingId(platformGroupsList, group).name}}
                  </div>
                </template>
              </editable-table>
            </b-tab>
            <b-tab title="By Platform Groups">
              <b-table :items="platformGroupsList" dark hover
                :fields="[{key: 'ec', label: ''}, 'id', 'name', {key: 'desc', label: 'Description'}, {key: 'meta_type_id', label: 'Group Type'}, {key: 'platforms_ct', label: 'Total Platforms'}]"
                @row-clicked="toggleRowDetails">
                <template #cell(ec)="row">
                  <b-icon :icon="`chevron-${row.detailsShowing ? 'down' : 'right'}`" />
                </template>
                <template #cell(meta_type_id)="data">
                  {{parseListForMatchingId(platformGroupsMetadataList, data.value).name}}
                </template>
                <template #cell(platforms_ct)="data">
                  {{findMatchingPlatformsByGroup(data.item.id).length}}
                </template>
                <template #row-details="row">
                  <div style="margin: 0.5em 1em; padding: 1em; background-color: var(--list-item-bg); border-radius: 0.25em">
                    <ol style="column-count: 3">
                      <li v-for="p in findMatchingPlatformsByGroup(row.item.id)" :key="row.item.id + '-' + p.id" style="margin-bottom: 0.2em">
                        <platform :platform-id="p.id" />
                      </li>
                    </ol>
                  </div>
                </template>
              </b-table>
            </b-tab>
            <b-tab title="By Platform Group Types">
              <b-table :items="platformGroupsMetadataList" dark hover
                :fields="[{key: 'ec', label: ''}, 'id', 'name', {key: 'desc', label: 'Description'}, {key: 'platform_groups_ct', label: 'Total Platform Groups'}]"
                @row-clicked="toggleRowDetails">
                <template #cell(ec)="row">
                  <b-icon :icon="`chevron-${row.detailsShowing ? 'down' : 'right'}`" />
                </template>
                <template #cell(platform_groups_ct)="data">
                  {{findMatchingPlatformGroupsByType(data.item.id).length}}
                </template>
                <template #row-details="row">
                  <div>
                    <b-table :items="findMatchingPlatformGroupsByType(row.item.id)" borderless dark hover :fields="['name']" thead-tr-class="hidden" @row-clicked="toggleRowDetails">
                      <template #cell(name)="row">
                        <b-icon :icon="`chevron-${row.detailsShowing ? 'down' : 'right'}`" />
                        <span style="margin-left: 1.5em">{{row.value}}</span>
                      </template>
                      <template #row-details="pg">
                        <div>
                          <ul style="column-count: 3">
                            <li v-for="p in findMatchingPlatformsByGroup(pg.item.id)" :key="pg.item.id + '-' + p.id" style="margin-bottom: 0.2em">
                              <platform :platform-id="p.id" />
                            </li>
                          </ul>
                        </div>
                      </template>
                    </b-table>
                  </div>
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-tab>
        <b-tab title="Profile Pics/Icons">
          <b-tabs>
            <b-tab title="Pictures/Icons">
              <editable-table :fields="dataConfig.profile_pics.fields" edit-url-base="/internal/profile_pics" :items="dataConfig.profile_pics.data" dark @updated="$store.dispatch('meta/getProfilePics')">
                <template #cell(file)="data">
                  <g-image :image-object="{File: data.value, Width: data.item.width, Height: data.item.height}" image-type="PROFILE_PIC" hide-blur-hash />
                </template>
                <template #cell(profile_pic_type_id)="data">
                  {{parseListForMatchingId(dataConfig.profile_pics.types, data.value).desc}}
                </template>
              </editable-table>
            </b-tab>
          </b-tabs>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import {BButton, BFormCheckboxGroup, BTabs, BTab, BTable, BIcon} from 'bootstrap-vue';
import EditableTable from "@/components/EditableTable";
import Flag from "@/components/FlagIcon";
import Platform from "@/components/Platform";
import GImage from "@/components/GImage";
import _ from 'underscore';
import Vue from 'vue';

export default {
  name: "UpdateData",
  components: {
    BButton,
    BFormCheckboxGroup,
    BTabs,
    BTab,
    BTable,
    BIcon,
    EditableTable,
    Flag,
    Platform,
    GImage
  },
  computed: {
    dataTriggerOptsSelected () {
      return this.selectedTriggerOpts && this.selectedTriggerOpts.length > 0;
    },
    allCompanies () {
      return this.$store.state.meta.allCompanies;
    }
  },
  NAME_ID_FIELDS: [
    {
      key: "id",
      idField: true
    },
    {
      key: "name"
    }
  ],
  data () {
    return {
      dataTriggerOptions: [],
      selectedTriggerOpts: undefined,
      dataConfig: {
        countries: {
          fields: [
            ...this.$options.NAME_ID_FIELDS,
            ...[
              {
                key: "abbr"
              },
              {
                key: "iso_code",
                label: "ISO Code"
              },
              {
                key: "file",
                label: "Image",
                type: "image",
                imageType: "FLAG"
              }
            ]
          ]
        },
        genres: {
          fields: this.$options.NAME_ID_FIELDS
        },
        companies: {
          fields: this.$options.NAME_ID_FIELDS
        },
        platforms: {
          fields: [
            ...this.$options.NAME_ID_FIELDS,
            ...[
              {
                key: "short"
              },
              {
                key: "abbr"
              },
              {
                key: "image",
                type: "image",
                imageType: "PLATFORM"
              },
              {
                key: "groups",
                notEditable: true
              }
            ]
          ]
        },
        profile_pics: {
          fields: [
            {
              key: "id",
              idField: true
            },
            {
              key: "description",
              label: "Description"
            },
            {
              key: "file",
              label: "Image",
              type: "image",
              imageType: "PROFILE_PIC"
            },
            {
              key: "profile_pic_type_id",
              label: "Type",
              type: "select",
              options: () => { return this.dataConfig.profile_pics.types; },
              idListField: "desc"
            }
          ],
          data: undefined,
          types: undefined
        }
      }
    }
  },
  created () {
    this.$store.dispatch('getDataTriggerOptions')
    .then(response => {
      this.dataTriggerOptions = response.data_process_types;
    });

    this.$store.dispatch('meta/getCompanies', true);
    this.$store.dispatch('meta/getProfilePics')
    .then(response => {
      this.dataConfig.profile_pics.data = response.data.images;
      this.dataConfig.profile_pics.types = response.data.types;
    });
  },
  methods: {
    triggerUpdate () {
      let bits = _.reduce(this.selectedTriggerOpts, (memo, val) => { return memo | val }, 0);
      this.$store.dispatch('triggerDataUpdate', bits)
    },
    toggleRowDetails (item) {
      Vue.set(item, '_showDetails', !item._showDetails);
    },
    findMatchingPlatformsByGroup (groupId) {
      return _.filter(this.platformsList, p => {
        return _.contains(p.groups, groupId);
      })
    },
    findMatchingPlatformGroupsByType (typeId) {
      return _.where(this.platformGroupsList, { meta_type_id: typeId });
    },
    fastNameFilter (row, filter) {
      // For some reason built-in table filtering was slow/glitchy
      return row.name.toLowerCase().includes(filter.toLowerCase()) || row.id.toString().includes(filter);
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .tab-content {
  padding: 2em;
}

::v-deep .nav-item > a {
  color: var(--link-text);
  &:hover {
    opacity: 0.8;
  }
}

::v-deep .table-hover  {
  .table-dark:hover {
    background-color: #343a40; //override some weird style in bootstrap css
  }
  > tbody > tr {
    &.b-table-details > td > div {
      margin: 0.5em 1em;
      padding: 1em;
      background-color: var(--list-item-bg);
      border-radius: 0.25em;
    }
    &:hover {
      &:not(.b-table-details) {
        cursor: pointer;
      }
      &.b-table-details {
        background-color: unset;
      }
    }
  }
}
</style>
