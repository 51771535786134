<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: "GAMEYE",
      meta: [
        {vmid: "canonical", property: "canonical", content: window.location.origin + this.$route.fullPath}
      ]
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import "@/assets/styles/_variables.scss";

html, #app, #base {
  height: 100%;
}

#content {
  position: relative;
  flex-grow: 1;
}

#base {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

html body {
  width: 100%;
  margin: $headerHeight 0 0;
  height: calc(100% - $headerHeight);
  line-height: initial;
  background-color: inherit;
  overflow-x: hidden;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 14px;
}

#base > #content > div:not(.home) {
  top: $headerHeight;
  left: 0;
  width: 100%; /* required due to left margin and fitting within page */
  opacity: 1;
  height: calc(100% - $headerHeight);
  max-height: calc(100% - $headerHeight);
  display: flex;
  flex-direction: column;
}

input::-moz-focus-inner,
select::-moz-focus-inner {
  outline: none;
  border: none;
}

input.form-control {
  border-radius: 4px !important;
  &::placeholder {
    opacity: 0.7;
    font-size: 0.9em;
  }
}

select:focus {
  outline: none;
  box-shadow: none;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000
}

html {
  background-color: var(--background-color);
}

.flex,
.flex-col {
  display: flex;
}

.flex {
  &.center {
    justify-content: center;
  }
  &.v-center {
    align-items: center;
  }
}

.flex-col {
  flex-direction: column;
  &.center {
    align-items: center;
  }
  &.v-center {
    justify-content: center;
  }
}

.v-h-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.absolute-h-center {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.absolute-v-center {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.sticky-below-nav {
  position: fixed;
  top: calc($headerHeight + 15px);
}

.grid {
  display: grid;
}

$gap-sizes: 4, 8, 12, 16;

@each $size in $gap-sizes {
  .gap-#{$size} {
    gap: $size + px;
  }
  .gap-col-#{$size} {
    column-gap: $size + px;
  }
  .gap-row-#{$size} {
    row-gap: $size + px;
  }
}

.float-right {
  float: right;
}

.left {
  text-align: left;
}

.align-right {
  text-align: right;
  justify-content: right;
  align-items: end;
}

.align-center {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.g-container {
  padding: 10px 15px;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.margin-0 {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.strike {
  text-decoration: line-through;
}

.grey {
  color: #afaeae;
  fill: #afaeae;
}

.light-grey {
  color: #cfcfcf;
  fill: #cfcfcf;
}

.error {
  color: var(--danger);
}

.btn.btn-link,
a.btn-link {
  &, &:hover {
    color: var(--gameye-blue-light);
  }
}

a, a:hover {
  color: var(--nav-default);
}

a.plain,
.btn.plain {
  &, &:hover {
    color: currentColor;
    text-decoration: none;
  }
}

.btn.plain {
  border: none;
  &:not(:hover) {
    background-color: transparent;
  }
}

.btn.plain {
  &:focus, &:active {
    border: none !important;
    box-shadow: none !important;
  }
}

span:not(.unfilled) {
  .bg-fill {
    fill: white;
  }
  .logo-fill {
    fill: var(--gameye-blue);
  }
}

span.unfilled {
  .bg-fill {
    fill: transparent;
  }
  .logo-fill {
    fill: currentColor;
  }
}

 .logo-text-fill {
   fill: white;
 }

.pill {
  .badge-warning {
    background-color: #FFD60A;
  }
}

.hidden {
  display: none;
}

.locked,
.locked .nav-tab {
  color: #AAA;
}

.locked .nav-tab .fill-color {
  fill: #AAA;
}

/* bootstrap overrides */
.custom-checkbox {
  &.center {
    margin: 0;
    padding-left: 1rem;
    .custom-control-label {
      &::before, &::after {
        left: -1rem;
      }
    }
  }
  .custom-control-label {
    line-height: 1.5em;
    &::before {
      background-color: transparent;
    }
    &::before, &::after {
      top: 1px;
    }
  }
}

.form-group {
  margin-bottom: 0;
}

.btn.btn-link:focus,
.btn-group-toggle > .btn {
  box-shadow: none !important;
}

.btn.btn-outline-primary {
  color: rgb(51, 162, 255);
}

.nav-tabs.dark {
  $border-color: rgb(56, 61, 63);
  border-bottom-color: $border-color;
  .nav-link, .nav-link.active {
    border-color: transparent;
    background-color: transparent;
    color: var(--link-text);
    &.exact-active {
      color: rgb(181, 175, 166);
      background-color: transparent;
      border-color: $border-color $border-color transparent;
    }
    &:focus, &:hover {
      border-color: $border-color $border-color transparent;
    }
  }
  
}

.nav-container {
  .nav-tab .fill-color {
    fill: var(--nav-default);
  }

  .nav-tab:not(.router-link-active) .fill-none-inactive {
    fill: none;
  } 

  .nav-tab.router-link-active {
    background-color: rgba(17, 23, 30, 0.6);
    border-left: 5px solid;
    &.nav_encyclopedia-icon {
      border-color: var(--nav-encyclopedia);
      color: var(--nav-encyclopedia);
      .fill-color {
        fill: #F1BB00;
      }
      .fill-none-inactive {
        fill: var(--nav-encyclopedia);
      }
    }
    &.nav_library-icon {
      border-color: var(--nav-library);
      color: var(--nav-library);
      .fill-color {
        fill: var(--nav-library);
      }
      .fill-none-inactive {
        fill: #00C6F7;
      }
    }
    &.nav_wishlist-icon {
      border-color: var(--nav-wishlist);
      color: var(--nav-wishlist);
      .fill-color {
        fill: var(--nav-wishlist);
      }
      .fill-none-inactive {
        fill: #d84545;
      }
    }
    &.nav_sell-icon {
      border-color: var(--nav-sell);
      color: var(--nav-sell);
      .fill-color {
        fill: var(--nav-sell);
      }
    }
    &.nav_tools-icon {
      border-color: var(--nav-tools);
      color: var(--nav-tools);
      .fill-color {
        fill: var(--nav-tools);
      }
    }
  }
  .nav-link.active {
    color: #fff;
    background-color: transparent;
    border: none;
    border-bottom: 3px solid #fff;
  }
  .nav-link svg {
    fill: #A3A6AE;
    margin-bottom: 4px;
  }
  .nav-link.active svg {
    fill: #fff;
  }
}

select.custom-select,
select.custom-select:focus {
  border: none;
  outline: none;
  font-size: inherit;
  background: var(--input-grey-bg) url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23828282' d='M 4 2 L 0 2 L 2 5 Z'/%3e%3c/svg%3e") no-repeat right 12px center/8px 10px !important;
  padding: 3px 20px 3px 8px !important;
  box-shadow: none;
}

.icon-set svg {
  fill: currentColor;
}

.item .footer svg,
.prices .pill svg {
  fill: white;
}

.item .prices-col .pills .value .body {
  margin: 0 10px;
}

.img-fluid {
  max-height: 100%;
}

.game-details {
  width: 100%;
}

.no-padding {
  padding: 0 !important;
}

.modal .modal-content {
  background-color: #4F5058;
  color: white;
}

.input-group input.form-control:focus {
  box-shadow: none;
}

.input-with-icon > span {
  position: absolute;
  left: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  color: #707070;
  font-size: 1.1em;
  &.close:hover {
    cursor: pointer;
    color: #555;
  }
}

.input-with-icon.right {
  > span,
  > div {
    right: 15px;
    left: unset;
    z-index: 4;
  }
  > input {
    padding-left: 10px;
  }
}

.input-with-icon > input {
  padding-left: 30px;
  padding-right: 30px;
  height: 30px;
}

.input-with-icon > input:focus {
  border-color: white;
}

.input-with-icon > input:focus + span {
  color: #444;
  z-index: 4;
}

.search .autocomplete-input {
  font-size: 1em;
  border-radius: 3px !important;
  border: none;
}

.search .autocomplete {
  color: var(--input-grey-text);
}

.search .autocomplete-input,
.search .autocomplete-result {
  padding: 3px 15px;
  color: var(--input-grey-text);
}

.search .autocomplete > input,
.search .autocomplete > input:focus {
  background-image: none;
  background-color: var(--input-grey-bg);
}

.search .autocomplete .badge-pill {
  border-radius: 3px;
  cursor: pointer;
}

.search .autocomplete .badge {
  font-size: 80%;
}

.filters .active .badge {
  font-size: 100%;
  padding: 5px 10px;
}

.title .dropdown {
  > .btn {
    color: white;
    text-decoration: none;
    box-shadow: none;
    padding: 0;
  }
  .icon > svg {
    fill: white;
    margin-right: 10px;
    margin-top: -4px;
  }
  ul {
    background-color: rgb(34, 34, 34);
    border-radius: 0;
    padding: 0;
    li {
      > .dropdown-item {
        color: white;
        padding-top: 8px;
        padding-bottom: 8px;
        &:hover {
          background-color: rgb(80, 80, 80);
        }
      }
      &:not(:last-child) {
        border-bottom: 1px solid grey;
      }
    }
  }
}

.top-bar .link > .dropdown {
  display: inline-block;
  line-height: 1em;
  font-size: 1em;
  > .btn.dropdown-toggle {
    padding: 0;
    margin: 0;
    font-size: 1em;
    line-height: 1em;
    text-decoration: none;
    color: inherit;
  }
}

.popover.link-edit {
  max-width: 450px;
  &.text-area {
    max-width: 800px;
    .popover-body {
      width: 800px;
    }
  }
  background-color: var(--nav-gradient-bottom) !important;
  border-color: rgba(0, 0, 0, 0.2) !important;
  > .arrow::after,
  > .arrow::before {
    border-top-color: var(--nav-gradient-bottom) !important;
  }
  .popover-body {
    width: 450px;
  }
  input {
    color: white;
    background-color: transparent;
  }
}

.multiselect {
  .multiselect__tags {
    .multiselect__input {
      color: var(--dark);
      &::placeholder {
        opacity: 0.5;
      }
    }
    &, .multiselect__single, .multiselect__input {
      background-color: var(--input-grey-bg);
    }
  }
  .platform {
    color: var(--dark) !important;
  }
}

.dissolvey-enter-active,
.dissolvey-leave-active {
  transition: all 0.3s ease;
}

.dissolvey-enter,
.dissolvey-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.dissolve-out-enter-active,
.dissolve-out-leave-active {
  transition: all 0.3s ease;
}

.dissolve-out-enter,
.dissolve-out-leave-to {
  transform: scale(0.8);
  opacity: 0;
}

@media (max-width: $smallWidth) {
  #base > div:not(.top-bar) > div:not(.home) {
    top: $smallHeaderHeight;
  }
  html body {
    margin-top: $smallHeaderHeight;
    height: calc(100% - $smallHeaderHeight);
  }
  .sticky-below-nav {
    top: calc($smallHeaderHeight + 15px);
  }
}

@media (max-width: $mobileWidth) {
  #base > div:not(.top-bar) > div:not(.home) {
    top: $mobileHeaderHeight;
  }
  html body {
    margin-top: $mobileHeaderHeight;
    height: calc(100% - $mobileHeaderHeight);
  }
  .sticky-below-nav {
    top: calc($mobileHeaderHeight + 15px);
  }
}

</style>
