<template>
  <queue-item :item="item" queue-type="TGDB" :queue-id="item.other_id"
    :skipped-fields="SKIPPED_FIELDS" :skipped-fields-if-set="SKIP_IF_SET_FIELDS"
    :source-link="tgdbLink"
    :save-func="saveQueueChange" :decline-func="deleteFromQueue"
    :remove-from-queue-func="deleteFromQueue"
  ></queue-item>
</template>

<script>
import _ from "underscore";
import QueueItem from "@/components/QueueItem";
import {QUEUE_TYPE_IDS, ITEM_FIELDS_MAP} from "@/store/constants.js";

export default {
  name: "TGDBitem",
  components: {
    QueueItem
  },
  SKIPPED_FIELDS:[
    "vgpc_id",
    "vgpc_url",
    "metacritic_url",
    "image_url", // only used for editing i think?
    "game_specific",
    "tgdb_id",
    "category_id"
  ],
  SKIP_IF_SET_FIELDS: [
    "country_id",
    "release_type",
    "platform_id",
    "rating_id"
  ],
  data () {
    return {
      SKIPPED_FIELDS: this.$options.SKIPPED_FIELDS,
      SKIP_IF_SET_FIELDS: this.$options.SKIP_IF_SET_FIELDS,
      QUEUE_TYPE_IDS: QUEUE_TYPE_IDS,
      ITEM_FIELDS_MAP: ITEM_FIELDS_MAP
    }
  },
  props: {
    item: {
      // Expects very specific format (based on back end API format):
      //   {
      //      id (queue id), item_id (gameye id), other_id (tgdb id), type_id (game, system, etc id),
      //      data {} (represents data from tgdb),
      //      oldData {} (represents gameye data - if applicable),
      //      imagesInQueue (nested object representing queue item -what this is- for related image change) {
      //        -> NOTE: all images added to queue are grouped into one queue item, that's why this is one object
      //        data {
      //          base_url (url for all images in this),
      //          data { tgdb_id, art (object of image types mapped to list of images) }
      //        }
      //      }
      //   }
      type: Object,
      required: true
    }
  },
  computed: {
    tgdbLink () {
      return `https://thegamesdb.net/game.php?id=${this.item.other_id}`;
    }
  },
  methods: {
    saveQueueChange (objToSave, changeTypeId) {
      let storeMethod;
      if (changeTypeId === QUEUE_TYPE_IDS.TGDB.EDIT) {
        storeMethod = "edit";
        // set this value if overriding a merge - means entry needs tgdb_id set
        if (this.item.type_id !== changeTypeId) objToSave.tgdb_id = this.item.other_id;
      } else if (changeTypeId === QUEUE_TYPE_IDS.TGDB.NEW) {
        storeMethod = "addNew";
        // only set this value for new items
        objToSave.tgdb_id = this.item.other_id;
      } else if (changeTypeId === QUEUE_TYPE_IDS.TGDB.DELETE) {
        storeMethod = "delete";
      }
      return this.$store.dispatch(`updates/${storeMethod}Item`, objToSave);
    },
    deleteFromQueue (queueItem) {
      return this.$store.dispatch('queue/deleteFromTGDBQueue', queueItem.id).catch(e => {
        this.handleError(e, "Couldn't delete from the queue. The changes were successfully made, however.");
      });
    }
  }
}
</script>
