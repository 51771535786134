<script>
import UserBadge from "@/components/UserBadge";

export default {
  name: "PlaceholderUserBadge",
  extends: UserBadge,
  props: {
    showAssignedImage: {
      type: Boolean,
      default: true
    },
    // array of field name strings
    editingFields: {
      type: Array
    }
  },
  computed: {
    displayName () { return this.displayNameOverride || undefined; },
    styleObj () {
      if (!this.editingFields) return undefined;
      let vars = {};
      this.editingFields.forEach(f => {
        vars[`--${f}-bg-color`] = '#3E5C74';
      })
      return vars;
    },
    avatarText () { return this.showAssignedImage ? (this.userName ? this.userName[0].toUpperCase() : ' ') : ' '; },
    _userColor () {
      if (!this.showAssignedImage) return undefined;
      if (this.colorOverride) return this.colorOverride;
      return this.userColor;
    }
  },
  data () {
    return {
      baseClass: 'placeholder'
    }
  }
}
</script>

<style lang="scss">
.user-badge.placeholder {
  #displayName {
    min-height: 1.4rem;
    width: 100%;
    background-color: var(--displayName-bg-color);
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 0 0.25rem;
  }
}
</style>