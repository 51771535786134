
export const DIGITAL = -1;
export const UNKNOWN = 0;
export const HAVE_ITEM = (1 << 0);
export const HAVE_MANUAL = (1 << 1);
export const HAVE_BOX = (1 << 2);
export const HAVE_ALL_INSERTS = (1 << 3);

export const HAVE_BOX_AND_GAME = (HAVE_BOX | HAVE_ITEM);
export const HAVE_GAME_AND_MANUAL = (HAVE_ITEM | HAVE_MANUAL);
export const HAVE_BOX_AND_MANUAL = (HAVE_BOX | HAVE_MANUAL);

export const COMPLETE_MINUS_INSERTS = (HAVE_ITEM | HAVE_MANUAL | HAVE_BOX);
export const COMPLETE = (COMPLETE_MINUS_INSERTS | HAVE_ALL_INSERTS);

export const PRIVATE = (1 << 4);

export const SEALED = (PRIVATE | COMPLETE);

// For ease of use with print media
export const PRINT_MEDIA_HAVE_ITEM = HAVE_ITEM;
export const PRINT_MEDIA_HAVE_INSERTS = HAVE_BOX_AND_MANUAL;
export const PRINT_MEDIA_HAVE_OTHER = HAVE_ALL_INSERTS;