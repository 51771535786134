<template>
  <span class="icon-set">
    <svg v-if="icon === 'all'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 40">
      <g id="Layer_1" data-name="Layer 1" transform="translate(-3 -50)">
        <path id="Path_47" data-name="Path 47" d="M35,83.207H28.335V90l-19.3-3.831A7.663,7.663,0,0,1,3,78.772V54.367A4.407,4.407,0,0,1,7.428,50H35Zm-8.677,0V62.288L5.9,58.471a4.455,4.455,0,0,1-.8-.4c-.029-.019-.064-.028-.093-.047V78.772a5.655,5.655,0,0,0,4.443,5.457l16.868,3.347Zm6.665-31.223H7.428A2.4,2.4,0,0,0,5.011,54.3a2.425,2.425,0,0,0,1.516,2.28l21.808,4.064V81.221h4.653Z" transform="translate(0 0)"/>
      </g>
    </svg>
    <svg v-if="icon === 'game'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.391 40">
      <g data-name="Game" transform="translate(-202.177 -2.359)">
        <path id="Path_18" data-name="Path 18" d="M241.431,2.359H203.313A1.137,1.137,0,0,0,202.177,3.5V36.1a1.136,1.136,0,0,0,1.136,1.136H205.9v3.988a1.137,1.137,0,0,0,1.136,1.136h30.681a1.137,1.137,0,0,0,1.136-1.136V37.235h2.582a1.136,1.136,0,0,0,1.136-1.136V3.5A1.137,1.137,0,0,0,241.431,2.359ZM218.876,4.632h14.917V27.483a.952.952,0,0,1-.951.951H219.826a.952.952,0,0,1-.951-.951ZM240.3,34.962h-2.582a1.136,1.136,0,0,0-1.136,1.136v3.987H208.168V36.1a1.136,1.136,0,0,0-1.136-1.136h-2.582V4.632h12.608V27.483a2.771,2.771,0,0,0,2.769,2.768h13.015a2.772,2.772,0,0,0,2.769-2.768V4.632H240.3Z" transform="translate(0 0)"/>
      </g>
    </svg>
    <svg v-if="icon === 'system'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.503 40">
      <g data-name="System" transform="translate(-262.877 -4.74)">
        <path id="Path_17" data-name="Path 17" d="M312.256,14.142l-3.7-7.823a1.3,1.3,0,0,0-1.15-.745l-4.776-.1-1.681-.646a1.291,1.291,0,0,0-.467-.086H274.768a1.288,1.288,0,0,0-.466.086l-1.683.647-4.766.1a1.3,1.3,0,0,0-1.15.745L263,14.142a1.3,1.3,0,0,0-.124.557V38.4a1.338,1.338,0,0,0,.043.335l1.338,5.036a1.3,1.3,0,0,0,1.26.968h44.221a1.3,1.3,0,0,0,1.26-.968l1.338-5.036a1.335,1.335,0,0,0,.043-.335V14.7A1.3,1.3,0,0,0,312.256,14.142Zm-5.712-5.98,2.723,5.756H303.7l-1.264-5.844Zm-31.72-.745.186-.071h25.229l.187.073,1.407,6.5h-28.41Zm-1.67,8.064H302.1v2.806l-2.485,2.157h-23.98l-2.485-2.157Zm-4.44-7.319,4.1-.089-1.258,5.845H265.99Zm40.025,33.973H266.519l-.785-2.952h43.788Zm1.037-4.515H265.482V15.48h5.849V18.7a.915.915,0,0,0,.314.689l3.055,2.654a.915.915,0,0,0,.6.224h24.662a.915.915,0,0,0,.6-.224l3.055-2.654a.915.915,0,0,0,.314-.689V15.48h5.849ZM282.851,27.025a2.867,2.867,0,1,0,2.867,2.866A2.87,2.87,0,0,0,282.851,27.025Zm0,4.17a1.3,1.3,0,1,1,1.3-1.3A1.306,1.306,0,0,1,282.851,31.2Zm-9.552-4.17a2.867,2.867,0,1,0,2.865,2.866A2.869,2.869,0,0,0,273.3,27.025Zm0,4.17a1.3,1.3,0,1,1,1.3-1.3A1.305,1.305,0,0,1,273.3,31.2Zm19.107-4.17a2.867,2.867,0,1,0,2.866,2.866A2.87,2.87,0,0,0,292.406,27.025Zm0,4.17a1.3,1.3,0,1,1,1.3-1.3A1.305,1.305,0,0,1,292.406,31.2Zm6.688-1.3a2.865,2.865,0,1,0,2.865-2.866A2.869,2.869,0,0,0,299.094,29.891Zm4.167,0a1.3,1.3,0,1,1-1.3-1.3A1.305,1.305,0,0,1,303.261,29.891Z" transform="translate(0 0)"/>
      </g>
    </svg>
    <svg v-if="icon === 'peripheral'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42.829 40">
      <g data-name="Peripheral" transform="translate(-325.3 -2.602)">
        <path id="Path_16" data-name="Path 16" d="M338.655,31.088h-1.612a.231.231,0,0,1-.23-.23V29.246a.46.46,0,0,0-.46-.461h-1.382a.461.461,0,0,0-.461.461v1.611a.231.231,0,0,1-.23.23h-1.611a.461.461,0,0,0-.461.461V32.93a.46.46,0,0,0,.461.46h1.611a.231.231,0,0,1,.23.23v1.612a.461.461,0,0,0,.461.461h1.382a.46.46,0,0,0,.46-.461V33.62a.231.231,0,0,1,.23-.23h1.612a.46.46,0,0,0,.461-.46V31.549A.461.461,0,0,0,338.655,31.088Zm19.111-9.21h-22.1a10.362,10.362,0,1,0,8.288,16.579h5.529a10.362,10.362,0,1,0,8.288-16.579Zm0,18.421a8.045,8.045,0,0,1-6.73-3.626,1.15,1.15,0,0,0-.961-.519h-6.722a1.151,1.151,0,0,0-.961.517,8.059,8.059,0,1,1-6.73-12.491h22.1a8.059,8.059,0,1,1,0,16.118Zm-2.914-9.593a1.534,1.534,0,1,0,1.534,1.534A1.534,1.534,0,0,0,354.852,30.705Zm5.83,0a1.534,1.534,0,1,0,1.534,1.534A1.534,1.534,0,0,0,360.682,30.705Zm-2.914,2.914a1.534,1.534,0,1,0,1.534,1.535A1.535,1.535,0,0,0,357.767,33.619Zm0-2.761a1.534,1.534,0,1,0-1.535-1.534A1.534,1.534,0,0,0,357.767,30.858Zm-8.313.009L347.622,32.7a.72.72,0,1,0,1.017,1.019l1.833-1.833a.72.72,0,0,0-1.017-1.019Zm-4.9,0L342.726,32.7a.72.72,0,0,0,1.017,1.019l1.833-1.833a.72.72,0,0,0-1.017-1.019Zm.277-14.069a6.1,6.1,0,0,1,1.188,2.988h1.841a7.648,7.648,0,0,0-1.454-3.942,12.052,12.052,0,0,1-.895-1.662,2.943,2.943,0,0,1,.041-2.353,1.406,1.406,0,0,1,1.008-.749,5.774,5.774,0,0,1,2.474.228,6.387,6.387,0,0,0,4.062,0A5.911,5.911,0,0,0,356.423,7.9a5.46,5.46,0,0,0-.226-4.446v0c-.072-.14-.149-.277-.233-.412a.921.921,0,0,0-1.565.972c.057.089.109.182.157.276v0a3.637,3.637,0,0,1,.142,2.965A4.064,4.064,0,0,1,352.416,9.6a4.708,4.708,0,0,1-2.957-.082,7.41,7.41,0,0,0-3.269-.239,3.232,3.232,0,0,0-2.257,1.684,4.748,4.748,0,0,0-.126,3.91A13.33,13.33,0,0,0,344.836,16.8Z"/>
      </g>
    </svg>
    <svg v-if="icon === 'toy'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 31.045 40">
      <g data-name="Toy" transform="translate(-205.977 -63.644)">
        <path id="Path_15" data-name="Path 15" d="M236.848,101.772a6.271,6.271,0,0,0,.145-2.056l-.246-2.027a2.548,2.548,0,0,0-2.415-2.3H230.5v-.372a2.8,2.8,0,0,0-.915-2.045,48.29,48.29,0,0,0,.383-7.156A5.388,5.388,0,0,0,232.4,86.83a2.634,2.634,0,0,0,2.108-.666c1.9-1.731.864-4.188-1.416-7.59a27.55,27.55,0,0,0-4.646-5.508,7.272,7.272,0,1,0-13.747.447,34.728,34.728,0,0,1-6.5,5.291,3.409,3.409,0,0,0-1.632,3.222,2.927,2.927,0,0,0,1.354,2.318c1.29.8,3.206.58,4.916.091a78.9,78.9,0,0,0,.661,8.533,2.845,2.845,0,0,0-.914,2.046v.372h-3.92a2.553,2.553,0,0,0-2.415,2.3l-.246,2.029a6.3,6.3,0,0,0,.145,2.056,2.508,2.508,0,0,0,2.3,1.872h26.093a2.509,2.509,0,0,0,2.3-1.871ZM221.5,65.707a5.215,5.215,0,0,1,5.209,5.208h0a5.209,5.209,0,1,1-5.208-5.208Zm-6.222,28.444a1.029,1.029,0,0,0,.344-.931,77.151,77.151,0,0,1-.74-10.21,1.03,1.03,0,0,0-1.393-.962c-2.06.771-3.776.977-4.475.542a.892.892,0,0,1-.39-.769,1.33,1.33,0,0,1,.62-1.239A37.572,37.572,0,0,0,216.132,75a6.74,6.74,0,0,0,10.987-.341,25.861,25.861,0,0,1,4.264,5.062c2.7,4.036,2.051,4.631,1.737,4.917a.592.592,0,0,1-.51.138,5.8,5.8,0,0,1-3.006-2.1,1.032,1.032,0,0,0-1.856.748,41.33,41.33,0,0,1-.283,9.8,1.032,1.032,0,0,0,.344.931l.107.09c.285.237.521.457.521.771v.372h-4.286l-.008-.052c-.081-.516-.3-1.751-.534-3.118-.338-1.935-.757-4.342-.836-4.973a.825.825,0,0,0-.818-.724h-.821a.825.825,0,0,0-.818.724c-.077.631-.5,3.038-.836,4.973-.239,1.367-.454,2.6-.533,3.116l-.009.054H214.65v-.372c0-.315.236-.535.521-.772Zm7.2,1.233h-1.871c.1-.589.287-1.685.5-2.887.147-.84.3-1.728.438-2.527.137.8.292,1.687.439,2.527C222.192,93.7,222.383,94.793,222.479,95.384Zm12.464,4.578a4.269,4.269,0,0,1-.088,1.273.55.55,0,0,1-.309.345H208.462a.536.536,0,0,1-.317-.342,4.349,4.349,0,0,1-.09-1.276l.246-2.029a.553.553,0,0,1,.367-.486h25.664a.55.55,0,0,1,.367.486Z" transform="translate(0)"/>
      </g>
    </svg>
    <svg v-if="icon === 'print'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 42">
      <g data-name="Print">
        <path d="M35.56,2.25c-1.18,0-4.3,1.08-28.54,1.08a.8.8,0,0,0-.81.79h0V34.61a.8.8,0,0,0,.73.8c6.51.64,17.21,2.09,22.83,4.74a.75.75,0,0,0,.32.1c.16,0,.21-.13.21-.43V36.94l2.36.32h.21c.19,0,.22-.09.22-.38V34.49c.85-.09,1.68-.17,2.46-.27a.28.28,0,0,0,.24-.28V2.63C35.79,2.38,35.76,2.25,35.56,2.25ZM28.41,37.58C21.8,35.19,11.93,34,8.1,33.61V5.39C11.88,5.71,22,6.68,28.41,8.52Zm3.26-1.89-1.37-.18V7.33a.26.26,0,0,0-.19-.26,56.18,56.18,0,0,0-6.89-1.66c3,.17,6,.36,8.45.56Zm2.7-2.75-1.28.13V4.92a.27.27,0,0,0-.26-.27c-1.35-.12-2.83-.24-4.44-.34,2.09-.13,4.12-.29,6-.5Z"/>
      </g>
    </svg>
    <svg v-if="icon === 'other'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 24">
      <g data-name="Other" transform="translate(168 -3) rotate(90)">
        <path id="Path_40" data-name="Path 40" d="M9,165a3,3,0,1,1-3-3A3,3,0,0,1,9,165Zm9,0a3,3,0,1,1-3-3A3,3,0,0,1,18,165Zm9,0a3,3,0,1,1-3-3A3,3,0,0,1,27,165Z"/>
      </g>
    </svg>
    <svg v-if="icon === 'logo'" :height="height" :width="width"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36">
      <g id="Group_35" data-name="Group 35" transform="translate(-56 45)">
        <path id="Path_87" class="bg-fill" data-name="Path 87" d="M73-45A17,17,0,0,0,56-28h0A17,17,0,0,0,73-11h0A17,17,0,0,0,90-28h0A17,17,0,0,0,73-45Z" transform="translate(1 1)"/>
        <path id="Path_80" class="logo-fill" data-name="Path 80" d="M84.029-24.686v2.571H81.457v2.571H78.886v2.571H76.315v-2.571H73.744v2.571a10.323,10.323,0,0,1-2.571-.324v-2.247H68.6v1.2a10.282,10.282,0,0,1-5.143-8.91,10.221,10.221,0,0,1,.455-3.027,5.113,5.113,0,0,0,2.116.455c.164,0,.326-.008.486-.023a5.14,5.14,0,0,0,4.634-4.634c.015-.159.023-.321.023-.486a5.113,5.113,0,0,0-.455-2.116,10.248,10.248,0,0,1,3.027-.455,10.285,10.285,0,0,1,8.91,5.143h-1.2v2.571H83.7a10.384,10.384,0,0,1,.324,2.572H81.453v2.571ZM71.172-22.115v2.571h2.571v-2.571Zm2.571-2.571v2.571h2.571v-2.571Zm2.571,0h2.571v-2.571H76.314Zm2.571-2.571h2.571v-2.572H78.885Zm0,5.143h2.571v-2.571H78.885Zm-2.571,0v2.571h2.571v-2.571Z"/>
        <g id="Path_95" data-name="Path 95" fill="none" stroke-miterlimit="10">
          <path d="M74-45A18,18,0,0,0,56-27h0A18,18,0,0,0,74-9h0A18,18,0,0,0,92-27h0A18,18,0,0,0,74-45Z" stroke="none"/>
          <path class="logo-fill" d="M 74 -43 C 69.72631072998047 -43 65.70836639404297 -41.33568954467773 62.68634033203125 -38.31365966796875 C 59.664306640625 -35.29162979125977 58 -31.27368927001953 58 -27 C 58 -22.72631072998047 59.664306640625 -18.70837020874023 62.68634033203125 -15.68634033203125 C 65.70836639404297 -12.66431045532227 69.72631072998047 -11 74 -11 C 78.27368927001953 -11 82.29163360595703 -12.66431045532227 85.31365966796875 -15.68634033203125 C 88.335693359375 -18.70837020874023 90 -22.72631072998047 90 -27 C 90 -31.27368927001953 88.335693359375 -35.29162979125977 85.31365966796875 -38.31365966796875 C 82.29163360595703 -41.33568954467773 78.27368927001953 -43 74 -43 M 74 -45 C 83.94100189208984 -45 92 -36.94100189208984 92 -27 C 92 -17.05899810791016 83.94100189208984 -9 74 -9 C 64.05899810791016 -9 56 -17.05899810791016 56 -27 C 56 -36.94100189208984 64.05899810791016 -45 74 -45 Z" stroke="none"/>
        </g>
      </g>
    </svg>
    <svg v-if="icon === 'logo-inverted'" :width="width" :height="height" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="logo-fill" d="M34.3367 0.134094C25.3475 0.134094 16.7266 3.70501 10.3703 10.0613C4.01403 16.4176 0.443115 25.0385 0.443115 34.0277H0.443115C0.443028 38.4787 1.31965 42.8861 3.02292 46.9984C4.72619 51.1106 7.22276 54.8471 10.3701 57.9945C13.5174 61.1418 17.2538 63.6385 21.366 65.3418C25.4782 67.0452 29.8857 67.9219 34.3367 67.9219C43.3259 67.9219 51.9469 64.351 58.3033 57.9947C64.6597 51.6385 68.2307 43.0175 68.2309 34.0283C68.2309 25.0391 64.66 16.4181 58.3038 10.0617C51.9475 3.70534 43.3265 0.134271 34.3373 0.134094L34.3367 0.134094ZM53.2221 38.3849V43.2261H48.3783V48.0673H43.5371V52.9091H38.6959V48.0673H33.8548V52.9091C32.2218 52.9096 30.5953 52.7046 29.0136 52.2989V48.0679H24.1737V50.328C20.3755 48.1341 17.434 44.7165 15.8299 40.6341C14.2258 36.5516 14.0541 32.0457 15.3429 27.853C16.5944 28.4205 17.9531 28.7127 19.3272 28.7099C19.6374 28.7099 19.9425 28.6955 20.2426 28.6667C22.4857 28.4548 24.5846 27.467 26.1775 25.8735C27.7704 24.28 28.7574 22.1807 28.9684 19.9375C28.9972 19.6375 29.0116 19.3326 29.0116 19.0229C29.0138 17.6488 28.7216 16.2902 28.1548 15.0385C32.3469 13.749 36.8525 13.9198 40.935 15.523C45.0175 17.1263 48.4353 20.0671 50.6297 23.8647H48.3696V28.7066H52.6006C53.0066 30.2887 53.2116 31.9157 53.2108 33.5491H48.3696V38.3909L53.2221 38.3849ZM29.0116 43.2261V48.0673H33.8534V43.2261H29.0116ZM33.8534 38.3849V43.2261H38.6946V38.3849H33.8534ZM38.6946 38.3849H43.5358V33.5437H38.6946V38.3849ZM43.5358 33.5437H48.377V28.7006H43.5358V33.5437ZM43.5358 43.2281H48.377V38.3869H43.5358V43.2281ZM38.6946 43.2281V48.0693H43.5358V43.2281H38.6946Z"/>
    </svg>
    <svg v-if="icon === 'logo-text'" :height="height" :width="width" 
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 430 63.18">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path class="logo-text-fill" d="M46.94,55.72a23.88,23.88,0,0,1-17.37,7.46A27.64,27.64,0,0,1,18,60.72,29.11,29.11,0,0,1,8.64,54,32,32,0,0,1,2.33,43.83,33.48,33.48,0,0,1,0,31.24a31.62,31.62,0,0,1,8.73-22,30.74,30.74,0,0,1,9.6-6.76,29,29,0,0,1,12-2.5,29.2,29.2,0,0,1,15,3.69,23.83,23.83,0,0,1,9.43,9.91l-9,6.67a16.79,16.79,0,0,0-6.63-7.11,18.2,18.2,0,0,0-9.25-2.46,15.93,15.93,0,0,0-7.24,1.67,17,17,0,0,0-5.61,4.56,21.36,21.36,0,0,0-3.6,6.67,24.61,24.61,0,0,0-1.27,8,23.42,23.42,0,0,0,1.4,8.2,20.89,20.89,0,0,0,3.86,6.62,18,18,0,0,0,5.83,4.43,16.58,16.58,0,0,0,7.33,1.62A21.25,21.25,0,0,0,46.94,44V39.58H34.39V30.72H56.94v32h-10Z"/>
          <path class="logo-text-fill" d="M100.41.45h10.71L134.8,62.74H122.35l-5.79-15.53H94.8L89.1,62.74H76.64Zm14,38.25-8.59-24.48-9,24.48Z"/>
          <path class="logo-text-fill" d="M206.55,62.74V21.5L190.5,52.21h-6.58l-16-30.71V62.74H155.76V.45h13l18.42,35.44L205.77.45h12.89V62.74Z"/>
          <path class="logo-text-fill" d="M289.86,52.12V62.74H246.61V.45h42.46V11.06H258.71v15h26.23v9.82H258.71V52.12Z"/>
          <path class="logo-text-fill" d="M321.8.45l15.35,30,15.62-30h13.07L343.2,41V62.74h-12V40.8L308.64.45Z"/>
          <path class="logo-text-fill" d="M430,52.12V62.74H386.75V.45h42.46V11.06H398.86v15h26.23v9.82H398.86V52.12Z"/>
        </g>
      </g>
    </svg>
    <svg v-if="icon === 'nav_encyclopedia'" :height="height" :width="width" :class="icon"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 30">
      <g id="Encyclopedia" data-name="Encyclopedia" transform="translate(-3 -50)">
        <path id="Path_96" data-name="Path 96" d="M4.04,55.045l1.731.742,15.394,2.968V78.909L7.379,76.189l-1.917-.866L4.72,74.148l-.68-.866V72.046l-.247-1.669Z" class="fill-none-inactive"/>
        <path id="Path_47" data-name="Path 47" d="M27,74.9H22V80L7.527,77.127A5.747,5.747,0,0,1,3,71.579v-18.3A3.305,3.305,0,0,1,6.321,50H27Zm-6.508,0V59.216L5.178,56.353a3.341,3.341,0,0,1-.6-.3c-.022-.014-.048-.021-.07-.035V71.579a4.241,4.241,0,0,0,3.332,4.093l12.651,2.51Zm5-23.417H6.321a1.8,1.8,0,0,0-1.813,1.738,1.819,1.819,0,0,0,1.137,1.71L22,57.984V73.416h3.49Z" class="fill-color"/>
      </g>
    </svg>
    <svg v-if="icon === 'nav_library'" :height="height" :width="width" :class="icon"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.464 28.464">
      <g id="Group_44" data-name="Group 44" transform="translate(-1 -1)">
        <path class="fill-color" id="Path_51" data-name="Path 51" d="M17.844,2.668V8.794L15,11.641,12.156,8.8l0-6.127h5.69m9.488,9.488,0,5.688H21.2L18.357,15,21.2,12.156h6.134m-18.537,0L11.641,15,8.794,17.844l-6.126,0,0-5.69H8.8m6.2,6.2L17.844,21.2l0,6.132-5.69,0V21.2L15,18.357M17.846.768H12.154a1.9,1.9,0,0,0-1.9,1.9V9.581L15,14.329l4.748-4.748V2.666a1.9,1.9,0,0,0-1.9-1.9Zm9.488,9.488H20.411L15.671,15l4.74,4.748h6.923a1.9,1.9,0,0,0,1.9-1.9V12.154a1.9,1.9,0,0,0-1.9-1.9Zm-17.753,0H2.666a1.9,1.9,0,0,0-1.9,1.9v5.692a1.9,1.9,0,0,0,1.9,1.9H9.581L14.329,15l-4.748-4.74ZM15,15.671l-4.74,4.74v6.923a1.9,1.9,0,0,0,1.9,1.9h5.692a1.9,1.9,0,0,0,1.9-1.9V20.411L15,15.672Z" transform="translate(0.232 0.232)"/>
        <path class="fill-none-inactive" id="Path_97" data-name="Path 97" d="M-54.357,35.953H-47.7l3.281,3.519L-47.985,42.8h-6.373Z" transform="translate(57 -24)"/>
        <path class="fill-none-inactive" id="Path_98" data-name="Path 98" d="M-41.768,41.374l-3.934,4.09v6.753h7.514V45.226Z" transform="translate(57 -24)"/>
        <path class="fill-none-inactive" id="Path_99" data-name="Path 99" d="M-39.71,39.232l3.947,3.854h7.229V35.668H-36Z" transform="translate(57 -24)" />
        <path class="fill-none-inactive" id="Path_100" data-name="Path 100" d="M-41.768,37l3.96-4.09V25.871h-7.8V33.29Z" transform="translate(57 -24)"/>
      </g>
    </svg>
    <svg v-if="icon === 'nav_wishlist'" :height="height" :width="width" :class="icon"
      viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path class="fill-color" fill-rule="evenodd" d="M10 4.748l-.717-.737C7.6 2.281 4.514 2.878 3.4 5.053c-.523 1.023-.641 2.5.314 4.385.92 1.815 2.834 3.989 6.286 6.357 3.452-2.368 5.365-4.542 6.286-6.357.955-1.886.837-3.362.314-4.385-1.114-2.175-4.2-2.773-5.883-1.043L10 4.748zM10 17C-5.333 6.868 5.279-1.04 9.824 3.143c.06.055.119.112.176.171a3.12 3.12 0 01.176-.17C14.72-1.042 25.333 6.867 10 17z" clip-rule="evenodd"></path>
        <path class="fill-none-inactive" fill-rule="evenodd" d="M10 3.314C14.438-1.248 25.534 6.735 10 17-5.534 6.736 5.562-1.248 10 3.314z" clip-rule="evenodd"></path>
      </g>
    </svg>
    <svg v-if="icon === 'nav_sell'" :height="height" :width="width" :class="icon"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.85 21.36">
      <g id="Group_32" data-name="Group 32" transform="translate(-0.537 -4.292)">
        <path class="fill-color" id="Path_83" data-name="Path 83" d="M7,19.81V8.182L10.1,11.559l1.416-1.3L6.047,4.292.537,10.258l1.41,1.3,3.137-3.4V19.81a5.385,5.385,0,0,0,5.379,5.379h7.926V23.268H10.463A3.462,3.462,0,0,1,7,19.81Z" transform="translate(0)"/>
        <path class="fill-color" id="Path_84" data-name="Path 84" d="M28.006,18.383l-3.137,3.4V10.129a5.384,5.384,0,0,0-5.378-5.378H11.562V6.672h7.929a3.463,3.463,0,0,1,3.458,3.457V21.763l-3.093-3.377-1.416,1.3,5.467,5.97,5.51-5.966Z" transform="translate(-0.029 -0.001)"/>
      </g>
    </svg>
    <svg v-if="icon === 'nav_tools'" viewBox="0 0 16 16" :width="width" :height="height">
      <g>
        <path class="fill-color" d="M1 0L0 1l2.2 3.081a1 1 0 0 0 .815.419h.07a1 1 0 0 1 .708.293l2.675 2.675-2.617 2.654A3.003 3.003 0 0 0 0 13a3 3 0 1 0 5.878-.851l2.654-2.617.968.968-.305.914a1 1 0 0 0 .242 1.023l3.356 3.356a1 1 0 0 0 1.414 0l1.586-1.586a1 1 0 0 0 0-1.414l-3.356-3.356a1 1 0 0 0-1.023-.242L10.5 9.5l-.96-.96 2.68-2.643A3.005 3.005 0 0 0 16 3c0-.269-.035-.53-.102-.777l-2.14 2.141L12 4l-.364-1.757L13.777.102a3 3 0 0 0-3.675 3.68L7.462 6.46 4.793 3.793a1 1 0 0 1-.293-.707v-.071a1 1 0 0 0-.419-.814L1 0zm9.646 10.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708zM3 11l.471.242.529.026.287.445.445.287.026.529L5 13l-.242.471-.026.529-.445.287-.287.445-.529.026L3 15l-.471-.242L2 14.732l-.287-.445L1.268 14l-.026-.529L1 13l.242-.471.026-.529.445-.287.287-.445.529-.026L3 11z"></path>
      </g>
    </svg>
    <svg v-if="icon === 'sort'" :height="height" :width="width" :class="icon"
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17.199 17">
      <g id="Layer_1" data-name="Layer 1" transform="translate(-8.389 -261)">
        <path id="Path_37" data-name="Path 37" d="M17.582,269.8v-8.56H16.4v8.56a2.339,2.339,0,0,0,0,4.523V278h1.187v-3.674a2.342,2.342,0,0,0,0-4.523Zm-.6,3.414a1.153,1.153,0,0,1,0-2.305,1.153,1.153,0,0,1,0,2.305ZM10.72,261a2.339,2.339,0,0,0-.59,4.6V278h1.187V265.6a2.341,2.341,0,0,0-.6-4.6Zm0,3.492a1.135,1.135,0,0,1-1.118-.919,1.267,1.267,0,0,1-.026-.26,1.148,1.148,0,1,1,1.144,1.179ZM23.249,261a2.339,2.339,0,0,0-.59,4.6V278h1.187V265.6a2.339,2.339,0,0,0-.6-4.6Zm0,3.492a1.152,1.152,0,1,1,1.152-1.153A1.144,1.144,0,0,1,23.249,264.492Z" fill-rule="evenodd"/>
      </g>
    </svg>
    <svg v-if="icon === 'loose'" :height="height" :width="width" :class="icon" viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.0409 29.7722C12.1822 29.7722 9.38771 28.9245 7.01081 27.3363C4.63392 25.7482 2.78135 23.4908 1.68739 20.8497C0.593421 18.2087 0.30719 15.3025 0.864888 12.4988C1.42259 9.69503 2.79917 7.11963 4.82055 5.09825C6.84194 3.07686 9.41734 1.70028 12.2211 1.14258C15.0248 0.584884 17.931 0.871116 20.572 1.96508C23.2131 3.05905 25.4705 4.91161 27.0587 7.28851C28.6468 9.6654 29.4945 12.4599 29.4945 15.3185C29.4903 19.1506 27.9661 22.8245 25.2564 25.5341C22.5468 28.2438 18.8729 29.768 15.0409 29.7722ZM15.0409 2.29442C12.4667 2.29442 9.95024 3.05767 7.80977 4.48768C5.66931 5.91768 4.00089 7.95022 3.01546 10.3283C2.03003 12.7065 1.77183 15.3234 2.27351 17.8482C2.77519 20.373 4.01422 22.6925 5.83395 24.5132C7.65368 26.3339 9.97241 27.5742 12.497 28.0773C15.0215 28.5804 17.6386 28.3236 20.0172 27.3395C22.3959 26.3554 24.4294 24.6881 25.8605 22.5484C27.2917 20.4087 28.0564 17.8927 28.0578 15.3185C28.054 11.8674 26.6813 8.55871 24.241 6.11838C21.8007 3.67806 18.492 2.30541 15.0409 2.30161V2.29442Z"/>
      <path d="M15.0411 20.6453C13.9876 20.6453 12.9577 20.3329 12.0817 19.7476C11.2057 19.1623 10.523 18.3303 10.1198 17.357C9.71666 16.3837 9.61118 15.3126 9.81671 14.2793C10.0222 13.2461 10.5296 12.2969 11.2745 11.552C12.0195 10.807 12.9686 10.2997 14.0019 10.0941C15.0352 9.88861 16.1062 9.9941 17.0796 10.3973C18.0529 10.8004 18.8848 11.4832 19.4701 12.3592C20.0554 13.2351 20.3678 14.265 20.3678 15.3185C20.3664 16.7308 19.8048 18.0849 18.8061 19.0835C17.8075 20.0822 16.4534 20.6439 15.0411 20.6453ZM15.0411 10.8898C14.1652 10.8898 13.3089 11.1495 12.5806 11.6361C11.8523 12.1228 11.2846 12.8145 10.9494 13.6237C10.6142 14.433 10.5265 15.3235 10.6974 16.1825C10.8683 17.0416 11.2901 17.8308 11.9095 18.4502C12.5289 19.0695 13.318 19.4913 14.1771 19.6622C15.0362 19.8331 15.9267 19.7454 16.7359 19.4102C17.5452 19.075 18.2369 18.5073 18.7235 17.779C19.2101 17.0507 19.4699 16.1945 19.4699 15.3185C19.4689 14.1442 19.002 13.0183 18.1717 12.188C17.3413 11.3576 16.2154 10.8907 15.0411 10.8898Z"/>
      <path d="M15.041 18.1579C14.4795 18.1579 13.9305 17.9914 13.4636 17.6794C12.9966 17.3674 12.6327 16.924 12.4178 16.4051C12.2029 15.8863 12.1467 15.3154 12.2562 14.7646C12.3658 14.2138 12.6362 13.7079 13.0333 13.3108C13.4304 12.9137 13.9363 12.6433 14.4871 12.5337C15.0379 12.4242 15.6088 12.4804 16.1276 12.6953C16.6464 12.9102 17.0899 13.2741 17.4019 13.7411C17.7139 14.208 17.8804 14.757 17.8804 15.3185C17.8799 16.0714 17.5806 16.7934 17.0483 17.3258C16.5159 17.8581 15.7939 18.1574 15.041 18.1579ZM15.041 13.5567C14.6926 13.5567 14.352 13.6601 14.0622 13.8537C13.7725 14.0472 13.5467 14.3224 13.4133 14.6443C13.28 14.9663 13.2451 15.3205 13.3131 15.6623C13.3811 16.004 13.5489 16.3179 13.7952 16.5643C14.0416 16.8107 14.3556 16.9785 14.6973 17.0465C15.0391 17.1145 15.3933 17.0796 15.7152 16.9462C16.0372 16.8129 16.3123 16.5871 16.5059 16.2974C16.6995 16.0076 16.8028 15.667 16.8028 15.3185C16.8024 14.8514 16.6166 14.4036 16.2863 14.0733C15.956 13.743 15.5082 13.5572 15.041 13.5567Z"/>
      <g opacity="0.4">
        <path opacity="0.4" d="M9.12879 17.1342C9.11083 17.0768 9.09466 17.0193 9.0785 16.9546L3.31714 18.5512C3.90381 20.6365 5.03726 22.527 6.60011 24.027L10.7541 19.7168C10.0019 19.0078 9.44262 18.1191 9.12879 17.1342Z"/>
        <path opacity="0.4" d="M20.9012 13.7022L26.6644 12.1092C26.099 10.015 24.9827 8.11047 23.4317 6.5939L19.2759 10.9041C20.0582 11.6747 20.6194 12.6409 20.9012 13.7022Z"/>
      </g>
    </svg>
    <svg v-if="icon === 'cib'" :height="height" :width="width" :class="icon" viewBox="0 0 46 37" xmlns="http://www.w3.org/2000/svg">
      <path d="M25.8185 3.362H24.0226C24.0226 3.02859 23.8901 2.70882 23.6543 2.47306C23.4186 2.2373 23.0988 2.10485 22.7654 2.10485H3.24897C3.12281 2.1043 2.99815 2.13229 2.88432 2.18671C2.77049 2.24114 2.67045 2.32059 2.59166 2.41914L1.19263 1.29129C1.43955 0.983436 1.75261 0.735114 2.10859 0.564743C2.46456 0.394371 2.85433 0.30632 3.24897 0.307119H22.7618C23.1632 0.306647 23.5607 0.385328 23.9317 0.538661C24.3026 0.691994 24.6397 0.916969 24.9236 1.20071C25.2075 1.48445 25.4327 1.8214 25.5863 2.19226C25.7398 2.56312 25.8187 2.96061 25.8185 3.362Z"/>
      <path d="M45.2812 1.29126V30.0262L43.0093 30.1753L42.9142 28.7152L43.8121 28.6559V2.85552L23.1607 4.21504V4.29766C23.0691 4.22043 22.9811 4.145 22.8769 4.07316C22.5981 3.86779 22.2969 3.69486 21.9789 3.55773C21.8927 3.52002 21.7993 3.4841 21.7131 3.45177C21.7131 3.43381 21.7131 3.41585 21.7257 3.39789C21.7291 3.38448 21.7333 3.37128 21.7383 3.35838L21.7544 3.31528C21.7819 3.25072 21.8181 3.19027 21.8622 3.13569L21.8981 3.09258C21.9178 3.06927 21.9395 3.04765 21.9628 3.02793C21.9825 3.00997 22.0041 2.99381 22.0256 2.97764L22.0795 2.94172H22.0903C22.2457 2.84685 22.4237 2.79538 22.6057 2.79266L45.2812 1.29126Z"/>
      <path d="M43.4093 3.15906V31.3229H41.362V30.4249H42.506V4.05343L22.8728 3.97261V4.07319C22.5941 3.86781 22.2928 3.69489 21.9749 3.55775V3.32428L22.1544 3.2291C22.1836 3.20537 22.2149 3.18433 22.2478 3.16624C22.3677 3.09929 22.504 3.06754 22.6411 3.07465L43.4093 3.15906Z"/>
      <path d="M38.246 4.03547L22.7004 2.81065C22.4872 2.78586 22.2715 2.82924 22.0844 2.93457H22.0736L22.0198 2.97048C21.9982 2.98665 21.9767 3.00281 21.9569 3.02077L21.9461 3.02975L21.8923 3.08542L21.8563 3.12853C21.8123 3.18312 21.776 3.24356 21.7486 3.30812L21.7324 3.35122C21.7274 3.36412 21.7232 3.37732 21.7198 3.39073C21.7198 3.40869 21.7109 3.42665 21.7073 3.44461C21.7971 3.47694 21.8869 3.51286 21.9731 3.55057C22.291 3.6877 22.5923 3.86063 22.871 4.066C22.968 4.13784 23.0632 4.21327 23.1548 4.2905V4.31025L40.631 5.68773V31.4899L35.4587 31.0823C34.9947 31.5641 34.5004 32.0158 33.9789 32.4346L42.0947 33.0739V4.33899L38.246 4.03547Z"/>
      <path d="M24.3728 5.87091C24.5979 6.32482 24.7443 6.81369 24.8056 7.31664C28.1597 7.45431 31.331 8.88245 33.6571 11.3028C35.9832 13.7232 37.2843 16.9486 37.2887 20.3056C37.2931 23.6625 36.0005 26.8913 33.6807 29.3178C31.361 31.7442 28.1935 33.1807 24.8397 33.3272V34.1748C24.8399 34.3726 24.8255 34.5701 24.7966 34.7657C28.5768 34.634 32.1546 33.0256 34.7622 30.2856C37.3698 27.5457 38.7993 23.8926 38.7438 20.1106C38.6883 16.3286 37.1523 12.719 34.4655 10.0568C31.7787 7.39448 28.1552 5.89167 24.3728 5.87091Z"/>
      <path d="M24.8396 15.0266V15.9246C25.9065 16.063 26.8866 16.5851 27.5967 17.3933C28.3069 18.2016 28.6985 19.2406 28.6985 20.3165C28.6985 21.3924 28.3069 22.4315 27.5967 23.2397C26.8866 24.048 25.9065 24.57 24.8396 24.7085V25.6065C26.1467 25.4659 27.3556 24.8472 28.2341 23.8692C29.1126 22.8912 29.5985 21.623 29.5985 20.3084C29.5985 18.9938 29.1126 17.7257 28.2341 16.7477C27.3556 15.7697 26.1467 15.151 24.8396 15.0104V15.0266Z"/>
      <path d="M24.8396 17.5409V18.6562C25.1876 18.7749 25.4898 18.9994 25.7038 19.2985C25.9177 19.5975 26.0328 19.956 26.0328 20.3237C26.0328 20.6914 25.9177 21.0499 25.7038 21.3489C25.4898 21.648 25.1876 21.8726 24.8396 21.9912V23.1065C25.4812 22.9755 26.0578 22.6268 26.4719 22.1196C26.886 21.6123 27.1122 20.9776 27.1122 20.3228C27.1122 19.668 26.886 19.0333 26.4719 18.526C26.0578 18.0188 25.4812 17.6701 24.8396 17.5391V17.5409Z"/>
      <path d="M21.043 36.33C20.8623 36.33 20.6819 36.3132 20.5043 36.2797L3.17707 33.0452C2.38397 32.8794 1.67025 32.4502 1.15185 31.8275C0.633451 31.2047 0.340835 30.425 0.321533 29.615V3.2327C0.3229 2.84289 0.409876 2.45814 0.576313 2.10564C0.74275 1.75314 0.984585 1.44151 1.28472 1.19276C1.58486 0.944018 1.93597 0.764238 2.31324 0.666131C2.69051 0.568024 3.08472 0.553985 3.46801 0.625007L20.8257 3.86128C21.6194 4.02773 22.3334 4.45783 22.8515 5.08162C23.3696 5.70542 23.6615 6.48619 23.6795 7.29691V33.6361C23.6881 34.0343 23.6083 34.4294 23.4459 34.7931C23.2835 35.1567 23.0425 35.4798 22.7402 35.7391C22.2608 36.1281 21.6604 36.3371 21.043 36.33ZM2.97952 2.37604C2.7521 2.37697 2.53419 2.46736 2.37288 2.62766C2.21157 2.78796 2.11983 3.0053 2.11747 3.2327V29.615C2.13795 30.0058 2.28418 30.3796 2.53434 30.6806C2.78451 30.9815 3.12523 31.1936 3.50573 31.2852L20.8329 34.5179C20.9631 34.5545 21.1002 34.5589 21.2325 34.5308C21.3648 34.5027 21.4883 34.4429 21.5924 34.3565C21.6964 34.2701 21.7779 34.1597 21.8299 34.0349C21.8819 33.91 21.9027 33.7744 21.8907 33.6397V7.29691C21.8699 6.90616 21.7236 6.53255 21.4735 6.23163C21.2234 5.93071 20.8828 5.71855 20.5025 5.62669L3.13756 2.394C3.08548 2.38357 3.03261 2.37756 2.97952 2.37604Z"/>
      <path d="M1.32248 4.27663L1.125 5.33594L22.689 9.35596L22.8865 8.29665L1.32248 4.27663Z"/>
      <path d="M1.32004 27.5579L1.12256 28.6172L22.6778 32.6356L22.8753 31.5763L1.32004 27.5579Z"/>
      <path opacity="0.3" d="M1.42969 4.8652L22.4745 8.77316L22.9971 32.106L1.88945 28.3219L1.42969 4.8652Z"/>
    </svg>
    <svg v-if="icon === 'new'" :height="height" :width="width" viewBox="0 0 30 34" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_4573_7170)">
        <path d="M17.1124 4.86942H1.31079V5.9468H17.1124V4.86942Z"/>
        <path d="M24.2267 28.0375H1.31079V29.1149H24.2267V28.0375Z"/>
        <path d="M25.5124 14.6767V30.335C25.5124 32.075 24.0957 33.4917 22.3539 33.4917H3.15673C1.41496 33.4917 0 32.075 0 30.335V3.87462C0 2.13285 1.41496 0.716095 3.15673 0.716095H21.9984L21.1401 2.51173H3.15673C2.40436 2.51173 1.79564 3.12405 1.79564 3.87462V30.3352C1.79564 31.0857 2.40436 31.6963 3.15673 31.6963H22.3539C23.1063 31.6963 23.7166 31.0857 23.7166 30.3352V14.6768L25.5124 14.6767Z"/>
        <path d="M29.69 6.42121C29.69 6.24883 29.5661 6.0962 29.3883 6.04951C28.9035 5.92022 28.4276 5.75862 28.108 5.56648C27.2084 5.026 26.4363 4.24489 25.8258 3.26627C25.2907 2.40795 24.8777 1.3988 24.6101 0.265755C24.5724 0.109534 24.4252 0 24.2528 0C24.0822 0 23.9332 0.109534 23.8972 0.265755C23.6297 1.3988 23.2167 2.40795 22.6816 3.26627C22.0693 4.24489 21.299 5.026 20.3975 5.56648C20.0779 5.75862 19.6039 5.92022 19.1191 6.04951C18.9395 6.0962 18.8174 6.24883 18.8174 6.42121C18.8174 6.59359 18.9395 6.74622 19.1191 6.7929C19.6039 6.92219 20.0779 7.0838 20.3975 7.27593C21.299 7.81642 22.0693 8.59752 22.6816 9.57615C23.2167 10.4345 23.6297 11.4436 23.8972 12.5767C23.9332 12.7329 24.0822 12.8424 24.2528 12.8424C24.4252 12.8424 24.5724 12.7329 24.6101 12.5767C24.8777 11.4436 25.2907 10.4345 25.8258 9.57615C26.4363 8.59752 27.2084 7.81642 28.108 7.27593C28.4276 7.0838 28.9035 6.92219 29.3883 6.7929C29.5661 6.74622 29.69 6.59359 29.69 6.42121Z"/>
        <path opacity="0.4" d="M23.7166 14.6767V28.576H1.31079V5.52339H17.1117C17.1117 6.42121 17.1117 5.52339 17.1117 6.42121C17.1117 7.35853 17.7527 8.18812 18.6703 8.43951H18.6757L18.6793 8.44131C19.2701 8.59932 19.4819 8.71604 19.5197 8.73759C20.184 9.13622 20.7604 9.7216 21.2363 10.4812C21.678 11.1904 22.0156 12.0272 22.2365 12.9681L22.24 12.9789L22.2418 12.9915L22.2454 13.0004C22.4214 13.7349 23.1097 14.6767 23.7166 14.6767Z"/>
      </g>
      <defs>
        <clipPath id="clip0_4573_7170">
          <rect width="29.69" height="33.4919"/>
        </clipPath>
      </defs>
    </svg>
    <svg v-if="icon === 'manual'" :width="width" :height="height" :class="icon" viewBox="0 0 25 33" xmlns="http://www.w3.org/2000/svg">
      <path d="M24.2339 28.847H21.8992V27.3833H22.772V1.5793H2.14566V28.1142H0.680176V0.115616H24.2339V28.847Z"/>
      <path d="M22.3481 30.3681L20.2576 30.1867L20.333 29.2923L21.4501 29.3893V3.0214L1.86183 1.33682V28.1142H0.963867V0.358032L22.3481 2.19887V30.3681Z"/>
      <path d="M21.0265 32.5227L0.682129 28.7225V0.973542C0.68265 0.851087 0.710094 0.730245 0.762518 0.619578C0.814942 0.508911 0.891063 0.411127 0.985486 0.333155C1.07991 0.255183 1.19033 0.198931 1.30891 0.168384C1.42749 0.137837 1.55135 0.133742 1.67169 0.156391L21.0265 3.76802V32.5227ZM2.14582 27.5067L19.5664 30.7609V4.98387L2.14582 1.72963V27.5067Z"/>
    </svg>
    <svg v-if="icon === 'box'" :width="width" :height="height" :class="icon" viewBox="0 0 26 37" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.8361 36.3713C20.6553 36.3714 20.4749 36.3546 20.2973 36.321L2.97009 33.0883C2.17835 32.9206 1.46646 32.4904 0.949793 31.8675C0.433129 31.2446 0.141979 30.4654 0.123535 29.6563V3.27939C0.123724 2.88983 0.20954 2.50508 0.374909 2.15236C0.540278 1.79964 0.781156 1.48759 1.08049 1.23828C1.37983 0.988976 1.73031 0.808515 2.10712 0.709675C2.48393 0.610834 2.87787 0.596029 3.26103 0.666307L20.6259 3.89899C21.4199 4.06508 22.1343 4.49503 22.6528 5.11886C23.1713 5.74268 23.4634 6.52365 23.4815 7.33462V33.6756C23.4873 34.0737 23.4054 34.4682 23.2415 34.8311C23.0776 35.194 22.8358 35.5162 22.5332 35.775C22.0546 36.1661 21.4541 36.377 20.8361 36.3713ZM2.77254 2.41734C2.54547 2.41971 2.3285 2.51158 2.16878 2.67299C2.00905 2.8344 1.91946 3.05231 1.91947 3.27939V29.6563C1.93996 30.0471 2.08618 30.4209 2.33634 30.7219C2.58651 31.0228 2.92723 31.2349 3.30773 31.3265L20.6331 34.5592C20.7636 34.5958 20.9009 34.6002 21.0334 34.5719C21.1659 34.5437 21.2896 34.4838 21.3938 34.3972C21.498 34.3107 21.5797 34.2001 21.6317 34.075C21.6838 33.95 21.7047 33.8141 21.6927 33.6792V7.33821C21.6719 6.94746 21.5256 6.57385 21.2755 6.27293C21.0254 5.97201 20.6848 5.75985 20.3045 5.66799L2.93956 2.4353C2.88467 2.42349 2.82869 2.41747 2.77254 2.41734Z"/>
      <path d="M2.27999 2.42809C2.51799 2.19678 2.83709 2.06785 3.16898 2.06891L22.5812 2.09225C22.9147 2.09225 23.2344 2.2247 23.4702 2.46047C23.7059 2.69623 23.8384 3.01599 23.8384 3.34941V29.685C23.8384 30.0184 23.7059 30.3382 23.4702 30.5739C23.2344 30.8097 22.9147 30.9422 22.5812 30.9422V32.7381C23.391 32.7381 24.1675 32.4164 24.7401 31.8439C25.3127 31.2713 25.6343 30.4947 25.6343 29.685V3.34223C25.6343 2.5325 25.3127 1.75593 24.7401 1.18336C24.1675 0.610799 23.391 0.289135 22.5812 0.289135L3.17796 0.265788C2.42837 0.258739 1.70337 0.532987 1.14612 1.03438C0.588861 1.53578 0.239842 2.22789 0.167969 2.97406L2.27999 2.42809Z"/>
      <path d="M1.11765 4.31752L0.920166 5.37683L22.4842 9.39685L22.6817 8.33754L1.11765 4.31752Z"/>
      <path d="M1.1113 27.5985L0.913818 28.6578L22.469 32.6762L22.6665 31.6168L1.1113 27.5985Z"/>
      <path opacity="0.3" d="M1.02148 4.8652L22.0555 8.77316L22.5727 32.106L1.47047 28.3219L1.02148 4.8652Z"/>
    </svg>
    <svg v-if="icon === 'metacritic'" :width="width" :height="height" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.99988 14C5.14401 13.9979 3.36475 13.2597 2.05245 11.9474C0.74015 10.6351 0.00197768 8.85587 -0.00012207 7C0.00197768 5.14413 0.74015 3.36487 2.05245 2.05257C3.36475 0.740271 5.14401 0.00209975 6.99988 0C8.85575 0.00209975 10.635 0.740271 11.9473 2.05257C13.2596 3.36487 13.9978 5.14413 13.9999 7C13.9978 8.85587 13.2596 10.6351 11.9473 11.9474C10.635 13.2597 8.85575 13.9979 6.99988 14ZM6.99988 1.4C5.51519 1.40169 4.09179 1.99223 3.04195 3.04207C1.99211 4.09191 1.40157 5.51531 1.39988 7C1.40157 8.48469 1.99211 9.90809 3.04195 10.9579C4.09179 12.0078 5.51519 12.5983 6.99988 12.6C8.48457 12.5983 9.90797 12.0078 10.9578 10.9579C12.0076 9.90809 12.5982 8.48469 12.5999 7C12.5982 5.51531 12.0076 4.09191 10.9578 3.04207C9.90797 1.99223 8.48457 1.40169 6.99988 1.4Z" fill="#F2F2F2"/>
      <path d="M7.16323 4.52668C7.0408 4.65514 6.96152 4.8186 6.93642 4.99428C6.91132 5.16995 6.94167 5.34908 7.02323 5.50668C7.10272 5.62671 7.19677 5.73644 7.30323 5.83335L9.5899 8.12001L8.42323 9.28668L6.27656 7.14001C5.8099 6.67335 5.29656 6.48668 4.87656 6.90668C4.75738 7.037 4.68026 7.20024 4.65528 7.37507C4.63031 7.5499 4.65863 7.7282 4.73656 7.88668C4.79842 8.03188 4.89449 8.15997 5.01656 8.26001L7.25656 10.5L6.0899 11.6667L3.38323 8.96001C2.91656 8.49334 2.49656 8.07334 2.12323 7.74668L3.1499 6.72001L3.7099 7.18668L3.75656 7.14001C3.67878 6.83265 3.68542 6.50999 3.77577 6.20608C3.86612 5.90218 4.03683 5.6283 4.2699 5.41334C4.47828 5.17227 4.75939 5.00549 5.07084 4.93815C5.3823 4.87081 5.7072 4.90656 5.99656 5.04001C5.97708 4.89456 5.96618 4.74808 5.9639 4.60135C5.96037 4.41776 5.98718 4.23486 6.04323 4.06001C6.14785 3.7065 6.34054 3.38535 6.60323 3.12668C7.3499 2.38001 8.42323 2.33335 9.5899 3.50001L11.8766 5.78668L10.7099 6.95335L8.6099 4.85335C8.0499 4.29335 7.53656 4.15335 7.16323 4.52668Z" fill="#F2F2F2"/>
    </svg>
    <svg v-if="icon === 'ebay'" :width="width" :height="height" viewBox="0 0 36 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.65472 3.27246C2.11551 3.27246 0 4.39241 0 7.77225C0 10.4496 1.42281 12.1364 4.72045 12.1364C8.60259 12.1364 8.85135 9.47689 8.85135 9.47689H6.97045C6.97045 9.47689 6.56697 10.9092 4.60618 10.9092C3.00843 10.9092 1.85966 9.78711 1.85966 8.21392H9.04753V7.22647C9.04753 5.67011 8.09798 3.27246 4.65472 3.27246V3.27246ZM4.58899 4.53333C6.10989 4.53333 7.14641 5.50185 7.14641 6.95411H1.90112C1.90112 5.41246 3.25416 4.53333 4.58899 4.53333V4.53333Z" fill="#E53238"/>
      <path d="M9.02637 0V11.8631H10.8001C10.8001 11.8631 10.8648 11.2658 10.8648 10.7189C10.8648 10.7189 11.7506 12.1607 14.1614 12.1607C16.6996 12.1607 18.4228 10.3288 18.4228 7.70296C18.4228 5.26115 16.8392 3.29676 14.1655 3.29676C11.6616 3.29676 10.883 4.70275 10.883 4.70275V0H9.02637V0ZM13.7013 4.58918C15.4245 4.58918 16.5206 5.91945 16.5206 7.70401C16.5206 9.61792 15.2546 10.8704 13.7134 10.8704C11.874 10.8704 10.882 9.3771 10.882 7.72084C10.882 6.17814 11.7719 4.58918 13.7013 4.58918V4.58918Z" fill="#0064D2"/>
      <path d="M22.8803 3.27254C19.0599 3.27254 18.8152 5.44724 18.8152 5.79532H20.7163C20.7163 5.79532 20.8164 4.52499 22.7489 4.52499C24.0048 4.52499 24.9776 5.1223 24.9776 6.27169V6.68077H22.7489C19.789 6.68077 18.2246 7.58093 18.2246 9.40756C18.2246 11.2058 19.6707 12.1838 21.6234 12.1838C24.286 12.1838 25.1435 10.6537 25.1435 10.6537C25.1435 11.2626 25.189 11.862 25.189 11.862H26.8798C26.8798 11.862 26.814 11.1185 26.814 10.6432V6.53354C26.814 3.8383 24.7238 3.27148 22.8803 3.27148V3.27254ZM24.9787 7.90903V8.45481C24.9787 9.16675 24.557 10.9345 22.0714 10.9345C20.7102 10.9345 20.1267 10.2278 20.1267 9.40861C20.1267 7.9185 22.0916 7.90903 24.9787 7.90903V7.90903Z" fill="#F5AF02"/>
      <path d="M25.791 3.61426H27.9298L30.9999 10.0101L34.0629 3.61426H36.0005L30.4215 15.001H28.3889L29.9988 11.8272L25.791 3.61531V3.61426Z" fill="#86B817"/>
    </svg>
    <svg v-if="icon === 'amazon'" :width="width" :height="height" viewBox="0 0 30 31" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_3672_11949)">
        <path d="M26.3143 25.5192C13.5533 31.6081 5.63425 26.5139 0.564668 23.4188C0.250634 23.2235 -0.282494 23.4639 0.180037 23.9973C1.86949 26.0501 7.40404 31.0003 14.628 31.0003C21.852 31.0003 26.1585 27.0448 26.6965 26.3552C27.2308 25.6705 26.8535 25.2934 26.313 25.5192H26.3143ZM29.8977 23.5347C29.5544 23.0868 27.8138 23.0038 26.7184 23.1393C25.6205 23.2699 23.9736 23.9424 24.1172 24.3463C24.1903 24.4977 24.3412 24.4293 25.0946 24.3622C25.8505 24.2865 27.9684 24.018 28.409 24.5965C28.8521 25.1787 27.7335 27.9528 27.529 28.4007C27.3318 28.8487 27.6045 28.9634 27.9757 28.6656C28.3409 28.3678 29.003 27.5965 29.4473 26.5041C29.8891 25.4069 30.1581 23.8752 29.8977 23.5347Z" fill="#FF9900"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.734 13.0932C17.734 14.6908 17.7741 16.0236 16.9684 17.4418C16.3184 18.5951 15.2887 19.3054 14.1384 19.3054C12.5682 19.3054 11.6529 18.1057 11.6529 16.3348C11.6529 12.8394 14.7762 12.2047 17.734 12.2047V13.0932ZM21.859 23.0889C21.5888 23.3306 21.1969 23.3489 20.8926 23.1865C19.5354 22.0564 19.2932 21.5316 18.5458 20.4527C16.3026 22.7484 14.7141 23.4343 11.8038 23.4343C8.36408 23.4343 5.68262 21.3058 5.68262 17.0427C5.68262 13.7144 7.48284 11.448 10.0426 10.3398C12.2627 9.35858 15.3641 9.1865 17.734 8.91555V8.38465C17.734 7.40949 17.8082 6.25614 17.2398 5.41402C16.7395 4.65854 15.784 4.34732 14.9442 4.34732C13.385 4.34732 11.9937 5.14917 11.6541 6.81024C11.5848 7.18004 11.3145 7.54252 10.9469 7.55961L6.9777 7.13244C6.64419 7.05677 6.27539 6.78583 6.36789 6.27323C7.282 1.45358 11.6249 0 15.5138 0C17.5039 0 20.1038 0.530905 21.6752 2.04185C23.6653 3.90551 23.4755 6.39039 23.4755 9.09618V15.4878C23.4755 17.4088 24.2691 18.2509 25.0176 19.2896C25.2818 19.6581 25.3402 20.1024 25.0067 20.3794C24.1729 21.0776 22.6879 22.3749 21.8712 23.1023L21.8602 23.0913" fill="white"/>
        <path d="M26.3143 25.5192C13.5533 31.6081 5.63425 26.5139 0.564668 23.4188C0.250634 23.2235 -0.282494 23.4639 0.180037 23.9973C1.86949 26.0501 7.40404 31.0003 14.628 31.0003C21.852 31.0003 26.1585 27.0448 26.6965 26.3552C27.2308 25.6705 26.8535 25.2934 26.313 25.5192H26.3143ZM29.8977 23.5347C29.5544 23.0868 27.8138 23.0038 26.7184 23.1393C25.6205 23.2699 23.9736 23.9424 24.1172 24.3463C24.1903 24.4977 24.3412 24.4293 25.0946 24.3622C25.8505 24.2865 27.9684 24.018 28.409 24.5965C28.8521 25.1787 27.7335 27.9528 27.529 28.4007C27.3318 28.8487 27.6045 28.9634 27.9757 28.6656C28.3409 28.3678 29.003 27.5965 29.4473 26.5041C29.8891 25.4069 30.1581 23.8752 29.8977 23.5347Z" fill="#FF9900"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.734 13.0932C17.734 14.6908 17.7741 16.0236 16.9684 17.4418C16.3184 18.5951 15.2887 19.3054 14.1384 19.3054C12.5682 19.3054 11.6529 18.1057 11.6529 16.3348C11.6529 12.8394 14.7762 12.2047 17.734 12.2047V13.0932ZM21.859 23.0889C21.5888 23.3306 21.1969 23.3489 20.8926 23.1865C19.5354 22.0564 19.2932 21.5316 18.5458 20.4527C16.3026 22.7484 14.7141 23.4343 11.8038 23.4343C8.36408 23.4343 5.68262 21.3058 5.68262 17.0427C5.68262 13.7144 7.48284 11.448 10.0426 10.3398C12.2627 9.35858 15.3641 9.1865 17.734 8.91555V8.38465C17.734 7.40949 17.8082 6.25614 17.2398 5.41402C16.7395 4.65854 15.784 4.34732 14.9442 4.34732C13.385 4.34732 11.9937 5.14917 11.6541 6.81024C11.5848 7.18004 11.3145 7.54252 10.9469 7.55961L6.9777 7.13244C6.64419 7.05677 6.27539 6.78583 6.36789 6.27323C7.282 1.45358 11.6249 0 15.5138 0C17.5039 0 20.1038 0.530905 21.6752 2.04185C23.6653 3.90551 23.4755 6.39039 23.4755 9.09618V15.4878C23.4755 17.4088 24.2691 18.2509 25.0176 19.2896C25.2818 19.6581 25.3402 20.1024 25.0067 20.3794C24.1729 21.0776 22.6879 22.3749 21.8712 23.1023L21.8602 23.0913" fill="white"/>
      </g>
      <defs>
        <clipPath id="clip0_3672_11949">
          <rect width="30" height="31" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <svg v-if="icon === 'pricecharting'" :width="width" :height="height" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1971_10351)">
        <path d="M6.48348 18.4355L2.24963 16.1675L0.533674 17.9404C-0.228768 13.9132 -0.169059 9.96412 0.680651 5.87541C1.70581 4.50945 4.30455 3.35384 4.8208 3.9592C4.97697 4.142 5.1414 3.82049 6.35947 4.17415C7.75667 4.58018 8.58984 4.33491 13.0129 3.02957C14.7794 2.50781 14.9319 1.50285 15.9267 1.06376C16.2547 0.919537 16.0801 0.553932 16.6653 0.357351C18.6871 -0.321498 19.8317 0.155258 20.4435 0.268246C21.1463 0.397769 20.9552 0.694479 21.2445 0.971897C22.7694 2.43615 24.23 5.01835 25.2074 6.61489L22.4424 10.0551L16.1408 6.9465L6.48348 18.4355Z" fill="#008CE8"/>
        <path d="M1.96396 18.7239C2.07694 18.5953 2.20096 18.4539 2.33875 18.2949L6.64333 20.7008L16.5725 9.09878C16.6258 9.12634 21.9638 11.8362 22.5958 12.1596L26.0112 8.27571C26.9913 10.4914 28.7413 14.5103 27.6583 16.6773C26.9032 18.1884 23.9645 17.4792 22.4415 16.6828C20.2387 15.5309 18.2747 14.3504 11.9887 16.7857C7.05946 18.6954 8.44288 21.6993 4.53329 23.519C2.69241 24.3761 1.55426 22.143 0.979211 20.1955C0.951652 20.1018 0.925013 20.0091 0.899292 19.9154C0.899292 19.9154 1.61213 19.1244 1.96396 18.7239Z" fill="#7EA544"/>
      </g>
      <defs>
        <clipPath id="clip0_1971_10351">
          <rect width="28" height="23.7074" fill="white"/>
        </clipPath>
      </defs>
    </svg>
    <svg v-if="icon === 'facebook'" :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M12,2C6.477,2,2,6.477,2,12c0,5.013,3.693,9.153,8.505,9.876V14.65H8.031v-2.629h2.474v-1.749 c0-2.896,1.411-4.167,3.818-4.167c1.153,0,1.762,0.085,2.051,0.124v2.294h-1.642c-1.022,0-1.379,0.969-1.379,2.061v1.437h2.995 l-0.406,2.629h-2.588v7.247C18.235,21.236,22,17.062,22,12C22,6.477,17.523,2,12,2z"/>
    </svg>
    <svg v-if="icon === 'twitter'" :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M22,3.999c-0.78,0.463-2.345,1.094-3.265,1.276c-0.027,0.007-0.049,0.016-0.075,0.023c-0.813-0.802-1.927-1.299-3.16-1.299 c-2.485,0-4.5,2.015-4.5,4.5c0,0.131-0.011,0.372,0,0.5c-3.353,0-5.905-1.756-7.735-4c-0.199,0.5-0.286,1.29-0.286,2.032 c0,1.401,1.095,2.777,2.8,3.63c-0.314,0.081-0.66,0.139-1.02,0.139c-0.581,0-1.196-0.153-1.759-0.617c0,0.017,0,0.033,0,0.051 c0,1.958,2.078,3.291,3.926,3.662c-0.375,0.221-1.131,0.243-1.5,0.243c-0.26,0-1.18-0.119-1.426-0.165 c0.514,1.605,2.368,2.507,4.135,2.539c-1.382,1.084-2.341,1.486-5.171,1.486H2C3.788,19.145,6.065,20,8.347,20 C15.777,20,20,14.337,20,8.999c0-0.086-0.002-0.266-0.005-0.447C19.995,8.534,20,8.517,20,8.499c0-0.027-0.008-0.053-0.008-0.08 c-0.003-0.136-0.006-0.263-0.009-0.329c0.79-0.57,1.475-1.281,2.017-2.091c-0.725,0.322-1.503,0.538-2.32,0.636 C20.514,6.135,21.699,4.943,22,3.999z"/>
    </svg>
    <svg v-if="icon === 'instagram'" :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M 8 3 C 5.239 3 3 5.239 3 8 L 3 16 C 3 18.761 5.239 21 8 21 L 16 21 C 18.761 21 21 18.761 21 16 L 21 8 C 21 5.239 18.761 3 16 3 L 8 3 z M 18 5 C 18.552 5 19 5.448 19 6 C 19 6.552 18.552 7 18 7 C 17.448 7 17 6.552 17 6 C 17 5.448 17.448 5 18 5 z M 12 7 C 14.761 7 17 9.239 17 12 C 17 14.761 14.761 17 12 17 C 9.239 17 7 14.761 7 12 C 7 9.239 9.239 7 12 7 z M 12 9 A 3 3 0 0 0 9 12 A 3 3 0 0 0 12 15 A 3 3 0 0 0 15 12 A 3 3 0 0 0 12 9 z"/>
    </svg>
    <svg v-if="icon === 'discord'" :width="width" :height="height" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.952,5.672c-1.904-1.531-4.916-1.79-5.044-1.801c-0.201-0.017-0.392,0.097-0.474,0.281 c-0.006,0.012-0.072,0.163-0.145,0.398c1.259,0.212,2.806,0.64,4.206,1.509c0.224,0.139,0.293,0.434,0.154,0.659 c-0.09,0.146-0.247,0.226-0.407,0.226c-0.086,0-0.173-0.023-0.252-0.072C15.584,5.38,12.578,5.305,12,5.305S8.415,5.38,6.011,6.872 c-0.225,0.14-0.519,0.07-0.659-0.154c-0.14-0.225-0.07-0.519,0.154-0.659c1.4-0.868,2.946-1.297,4.206-1.509 c-0.074-0.236-0.14-0.386-0.145-0.398C9.484,3.968,9.294,3.852,9.092,3.872c-0.127,0.01-3.139,0.269-5.069,1.822 C3.015,6.625,1,12.073,1,16.783c0,0.083,0.022,0.165,0.063,0.237c1.391,2.443,5.185,3.083,6.05,3.111c0.005,0,0.01,0,0.015,0 c0.153,0,0.297-0.073,0.387-0.197l0.875-1.202c-2.359-0.61-3.564-1.645-3.634-1.706c-0.198-0.175-0.217-0.477-0.042-0.675 c0.175-0.198,0.476-0.217,0.674-0.043c0.029,0.026,2.248,1.909,6.612,1.909c4.372,0,6.591-1.891,6.613-1.91 c0.198-0.172,0.5-0.154,0.674,0.045c0.174,0.198,0.155,0.499-0.042,0.673c-0.07,0.062-1.275,1.096-3.634,1.706l0.875,1.202 c0.09,0.124,0.234,0.197,0.387,0.197c0.005,0,0.01,0,0.015,0c0.865-0.027,4.659-0.667,6.05-3.111 C22.978,16.947,23,16.866,23,16.783C23,12.073,20.985,6.625,19.952,5.672z M8.891,14.87c-0.924,0-1.674-0.857-1.674-1.913 s0.749-1.913,1.674-1.913s1.674,0.857,1.674,1.913S9.816,14.87,8.891,14.87z M15.109,14.87c-0.924,0-1.674-0.857-1.674-1.913 s0.749-1.913,1.674-1.913c0.924,0,1.674,0.857,1.674,1.913S16.033,14.87,15.109,14.87z"/>
    </svg>
    <svg v-if="icon === 'logo-earlyaccess'" :width="width" :height="height" viewBox="0 0 347 75" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M103.86 35.7911H111.567V38.4859C109.193 41.6614 105.506 43.633 101.542 43.7148C99.9854 43.7463 98.4472 43.3876 97.0409 42.7175C87.8202 38.3209 88.2176 23.3289 96.6636 19.0844C101.407 16.701 108.202 18.5119 110.866 23.9344L116.418 19.8392C113.544 14.1784 107.718 11.4262 101.38 11.4851C87.4485 11.6171 79.2264 25.7811 84.1611 38.4056C88.9939 50.7717 103.742 53.9873 111.567 45.7079V50.0198H117.711V30.3471H103.86V35.7911ZM144.421 11.7534L129.816 50.0198H137.47L140.974 40.4805H154.339L157.896 50.0198H165.55L150.998 11.7534H144.421ZM142.211 35.2516L147.708 20.2137L152.99 35.2516H142.211ZM197.718 33.5268L186.399 11.7534H178.422V50.0198H185.861V24.6877L195.725 43.5526H199.767L209.629 24.6877V50.0198H217.068V11.7534H209.145L197.721 33.5268H197.718ZM241.671 33.5268H257.785V27.4901H241.671V18.2737H260.317V11.7534H234.232V50.0198H260.802V43.4981H241.671V33.5268ZM289.858 30.1849L280.426 11.7534H272.342L286.193 36.5445V50.0184H293.577V36.6521L307.48 11.7534H299.452L289.858 30.1849ZM327.767 43.4981V33.5268H343.881V27.4901H327.767V18.2737H346.414V11.7534H320.329V50.0198H346.899V43.4981H327.767Z" fill="white"/>
      <path d="M30.9486 2.57141C15.3324 2.57141 2.67212 15.2317 2.67212 30.8479C2.67212 46.4641 15.3324 59.1259 30.9501 59.1259C46.5677 59.1259 59.228 46.4656 59.228 30.8479C59.228 15.2303 46.5663 2.57141 30.9486 2.57141Z" fill="white"/>
      <path d="M30.9487 4.48269C23.9061 4.48269 17.2854 7.22481 12.3048 12.2054C7.32421 17.186 4.58208 23.8067 4.58208 30.8493C4.58208 37.8919 7.32421 44.5126 12.3048 49.4932C17.2854 54.4738 23.9061 57.2159 30.9487 57.2159C37.9913 57.2159 44.612 54.4738 49.5926 49.4932C54.5732 44.5126 57.3153 37.8919 57.3153 30.8493C57.3153 23.8067 54.5732 17.186 49.5926 12.2054C44.612 7.22481 37.9913 4.48269 30.9487 4.48269ZM30.9487 0C47.9855 0 61.7966 13.8111 61.7966 30.8479C61.7966 47.8847 47.9855 61.6958 30.9487 61.6958C13.9119 61.6958 0.10083 47.8861 0.10083 30.8479C0.10083 13.8097 13.9119 0 30.9487 0Z" fill="#007AFF"/>
      <path d="M44.513 22.3789H42.6491V26.4082H38.6127V30.4374H34.5834V34.4739H30.5542V38.5031H26.5249V42.5324H22.4885V44.4365C17.6514 41.6542 14.397 36.434 14.397 30.4518C14.397 28.793 14.6438 27.1974 15.1101 25.6965C16.1246 26.1628 17.2482 26.4154 18.4334 26.4154C22.8946 26.4154 26.5048 22.8051 26.5048 18.3439C26.5048 17.1587 26.2508 16.0352 25.7917 15.0279C27.2926 14.5615 28.8882 14.3147 30.5398 14.3147C36.5148 14.3147 41.7221 17.5562 44.5102 22.3789H44.513Z" fill="#007AFF"/>
      <path d="M42.6504 30.436H38.614V34.4725H42.6504V30.436Z" fill="#007AFF"/>
      <path d="M46.6784 34.4724H42.6492V38.5017H46.6784V34.4724Z" fill="#007AFF"/>
      <path d="M38.6142 34.4724H34.585V38.5017H38.6142V34.4724Z" fill="#007AFF"/>
      <path d="M38.6142 42.5295H34.585V46.5588H38.6142V42.5295Z" fill="#007AFF"/>
      <path d="M34.5849 38.5002H30.5557V42.5295H34.5849V38.5002Z" fill="#007AFF"/>
      <path d="M26.5278 42.5295V46.0852C27.528 46.3392 28.5625 46.5028 29.6244 46.5573C29.9257 46.5774 30.2342 46.5846 30.5427 46.5846H30.5571V42.5281L26.5278 42.5295Z" fill="#007AFF"/>
      <path d="M42.6504 38.5002H38.614V42.5295H42.6504V38.5002Z" fill="#007AFF"/>
      <path d="M34.5849 38.5002H30.5557V42.5295H34.5849V38.5002Z" fill="#007AFF"/>
      <path d="M42.6504 30.436H38.614V34.4725H42.6504V30.436Z" fill="#007AFF"/>
      <path d="M46.6784 30.436H42.6492V26.4067H46.1647C46.5005 27.6953 46.6784 29.0441 46.6784 30.436Z" fill="#007AFF"/>
      <path d="M96.6179 72.7187V74.0963H88.3184V61.8708H96.463V63.2483H89.8681V67.2087H95.602V68.5001H89.8681V72.7187H96.6179Z" fill="#FFCC00"/>
      <path d="M115.481 61.8708H116.79L121.784 74.0963H120.131L118.684 70.5147H113.553L112.124 74.0963H110.471L115.481 61.8708ZM118.34 69.3094L116.136 63.6615L113.863 69.3094H118.34Z" fill="#FFCC00"/>
      <path d="M136.446 74.0963V61.8708H141.679C142.22 61.8708 142.716 61.9827 143.17 62.2065C143.622 62.4304 144.013 62.7288 144.339 63.1019C144.666 63.475 144.92 63.894 145.105 64.3589C145.289 64.8238 145.379 65.2973 145.379 65.7795C145.379 66.2042 145.321 66.6146 145.2 67.0106C145.079 67.4067 144.906 67.7654 144.683 68.0868C144.46 68.4083 144.188 68.6838 143.876 68.9133C143.558 69.1429 143.205 69.3094 142.817 69.4127L145.777 74.0963H144.02L141.231 69.7054H137.994V74.0963H136.446ZM137.995 68.3279H141.697C142.019 68.3279 142.309 68.259 142.567 68.1213C142.825 67.9835 143.046 67.797 143.228 67.5616C143.413 67.3263 143.557 67.0537 143.66 66.7437C143.764 66.4338 143.814 66.1124 143.814 65.7795C143.814 65.4351 143.754 65.1108 143.633 64.8066C143.513 64.5024 143.352 64.2326 143.151 63.9973C142.95 63.762 142.715 63.5783 142.446 63.4463C142.177 63.3143 141.891 63.2483 141.593 63.2483H137.994L137.995 68.3279Z" fill="#FFCC00"/>
      <path d="M160.785 74.0963V61.8708H162.334V72.7187H169.153V74.0963H160.785Z" fill="#FFCC00"/>
      <path d="M182.456 61.8708L186.157 68.2418L189.893 61.8708H191.582L186.933 69.6365V74.0963H185.383V69.6021L180.75 61.8708H182.456Z" fill="#FFCC00"/>
      <path d="M221 61.8708H222.308L227.3 74.0963H225.649L224.202 70.5147H219.071L217.642 74.0963H215.99L221 61.8708ZM223.858 69.3094L221.654 63.6615L219.381 69.3094H223.858Z" fill="#FFCC00"/>
      <path d="M240.725 67.8974C240.725 67.1627 240.855 66.4309 241.113 65.702C241.371 64.9731 241.75 64.3187 242.249 63.739C242.75 63.1593 243.36 62.6915 244.083 62.3357C244.806 61.9798 245.627 61.8019 246.545 61.8019C247.637 61.8019 248.572 62.0487 249.352 62.5423C250.133 63.0359 250.712 63.6788 251.091 64.4708L249.867 65.2629C249.685 64.8726 249.464 64.5455 249.206 64.2814C248.947 64.0174 248.665 63.805 248.362 63.6443C248.056 63.4836 247.744 63.3688 247.424 63.2999C247.101 63.2311 246.786 63.1966 246.477 63.1966C245.798 63.1966 245.198 63.3372 244.677 63.6185C244.155 63.8997 243.716 64.2671 243.36 64.7205C243.004 65.1739 242.737 65.6848 242.558 66.253C242.381 66.8212 242.291 67.3923 242.291 67.9663C242.291 68.6091 242.397 69.2204 242.611 69.8001C242.822 70.3798 243.119 70.8935 243.496 71.3412C243.874 71.7889 244.323 72.1448 244.839 72.4088C245.356 72.6728 245.918 72.8048 246.528 72.8048C246.85 72.8048 247.18 72.7647 247.518 72.6843C247.855 72.604 248.18 72.4748 248.491 72.2969C248.801 72.1189 249.091 71.8951 249.359 71.6253C249.629 71.3556 249.85 71.0313 250.024 70.6525L251.314 71.3584C251.118 71.8176 250.846 72.2223 250.496 72.5724C250.146 72.9225 249.748 73.2181 249.309 73.4592C248.866 73.7002 248.398 73.8839 247.904 74.0102C247.411 74.1364 246.929 74.1996 246.458 74.1996C245.62 74.1996 244.849 74.0173 244.151 73.6486C243.452 73.2812 242.845 72.802 242.332 72.2108C241.823 71.6196 241.427 70.9481 241.146 70.1962C240.865 69.4443 240.724 68.678 240.724 67.8974H240.725Z" fill="#FFCC00"/>
      <path d="M265.357 67.8974C265.357 67.1627 265.486 66.4309 265.745 65.702C266.004 64.9731 266.383 64.3187 266.882 63.739C267.382 63.1593 267.992 62.6915 268.715 62.3357C269.438 61.9798 270.259 61.8019 271.179 61.8019C272.269 61.8019 273.205 62.0487 273.984 62.5423C274.766 63.0359 275.346 63.6788 275.724 64.4708L274.5 65.2629C274.317 64.8726 274.096 64.5455 273.838 64.2814C273.579 64.0174 273.298 63.805 272.994 63.6443C272.69 63.4836 272.377 63.3688 272.055 63.2999C271.734 63.2311 271.42 63.1966 271.108 63.1966C270.431 63.1966 269.831 63.3372 269.309 63.6185C268.788 63.8997 268.348 64.2671 267.992 64.7205C267.636 65.1739 267.369 65.6848 267.191 66.253C267.013 66.8212 266.923 67.3923 266.923 67.9663C266.923 68.6091 267.029 69.2204 267.243 69.8001C267.455 70.3798 267.751 70.8935 268.128 71.3412C268.508 71.7889 268.955 72.1448 269.473 72.4088C269.991 72.6728 270.552 72.8048 271.161 72.8048C271.481 72.8048 271.811 72.7647 272.15 72.6843C272.489 72.604 272.813 72.4748 273.123 72.2969C273.434 72.1189 273.723 71.8951 273.991 71.6253C274.261 71.3556 274.483 71.0313 274.655 70.6525L275.947 71.3584C275.75 71.8176 275.479 72.2223 275.129 72.5724C274.779 72.9225 274.381 73.2181 273.941 73.4592C273.497 73.7002 273.03 73.8839 272.536 74.0102C272.042 74.1364 271.56 74.1996 271.09 74.1996C270.252 74.1996 269.483 74.0173 268.784 73.6486C268.084 73.2812 267.478 72.802 266.966 72.2108C266.453 71.6196 266.059 70.9481 265.779 70.1962C265.496 69.4443 265.356 68.678 265.356 67.8974H265.357Z" fill="#FFCC00"/>
      <path d="M299.22 72.7187V74.0963H290.92V61.8708H299.065V63.2483H292.47V67.2087H298.204V68.5001H292.47V72.7187H299.22Z" fill="#FFCC00"/>
      <path d="M321.543 64.4364C321.405 64.2757 321.22 64.1207 320.992 63.9715C320.762 63.8223 320.503 63.6874 320.217 63.5668C319.93 63.4463 319.617 63.3487 319.279 63.2741C318.94 63.1995 318.587 63.1622 318.22 63.1622C317.267 63.1622 316.571 63.3401 316.129 63.696C315.686 64.0518 315.465 64.5397 315.465 65.1596C315.465 65.4925 315.529 65.768 315.655 65.9861C315.782 66.2042 315.98 66.3908 316.249 66.5457C316.519 66.7007 316.855 66.8356 317.255 66.9504C317.657 67.0652 318.134 67.1857 318.685 67.312C319.314 67.4497 319.887 67.6076 320.398 67.7855C320.909 67.9634 321.345 68.1844 321.706 68.4484C322.068 68.7125 322.344 69.0339 322.532 69.4127C322.722 69.7915 322.817 70.2564 322.817 70.8074C322.817 71.3929 322.702 71.9008 322.473 72.3313C322.243 72.7618 321.929 73.1177 321.534 73.3989C321.14 73.6801 320.67 73.8868 320.129 74.0188C319.591 74.1508 319.006 74.2168 318.373 74.2168C317.433 74.2168 316.539 74.0704 315.696 73.7777C314.852 73.485 314.087 73.0574 313.397 72.4949L314.137 71.1863C314.321 71.3814 314.561 71.5766 314.852 71.7717C315.145 71.9669 315.477 72.1419 315.851 72.2969C316.224 72.4519 316.625 72.5781 317.056 72.6757C317.486 72.7733 317.937 72.8221 318.408 72.8221C319.279 72.8221 319.957 72.6671 320.439 72.3571C320.922 72.0472 321.163 71.5823 321.163 70.9624C321.163 70.618 321.084 70.3282 320.93 70.0928C320.777 69.8575 320.548 69.6538 320.249 69.4816C319.952 69.3094 319.58 69.1573 319.139 69.0253C318.696 68.8933 318.195 68.7584 317.631 68.6206C317.011 68.4714 316.466 68.3107 315.997 68.1385C315.526 67.9663 315.132 67.7597 314.817 67.5186C314.5 67.2775 314.262 66.9848 314.103 66.6404C313.942 66.2961 313.862 65.8828 313.862 65.4007C313.862 64.8152 313.971 64.2986 314.189 63.851C314.407 63.4033 314.711 63.0244 315.102 62.7145C315.492 62.4046 315.957 62.1721 316.498 62.0171C317.036 61.8622 317.621 61.7847 318.254 61.7847C319.081 61.7847 319.828 61.9109 320.492 62.1635C321.16 62.416 321.751 62.7547 322.266 63.1794L321.543 64.4364Z" fill="#FFCC00"/>
      <path d="M345.057 64.4364C344.919 64.2757 344.734 64.1207 344.504 63.9715C344.275 63.8223 344.017 63.6874 343.731 63.5668C343.443 63.4463 343.13 63.3487 342.793 63.2741C342.454 63.1995 342.101 63.1622 341.734 63.1622C340.781 63.1622 340.083 63.3401 339.641 63.696C339.2 64.0518 338.979 64.5397 338.979 65.1596C338.979 65.4925 339.042 65.768 339.168 65.9861C339.294 66.2042 339.492 66.3908 339.762 66.5457C340.032 66.7007 340.368 66.8356 340.769 66.9504C341.17 67.0652 341.646 67.1857 342.199 67.312C342.828 67.4497 343.401 67.6076 343.91 67.7855C344.421 67.9634 344.857 68.1844 345.219 68.4484C345.581 68.7125 345.856 69.0339 346.046 69.4127C346.236 69.7915 346.33 70.2564 346.33 70.8074C346.33 71.3929 346.215 71.9008 345.985 72.3313C345.756 72.7618 345.443 73.1177 345.048 73.3989C344.652 73.6801 344.183 73.8868 343.643 74.0188C343.104 74.1508 342.518 74.2168 341.886 74.2168C340.946 74.2168 340.053 74.0704 339.21 73.7777C338.366 73.485 337.6 73.0574 336.911 72.4949L337.651 71.1863C337.834 71.3814 338.073 71.5766 338.366 71.7717C338.657 71.9669 338.991 72.1419 339.363 72.2969C339.735 72.4519 340.139 72.5781 340.568 72.6757C341 72.7733 341.449 72.8221 341.92 72.8221C342.793 72.8221 343.47 72.6671 343.952 72.3571C344.434 72.0472 344.675 71.5823 344.675 70.9624C344.675 70.618 344.598 70.3282 344.444 70.0928C344.289 69.8575 344.061 69.6538 343.763 69.4816C343.464 69.3094 343.092 69.1573 342.653 69.0253C342.21 68.8933 341.709 68.7584 341.145 68.6206C340.525 68.4714 339.98 68.3107 339.509 68.1385C339.039 67.9663 338.644 67.7597 338.33 67.5186C338.016 67.2775 337.776 66.9848 337.615 66.6404C337.455 66.2961 337.374 65.8828 337.374 65.4007C337.374 64.8152 337.483 64.2986 337.701 63.851C337.92 63.4033 338.225 63.0244 338.616 62.7145C339.004 62.4046 339.471 62.1721 340.01 62.0171C340.55 61.8622 341.135 61.7847 341.767 61.7847C342.593 61.7847 343.341 61.9109 344.007 62.1635C344.672 62.416 345.263 62.7547 345.78 63.1794L345.057 64.4364Z" fill="#FFCC00"/>
    </svg>
  </span>
</template>

<script>
export default {
  name: "IconSet",
  components: {},
  props: {
    icon: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: '25px'
    },
    width: {
      type: String
    }
  },
  computed: {},
  methods: {}
};
</script>