<script>
import ProgressCountsBase from "@/views/tools/ProgressCountsBase";

export default {
  name: "RegionificationCounts",
  extends: ProgressCountsBase,
  methods: {
    getData () {
      return this.$store.dispatch('getRegionCounts');
    }
  }
}
</script>