<template>
    <div class="owned_components">

        <!-- Segmented control for owned components -->
        <div class="segmented_control">
            <input type="checkbox" id="item" v-model="hasItem" disabled>
            <label for="item">Item</label>

            <input type="checkbox" id="manual" v-model="hasManual" disabled>
            <label for="manual">Manual</label>

            <input type="checkbox" id="box" v-model="hasBox" disabled>
            <label for="box">Box</label>

            <input type="checkbox" id="other" v-model="hasOther" disabled>
            <label for="other">Other</label>

            <input type="checkbox" id="sealed" v-model="isSealedComputed" disabled>
            <label for="sealed">Sealed</label>
        </div>

        <!-- ratings for item, manual, box -->
        <div class="ratings" >
            <div v-if="hasItemNotSealed">
                <label>Item {{ itemRatingStr }}</label>
            </div>

            <div v-if="hasManualNotSealed">
                <label>Manual {{ manualRatingStr }}</label>
            </div>

            <div v-if="hasBox">
                <label>Box {{ boxRatingStr }}</label>
            </div>
        </div>

    </div>
</template>

<script>
import * as OwnershipMask from "@/store/ownershipMask.js";
export default {
  name: "OwnedComponents",
  props: {
      ownershipMask: {
        type: Number,
        required: true
      },
      itemRating: {
        type: Number,
        default: undefined
      },
      manualRating: {
        type: Number,
        default: undefined
      },
      boxRating: {
        type: Number,
        default: undefined
      }
  },
  computed: {
    isUnknown() {
        return this.ownershipMask === OwnershipMask.UNKNOWN;
    },
    hasItem () {
      return this.hasComponent(OwnershipMask.HAVE_ITEM) || this.isSealed();
    },
    hasItemNotSealed () {
      return !this.isSealed() && this.hasComponent(OwnershipMask.HAVE_ITEM);
    },
    hasManual () {
      return this.hasComponent(OwnershipMask.HAVE_MANUAL) || this.isSealed();
    },
    hasManualNotSealed () {
      return !this.isSealed() && this.hasComponent(OwnershipMask.HAVE_MANUAL);
    },
    hasBox () {
      return this.hasComponent(OwnershipMask.HAVE_BOX) || this.isSealed();
    },
    hasOther () {
      return this.hasComponent(OwnershipMask.HAVE_ALL_INSERTS) || this.isSealed();
    },
    isSealedComputed() {
      return this.isSealed();
    },
    itemRatingStr () {
        let rating = (this.itemRating === undefined ? 0.0 : this.itemRating) * 10.0;
        return rating.toFixed(1) + " out of 10 stars"
    },
    manualRatingStr () {
        let rating = (this.manualRating === undefined ? 0.0 : this.manualRating) * 10.0;
        return rating.toFixed(1) + " out of 10 stars"
    },
    boxRatingStr () {
        let rating = (this.boxRating === undefined ? 0.0 : this.boxRating) * 10.0;
        return rating.toFixed(1) + " out of 10 stars"
    }
  },
  methods: {
    hasComponent (component) {
      return (this.ownershipMask & component) === component; 
    },
    isSealed() {
      return this.ownershipMask === OwnershipMask.SEALED;
    }
  }
}
</script>

<style scoped lang="scss">
.segmented_control {
  display: inline-flex;

  input[type="checkbox"] { display: none; }

  $border-width: 2px;

  label {
    border: $border-width solid slategrey;
    padding: 4px 8px;
    background: rgba(slategrey, .2);

    text-transform: uppercase;
    color: slategrey;
    font-size: 14px;
    font-weight: bold;

    cursor: default;
    margin: 0;  /* Ensures the labels are tightly aligned */

    &:first-of-type {
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-of-type {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-right: $border-width solid slategrey;
    }

    &:not(:last-of-type) {
      border-right: none;  /* Merge the segments visually */
    }
  }

  input:checked + label {
    background: var(--gameye-blue);
    color: white;
    cursor: default;
  }
}
</style>
