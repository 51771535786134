<template>
  <login-modal hide-backdrop value no-close-on-backdrop no-close-on-esc hide-header-close :signup="signup"></login-modal>
</template>

<script>
import LoginModal from "@/components/LoginModal";

export default {
  name: 'LoginPage',
  components: {
    LoginModal
  },
  props: {
    signup: {
      type: Boolean,
      default: false
    }
  }
}
</script>
