import axios from "./axios";
import _ from "lodash";
import itemCache from "./itemCache";
import {getJsonFromHead} from "@/mixins/utils.js";

export default _.merge(
  {},
  itemCache,
  {
    namespaced: true,
    actions: {
      getItemHistory(context, itemId) {
        let reqs = [axios.get("/edits", { params: { item_id: itemId } })];
        return Promise.all(reqs).then((responses) => {
          var item = responses[0].data;
          return item;
        });
      },
      getItems(context, params) {
        return axios.get("/deep_search", params).then((response) => {
          context.commit("setItems", response.data.records);
          if (_.isNumber(response.data.full_count)) context.commit("setItemsCount", response.data.full_count);
          return response.data.records;
        });
      },
      getFilteredItems(context , filter) {
        return axios.get("/deep_search", { params: { title: filter } }).then((response) => {
          context.commit("setItems", response.data.records);
          if (_.isNumber(response.data.full_count)) context.commit("setItemsCount", response.data.full_count);
          return response.data.records;
        });
      },
      getItemDetails({}, itemId) {
        let pageSpecificHead = getJsonFromHead("page_specific");
        if (pageSpecificHead && pageSpecificHead.item_id === parseInt(itemId)) {
          console.log("got item from head", pageSpecificHead);
          return Promise.resolve(pageSpecificHead);
        }
        return axios.get("/items/" + itemId).then((response) => {
          return response.data;
        });
      },
      getOwnershipItemDetails({}, ownershipUUID) {
        return axios.get("/user_data/" + ownershipUUID).then((response) => {
          return response.data;
        });
      },
      getMultipleItemDetails({}, itemIds) {
        return axios.post("/updates_items", {item_ids: itemIds});
      }
    }
  }
);