<template>
  <div class="r-item">
    <div id="mainWrapper">
      <div :class="['main', { selected: selected }]" @click="clicked">
        <div class="img" style="height: 210px; background-color: #1a1a1a">
          <g-image class="flex-col center" hide-blur-hash fluid
            :src="vgpcItem.image_url"
            :draw-width="185"
            :draw-height="210"
          ></g-image>
        </div>
        <div class="details">
          <div style="font-size: 1.2em">{{vgpcItem.title}}</div>
          <div class="flex">
            <platform :platform-id="vgpcItem.platform_id"></platform>
          </div>
          <div>
            <pill v-if="vgpcItem.release_date" :text="Date.parse(vgpcItem.release_date) / 1000 | year" tag="span"></pill>
            <pill v-if="vgpcItem.region" :text="vgpcItem.region" tag="span"></pill>
          </div>
        </div>
        <div class="overlay" style="position: absolute; top: 0; height: 100%; width: 100%; z-index: 45; background-color: rgba(255,255,255,0.2)">
          <b-icon-check-2 style="height: 100%; width: 100%; font-size: 80px"></b-icon-check-2>
        </div>
      </div>
    </div>
    <div style="width: 100%; text-align: right; padding: 5px">
      <a :href="vgpcItem.vgpc_url" target="_blank">
        View on VGPC <b-icon-box-arrow-up-right></b-icon-box-arrow-up-right>
      </a>
    </div>
  </div>
</template>

<script>
import GImage from '@/components/GImage';
import Platform from '@/components/Platform';
import Pill from '@/components/Pill';
import ItemData from '@/mixins/EncyclopediaListItemDataMixin';
import {BIconCheck2, BIconBoxArrowUpRight} from 'bootstrap-vue';
import _ from "underscore";

export default {
  name: 'VGPCListItem',
  mixins: [ItemData],
  components: {
    GImage,
    Platform,
    Pill,
    BIconCheck2,
    BIconBoxArrowUpRight
  },
  computed: {
    vgpcItem () {
      // Clear out all "none"s
      return _.mapObject(this.item, v => {
        return v !== "none" ? v : undefined;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.r-item {
  padding-bottom: 15px;
  #mainWrapper {
    height: 100%;
  }
  .main {
    position: relative;
    height: 100%;
    width: 250px;
    display: flex;
    flex-direction: column;
    box-shadow: 3px 0 3px rgba(0,0,0,0.35);
    background-color: #2b2d32;
    padding-top: 15px;
    cursor: pointer;
    &:not(.selected) {
      .overlay {
        display: none;
      }
    }
    .details {
      padding: 5px;
      > div {
        margin-bottom: 5px;
        .pill {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }
}
</style>
