<template>
  <div :class="['pill', {'closeable': isCloseable}]">
    <b-badge pill :variant="variant" :tag="tag">
      <div class="body">
        <b-icon class="ico" v-if="icon !== null" :icon="icon"></b-icon>
        <span><slot>{{text}}</slot></span>
        <b-icon class="close" v-if="isCloseable" icon="x-circle-fill" @click="closeClicked"></b-icon>
      </div>
    </b-badge>
  </div>
</template>

<script>
import { BBadge, BIcon } from 'bootstrap-vue'

export default {
  name: "PillComponent",
  components: {
    BBadge,
    BIcon
  },
  props: {
    icon: {
      type: String,
      default: null
    },
    text: {
      type: [String, Number]
    },
    variant: {
      type: String,
      default: 'dark'
    },
    isCloseable: {
      type: Boolean,
      default: false
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
  computed: {},
  methods: {
    closeClicked () {
      this.$emit('close-clicked');
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.pill {
  .body {
    display: flex;
    align-items: center;
  }
  .badge-dark {
    color: #BEBCBC;
  }
  .badge {
    line-height: 1.25em;
    font-weight: 500;
  }
  .ico {
    margin-right: 3px;
    height: 1.3em;
    width: 1.3em;
    vertical-align: -0.2em;
  }
  .close {
    height: 1rem;
    width: 1rem;
    margin-left: 10px;
    color: white;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
      opacity: 1;
    }
  }
}
</style>
