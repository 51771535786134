import _ from 'underscore';

export default {
  computed: {
    imageTypes () {
      return this.metadata ? _.sortBy(Object.keys(this.metadata.image_types), type => { return this.metadata.image_types[type].type; }) : [];
    }
  },
  methods: {
    getImageURLByType(imgPath, sizeIdx, type = 'IMAGE') {
      let baseUrlKey = this.$options.IMAGE_TYPES_BASE_URL_META_KEYS[type];
      return this.metadata[baseUrlKey] + this.metadata.image_sizes[sizeIdx].url_add + imgPath;
    },
    getBlurHashHeight(imgH, imgW, maxH, maxW) {
      let scale = Math.min(maxW/imgW, maxH/imgH, 1);
      return Math.round(imgH * scale);
    },
    getBlurHashWidth(imgH, imgW, maxH, maxW) {
      let scale = Math.min(maxW/imgW, maxH/imgH, 1);
      return Math.round(imgW * scale);
    },
    getImagesByTypeFromRecord(images, type) {
      return images ? images[this.metadata.image_types[type].type] : undefined;
    }
  }
}

/* Here are the dimensions of the different image levels:
Name - mad dim
T1 - 128
T2 - 200
T3 - 300
Med - 512
Large - 1024
XL - 2048
Original - Unbounded */