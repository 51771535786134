<template>
  <div>
    <div style="display: flex; flex-direction: column; align-items: center;">
      <!-- Carousel goes here-->
      <div class="carousel section">
        <div :class="{comparison: !!previousImages}">
          <div class="type-group flex-col" v-for="(tImages, type) in images" :key="type + '-images'">
            <template v-if="previousImages && previousImages[type]">
              <div class="images flex center w-100" style="column-gap: 16px">
                <div :class="['img-container flex', {selected: selectable && (getMaxImages(type) === 1 && !selectedImages[type + '~~' + idx] || selectedPrevImages[type + '~~' + idx]), singular: getMaxImages(type)}]" v-for="(image, idx) in previousImages[type]" :key="image.File">
                  <div class="w-100 flex-col" style="min-height: 146px">
                    <div v-if="selectable && getMaxImages(type) === 1">
                      <b-form-checkbox class="center" v-model="selectedImages[type + '~~' + idx]" :value="false" :unchecked-value="true" @change="imageSelectChanged" />
                    </div>
                    <div v-else-if="selectable">
                      <b-form-checkbox inline class="center" v-model="selectedPrevImages[type + '~~' + idx]" @change="imageSelectChanged" />
                    </div>
                    <g-image hide-blur-hash fluid :allow-overflow="allowImageOverflow" center
                      :image-object="image.url ? undefined : image"
                      :src="image.url"
                      :draw-width="146"
                      :draw-height="146"
                      :punch="0.5"
                      :image-size-idx="2"
                      class="preview-img"
                      :ref="'prev-img-' + type + '-' + idx"
                      @click="imageClicked(idx, type, previousImages)"
                    ></g-image>
                    <template v-if="showAttribute">
                      <div class="custom-attr">
                        <template v-if="customAttrs['prev-img-' + type + '-' + idx]">
                          {{customAttrs['prev-img-' + type + '-' + idx][showAttribute]}}
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
                <div v-if="isEmpty(previousImages[type])" :class="['img-container flex', {selected: selectable && getMaxImages(type) === 1 && !selectedImages[type + '~~' + 0]}]">
                  <div style="min-height: 146px" class="w-100 flex center v-center grey italic">No Image(s)</div>
                </div>
              </div>
              <b-icon style="align-self: center" :icon="getMaxImages(type) === 1 ? 'arrow-down-short' : 'plus'" scale="1.5" class="m-1" />
            </template>
            <div class="images flex" style="column-gap: 16px">
              <div :class="['img-container flex', {selected: selectable && selectedImages[type + '~~' + idx], singular: getMaxImages(type)}]" v-for="(image, idx) in tImages" :key="image.File">
                <div class="w-100 flex-col" style="min-height: 146px">
                  <div v-if="selectable"><b-form-checkbox class="center" v-model="selectedImages[type + '~~' + idx]" @change="imageSelectChanged"/></div>
                  <g-image hide-blur-hash fluid :allow-overflow="allowImageOverflow" center
                    :image-object="image.url ? undefined : image"
                    :src="image.url"
                    :draw-width="146"
                    :draw-height="146"
                    :punch="0.5"
                    :image-size-idx="2"
                    class="preview-img"
                    :ref="'img-' + type + '-' + idx"
                    @click="imageClicked(idx, type)"
                  ></g-image>
                  <template v-if="showAttribute">
                    <div class="custom-attr">
                      <template v-if="customAttrs['img-' + type + '-' + idx]">
                        {{customAttrs['img-' + type + '-' + idx][showAttribute]}}
                      </template>
                    </div>
                  </template>
                </div>
                <div v-if="editMode" class="preview-controls flex-col">
                  <div class="replace" v-if="(getMaxImages(type) <= 1)" @click="$emit('replace', {image: image, type: type})">
                    <b-icon icon="cloud-upload-fill" font-scale="0.9" variant="primary" title="Replace Image"/>
                  </div>
                  <div class="edit" @click="$emit('edit', {image: image, type: type, attribution: image.Attribution})">
                    <b-icon icon="pencil-fill" font-scale="0.9" title="Edit Image"/>
                  </div>
                  <div class="delete" @click="$emit('delete', {image: image, type: type})">
                    <b-icon icon="trash-fill" font-scale="0.9" variant="danger" title="Remove Image" />
                  </div>
                </div>
              </div>
              <div class="img-container add-new" v-if="editMode && (getMaxImages(type) === undefined || images[type].length < getMaxImages(type))" style="position: relative" @click="$emit('addNew', type)">
                <div class="absolute-center flex-col center">
                  <b-icon icon="plus" font-scale="1.4" />
                  <div style="font-size: 0.7em">Add New</div>
                </div>
              </div>
            </div>
            <div class="grey type-label" style="font-size: 0.7rem; white-space: nowrap">{{type}}</div>
          </div>
        </div>
      </div>
    </div>
    <b-modal modal-class="img-overlay" size="xl" hide-header hide-footer v-model="showFullSizeImg" v-if="imageIdx !== undefined" centered>
      <g-image hide-blur-hash fluid
        :image-object="imageListObj[imageType][imageIdx].url ? undefined : imageListObj[imageType][imageIdx]"
        :src="imageListObj[imageType][imageIdx].url"
        :key="imageListObj[imageType][imageIdx].File"
        :image-size-idx="5"
      ></g-image>
    </b-modal>
  </div>
</template>

<script>
import l_ from "lodash";
import Vue from "vue";
import {BModal, BIcon} from "bootstrap-vue";
import ImageHelperMixin from "@/mixins/ImageHelperMixin";
import GImage from "@/components/GImage";

export default {
  name: "ImageCarousel",
  mixins: [ImageHelperMixin],
  components: {
    BModal,
    BIcon,
    GImage
  },
  props: {
    images: {
      type: Object,
      required: true
    },
    previousImages: {
      type: Object //expecting same format as images
    },
    editMode: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    allowImageOverflow: {
      type: Boolean,
      default: true
    },
    showAttribute: {
      type: String,
      validator: val => ["resolution"].includes(val)
    }
  },
  computed: {
    windowHeight () {
      return window.innerHeight - 90;
    },
    windowWidth () {
      return window.innerWidth - 200;
    }
  },
  data () {
    return {
      imageIdx: undefined,
      imageType: undefined,
      imageListObj: undefined,
      showFullSizeImg: false,
      selectedImages: {},
      selectedPrevImages: {},
      customAttrs: {}
    }
  },
  created () {
    l_.each(this.images, (typeImages, type) => {
      l_.each(typeImages, (img, idx) => {
        Vue.set(this.selectedImages, type + "~~" + idx, true);
        this.getCustomAttributes(img, 'img-' + type + '-' + idx)
      })
    })
    l_.each(this.previousImages, (typeImages, type) => {
      l_.each(typeImages, (img, idx) => {
        if (this.getMaxImages(type) > 1) Vue.set(this.selectedPrevImages, type + "~~" + idx, true);
        this.getCustomAttributes(img, 'prev-img-' + type + '-' + idx);
      })
    })
    this.imageSelectChanged();
  },
  methods: {
    imageClicked (idx, type, images = this.images) {
      this.imageIdx = idx;
      this.imageType = type;
      this.imageListObj = images;
      this.setShowFullImg(true);
    },
    setShowFullImg (val) {
      this.showFullSizeImg = val;
    },
    getTypeObj (type) {
      return this.metadata.image_types[type];
    },
    getMaxImages (type) {
      return this.getTypeObj(type).max;
    },
    imageSelectChanged () {
      let obj = {};
      l_.each(this.selectedImages, (val, typeIdxKey) => {
        let keys = typeIdxKey.split("~~");
        l_.set(obj, [...["add"], ...keys], val);
      })
      l_.each(this.selectedPrevImages, (val, typeIdxKey) => {
        let keys = typeIdxKey.split("~~");
        l_.set(obj, [...["remove"], ...keys], val === false);
      })
      this.$emit("selectChanged", obj);
    },
    getCustomAttributes (imgObj, htmlRefName) {
      if (!this.showAttribute) return;
      var setAttr = (val) => {
        this.$nextTick(() => {
          Vue.set(this.customAttrs, htmlRefName, {
            [this.showAttribute]: val
          });
        })
      }
      if (this.showAttribute === "resolution") {
        if (imgObj.Width && imgObj.Height) {
          setAttr(`${imgObj.Width} x ${imgObj.Height}`);
        } else if (imgObj.resolution) {
          setAttr(`${imgObj.resolution.split('x')[0]} x ${imgObj.resolution.split('x')[1]}`)
        } else {
          const interval = setInterval(() => {
            if (this.$refs && !_.isEmpty(this.$refs[htmlRefName])) {
              let image = this.$refs[htmlRefName][0].$el.querySelector("img");
              if (!image.naturalWidth || !image.naturalHeight) return;
              this.$nextTick(() => {
                setAttr(`${image.naturalWidth} x ${image.naturalHeight}`)
              })
              clearInterval(interval);
            }
          }, 50)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  width: 100%;
}

.carousel > div {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  column-gap: 12px;
  margin: 12px 0;
  padding: 12px 0;
}

.carousel .type-group > .images:first-child {
  border-radius: 8px 8px 0 0;
  overflow: hidden;

}

.carousel .img-container {
  margin-bottom: 8px;
  width: 146px;
  text-align: center;
  &.selected {
    background-color: var(--primary);
  }
  .preview-img {
    background-color: #000;
    cursor: pointer;
  }
  &.add-new {
    cursor: pointer;
    background: transparent;
    border: 1px dashed var(--gray);
    color: var(--gray);
    min-width: 100px;
    min-height: 75px;
    .b-icon {
      margin-bottom: 4px;
    }
    &:hover {
      color: #E0E0E0;
      background: rgba(0,0,0,0.1);
    }
  }
  .preview-controls {
    height: 100%;
    padding: 3px 6px;
    margin-left: 2px;
    background-color: rgba(0,0,0,0.2);
    border-radius: 0 4px 4px 0;
    justify-content: space-around;
    .edit, .delete {
      opacity: 0.6;
    }
    .replace {
      opacity: 0.8;
    }
    .edit, .delete, .replace {
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
}

.type-group {
  background-color: var(--card-bg);
  border-radius: 12px;
  padding: 12px 12px 6px;
}

.img-overlay {
  //These line up with top bar and side bar sizes
  margin-top: 90px;
}

.full-img-link {
  font-size: 12px;
}

.full-img-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

::v-deep .img-overlay .modal-content {
  background-color: transparent;
  border: none;
}

.comparison {
  .type-label {
    margin-top: 4px;
  }
  .images {
    background-color: #ffffff11;
  }
}

.custom-attr {
  align-self: right;
  justify-self: right;
  font-size: 0.8em;
  margin-top: 0.2em;
  color: var(--grey-font-color);
}
</style>
