<template>
  <div>
    <multiselect v-if="type === 'select' || type === 'multiselect'" :multiple="type === 'multiselect'" v-model="lFieldValue" v-bind="$attrs" :options="getOptions()" :label="selectLabel" :track-by="selectValue" select-label="" deselect-label="" selected-label="" :allow-empty="!required" @select="$emit('select', $event)">
      <template v-for="name in overriddenSelectSlots" v-slot:[name]="{option}">
        <slot :name="name" v-bind="option">
          <div class="flex" :key="name">
            <b-img v-if="getOptionIconImg(option)" :src="getOptionIconImg(option)" :title="option[selectLabel]" height="20" style="margin-right: 0.5em"/>
            <icon-set v-else-if="getOptionIcon(option)" :icon="getOptionIcon(option)" height="20" style="margin-right: 8px" />
            <span>{{option[selectLabel]}}</span>
          </div>
        </slot>
      </template>
    </multiselect>
    <b-form-datepicker v-else-if="type === 'date'" v-model="lFieldValue" dark value-as-date :required="required" no-flip show-decade-nav/>
    <div v-else-if="type.startsWith('multi-')" style="flex-grow: 1" class="multi-input">
      <div v-for="(v, i) in lFieldValue" :key="i" class="input flex v-center">
        <custom-input v-model="lFieldValue[i]" :type="type.slice(6)" required :select-config="selectConfig" v-bind="$attrs"></custom-input>
        <b-icon-x-lg class="error pointer" title="remove" style="margin-left: 4px" @click="removeMultiTextAt(i)" />
      </div>
      <div v-if="!lFieldValue || lFieldValue.length === 0" class="italic grey">None</div>
      <b-button variant="link" size="sm" @click="addMultiTextVal" :disabled="!!lFieldValue && lFieldValue.length >= 5">+ add</b-button>
    </div>
    <div v-else-if="type === 'boolean'" style="flex-grow: 1">
      <b-form-radio-group v-model="lFieldValue" buttons>
        <b-form-radio button :value="true" :button-variant="lFieldValue ? 'primary' : 'outline-primary'">Yes</b-form-radio>
        <b-form-radio button :value="false" :button-variant="lFieldValue ? 'outline-secondary' : 'secondary'">No</b-form-radio>
      </b-form-radio-group>
    </div>
    <div v-else-if="type === 'text-area'" style="flex-grow: 1">
      <textarea v-model="lFieldValue" style="width: 100%" rows="12" />
    </div>
    <div v-else-if="type === 'image'">
      <image-selector @input="selectImage" />
      <!-- currently assumes any passed in value is a URL (not image source) -->
      <g-img v-if="!selectedImage" :url="!_.isObject(lFieldValue) ? lFieldValue : undefined" :image-type="$attrs.imageType" :image-object="_.isObject(lFieldValue) ? lFieldValue : undefined" hide-blur-hash />
    </div>
    <b-form-input v-else v-model="lFieldValue" :number="type === 'number'" style="flex-grow: 1" :type="type" min="1" :required="required" v-on:keyup.enter="submit" v-bind="$attrs"></b-form-input>
  </div>
</template>

<script>
import { BFormInput, BFormDatepicker, BFormRadioGroup, BFormRadio, BImg } from "bootstrap-vue";
import Multiselect from "vue-multiselect";
import ImageSelector from "@/components/ImageSelector";
import GImg from "@/components/GImage";
import IconSet from "@/components/IconSet";
import _ from "underscore";

export default {
  name: "CustomInput",
  components: {
    BFormInput,
    BFormDatepicker,
    BFormRadioGroup,
    BFormRadio,
    BImg,
    Multiselect,
    ImageSelector,
    GImg,
    IconSet
  },
  props: {
    value: {
      required: true
    },
    type: {
      type: String,
      default: "text"
    },
    required: {
      type: Boolean,
      default: false
    },
    selectConfig: {
      // Expects: options, key (optional), value (optional), returnValue (optional)
      type: Object
    }
  },
  data () {
    this._ = _;
    return {
      lFieldValue: '',
      selectedImage: undefined
    }
  },
  watch: {
    value: {
      handler (newVal) {
        this.initData(newVal);
      },
      deep: true,
      immediate: true
    },
    lFieldValue: {
      handler (newVal) {
        console.log("new value", newVal)
        if (!_.isEqual(this.getValue(newVal), this.value)) this.updated(newVal);
      },
      deep: true
    }
  },
  computed: {
    overriddenSelectSlots () {
      return _.filter(['option', 'singleLabel'], s => { return this.selectConfig.getIconImg || this.selectConfig.getIcon || _.some(this.getOptions(), o => { return _.has(o, 'icon') }) || _.has(this.$scopedSlots, s); });
    },
    selectLabel () {
      return this.selectConfig && this.selectConfig.label ? this.selectConfig.label : 'name';
    },
    selectValue () {
      return this.selectConfig && this.selectConfig.value ? this.selectConfig.value : 'id';
    }
  },
  methods: {
    initData (val) {
      let newVal = ["image", "boolean"].includes(this.type) ? val : JSON.parse(JSON.stringify(val || this.value || ''));
      if (this.type === "date" && newVal) {
        let d = new Date(newVal * 1000);
        let month = `${d.getUTCMonth()+1}`.padStart(2, "0")
        let day = `${d.getUTCDate()}`.padStart(2, "0")
        this.lFieldValue = `${d.getUTCFullYear()}-${month}-${day}`;
      } else if (_.contains(['select', 'multiselect'], this.type) && this.selectConfig.returnValue) {
        // only do this when returnValue is set because that means we need to find the value from the list of objects
        let find = v => { return _.findWhere(this.getOptions(), {[this.selectValue]: v}); }
        if (this.type === 'select') this.lFieldValue = find(val);
        else this.lFieldValue = _.map(val, v => { return find(v) });
      } else
        this.lFieldValue = newVal;
    },
    updated (val) {
      this.$emit("input", this.getValue(val));
    },
    getOptions () {
      if (this.selectConfig && this.selectConfig.options) {
        let opts = _.isFunction(this.selectConfig.options) ? this.selectConfig.options(this) : this.selectConfig.options;
        if (!this.selectConfig.noSort) opts = _.sortBy(opts, this.selectValue);
        return opts;
      }
      else return [];
    },
    getOptionIconImg (opt) {
      return this.selectConfig.getIconImg ? this.selectConfig.getIconImg(opt, this) : undefined;
    },
    getOptionIcon (opt) {
      return opt.icon || (this.selectConfig.getIcon ? this.selectConfig.getIcon(opt) : undefined);
    },
    getValue (val) {
      let retVal;
      if (this.type === "date") retVal = new Date(val).getTime() / 1000;
      else if (this.type === 'select' && this.selectConfig.returnValue) retVal = _.get(this.lFieldValue, this.selectValue);
      else if (this.type === 'multiselect' && this.selectConfig.returnValue) retVal = _.map(this.lFieldValue, v => { return _.get(v, this.selectValue); });
      else retVal = val;
      if (retVal === undefined) retVal = null;
      return retVal;
    },
    clearValue () {
      this.lFieldValue = null;
    },
    addMultiTextVal () {
      if (!this.lFieldValue) this.lFieldValue = [];
      this.lFieldValue.push(''); 
    },
    removeMultiTextAt (index) {
      this.lFieldValue.splice(index, 1);
    },
    submit () {
      this.$emit('submit')
    },
    isFile (f) {
      return f instanceof File;
    },
    selectImage (i) {
      this.selectedImage = i;
      this.lFieldValue = i;
    }
  }
}
</script>
