<template>
  <div>
    <div style="display: grid; grid-template-columns: 2fr 1fr">
      <template v-for="(c, idx) in countData">
        <div :key="c.platform">{{c.platform}}</div>
        <div :key="idx">{{c.count | formattedNumber}}</div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressCountsBase",
  data () {
    return {
      countData: []
    }
  },
  methods: {
    getData () {
      console.error("This method should be overridden!");
    }
  },
  created () {
    this.getData().then(response => {
      this.countData = response.counts;
    });
  }
}
</script>