<template>
  <div class="user_item_details">
    <!-- Owned Components View
    A view that has a method of selecting whether or not an entry is digital
    If the entry is not digital, you can select "item/manual/box/other/new"
    If digital, hide the "item/manual/box/other/new" view -->
    <div class="owned_components">
        <!-- Digital checkbox -->
        <div class="is_digital">
          <label>Is Digital Copy
            <input type="checkbox" v-model="isDigital" disabled="true">
          </label>
        </div>
        <!-- item/manual/box/other/new multiselect/grouped check/something -->    
        <div class="physical_owned_components" v-if="!isDigital">
          <owned-components :ownership-mask="itemDetails.ownership_mask" :item-rating="itemDetails.item_quality" :manual-rating="itemDetails.manual_quality" :box-rating="itemDetails.box_quality"/>
        </div>
    </div>
    

    <!-- Notes View -->
    <div class="notes">
      <editable-field :editable="false" field-name="notes" :field-value="itemDetails.notes" type="text-area">
        <p ref="Notes">{{this.itemDetails.notes || 'Enter notes about item here'}}</p>
      </editable-field>
    </div>

    <!-- Tags View -->
    <div class="tags">
      <tag-pill v-for="tag in itemDetails.tags" :text="tag.tag" tag="span" :key="tag.uuid" :text-color="tagTextColorForID(tag.color_id)" :background-color="tagColorForID(tag.color_id)"></tag-pill>
    </div>
    
  </div>
</template>
  
<script>
  import * as OwnershipMask from "@/store/ownershipMask.js";
  import OwnedComponents from '@/components/OwnedComponents';
  import EditableField from '@/components/EditableField';
  import TagPill from '@/components/TagPill';
  export default {
    name: "UserItemDetails",
    components: {
      OwnedComponents,
      EditableField,
      TagPill
    },
    props: {
        itemDetails: {
          type: Object,
          required: true
        },
    },
    computed: {
      isDigital () {
        return this.itemDetails.ownership_mask === OwnershipMask.DIGITAL;
      }
    },
    data () {
      return {
      }
    },
    methods: {
    }
  }  
</script>