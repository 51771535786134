<template>
  <b-form @submit.prevent="signIn" ref="form">
    <div class="input-with-icon input-group" style="margin-bottom: 20px">
      <b-form-input v-model="email" placeholder="Email" type="email" ref="email"></b-form-input>
      <span>
        <b-icon-envelope></b-icon-envelope>
      </span>
    </div>
    <div class="input-with-icon input-group">
      <b-form-input v-model="password" type="password" placeholder="Password"></b-form-input>
      <span>
        <b-icon-lock></b-icon-lock>
      </span>
    </div>
    <div class="links flex-col center">
      <a href @click.prevent="forgotPassword">Forgot your Password?</a>
      <a href @click.prevent="signUp" v-if="showSignupLink">
        Need an Account?
      </a>
    </div>
    <div class="buttons">
      <loading-button spinner-variant="light" :loading="loggingIn" @click="submit" variant="primary" :disabled="!signInFormValid()" ref="signin">Sign In</loading-button>
      <b-button v-show="false" ref="submitBtn" type="submit"></b-button>
    </div>
    <div v-if="networkMsg" :class="['network-msg', {'error': networkMsg.error, 'success': networkMsg.success}]">
      <b-icon-exclamation-triangle-fill variant="danger" v-if="networkMsg.error"></b-icon-exclamation-triangle-fill>
      &nbsp;&nbsp;{{networkMsg.msg}}
    </div>
  </b-form>
</template>

<script>
import { BForm, BFormInput, BIconEnvelope, BIconLock, BIconExclamationTriangleFill } from 'bootstrap-vue';
import LoadingButton from '@/components/LoadingButton';

export default {
  name: 'LoginForm',
  components: {
    BForm,
    BFormInput,
    BIconEnvelope,
    BIconLock,
    BIconExclamationTriangleFill,
    LoadingButton
  },
  props: {
    showSignupLink: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      email: undefined,
      password: undefined,
      networkMsg: undefined,
      loggingIn: false
    }
  },
  methods: {
    signInFormValid () {
      return this.password && this.password !== '' && this.$refs.email.checkValidity();
    },
    signIn () {
      console.log('Signing in..');
      this.networkMsg = undefined;
      this.loggingIn = true;
      return this.$store.dispatch('signInAction', { email: this.email, password: this.password })
      .then(() => {
        this.$store.dispatch('getUserInfo').then(() => {
          this.$emit('login');
        })
        .catch(err => {
          this.$store.dispatch('signOutAction').then(() => {
            console.error('logged into fb, failed for us. this shouldnt happen!', err);
            this.networkMsg = {msg: 'There was an error logging in.', error: true};
            this.$emit('error', this.networkMsg.msg);
          })
        });
      })
      .catch(err => {
        console.error('issue signing in', err);
        this.networkMsg = {msg: 'There was an error logging in.', error: true};
        this.$emit('error', this.networkMsg.msg);
      })
      .finally(() => {
        this.loggingIn = false;
      });
    },
    submit () {
      this.$refs.submitBtn.click();
    },
    forgotPassword () {
      if (!this.email || !this.$refs.email.checkValidity()) {
        this.networkMsg = {
          error: true,
          msg: 'Must provide a valid email'
        }
        return;
      }
      this.$store.dispatch('sendPasswordReset', this.email)
        .then(() => {
          this.networkMsg = {
            success: true,
            msg: 'An email has been sent'
          }
        })
        .catch(e => {
          console.error('password reset failed', e);
          this.networkMsg = {
            error: true,
            msg: 'There was an error sending the email'
          }
        })
    },
    signUp () {
      this.$emit('signup');
    }
  }
}
</script>

<style lang="scss" scoped>
.links {
  margin: 20px 0 30px 0;
  font-size: 0.8rem;
}

.links > a {
  color: white;
  text-decoration: none;
  border-bottom: 1px solid #777;
  padding-bottom: 4px;
  &:not(:first-child) {
    padding-top: 0.75em;
  }
}

.links > a:hover {
  border-bottom: 1px solid white;
}

button {
  width: 75%;
}

.network-msg {
  width: 100%;
  margin-top: 8px;
  font-size: 0.8em;
}

.error {
  color: var(--error-text);
}

.success {
  color: var(--success-text);
}
</style>
