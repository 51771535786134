import axios from "@/store/axios";
import { getAuth, onAuthStateChanged, getIdTokenResult } from "firebase/auth";
import _ from "lodash";

export const updateAuthToken = (auth) => {
  if (auth) {
    localStorage.setItem('auth', JSON.stringify(auth));
    axios.defaults.headers.common['Authorization'] = `Bearer ${auth.token}`;
  } else {
    localStorage.removeItem('auth');
    delete axios.defaults.headers.common['Authorization'];
  }
};

export const getUserAuth = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        getIdTokenResult(user)
          .then(result => {
            updateAuthToken({token: result.token, expires: result.expirationTime});
            resolve({user, unsubscribe});
          })
      } else {
        updateAuthToken(null);
        resolve({user, unsubscribe});
      }
    }, reject);
  });
};

export const getCurrentUser = () => {
  return getUserAuth().then(({user, unsubscribe}) => {
    unsubscribe();
    return user;
  });
};

export const deepGet = (obj, key) => {
  return _.get(obj, key);
}

export const deepSet = (obj, key, val) => {
  return _.set(obj, key, val);
}

export const parseRGBFromHex = (hex) => {
  let color = (hex.charAt(0) === '#') ? hex.substring(1, 7) : hex;
  let r = parseInt(color.substring(0, 2), 16); // hexToR
  let g = parseInt(color.substring(2, 4), 16); // hexToG
  let b = parseInt(color.substring(4, 6), 16); // hexToB
  return {r, g, b};
}

const parseScaledRGBFromHex = (hex) => {
  let rgb = parseRGBFromHex(hex);
  let uicolors = [rgb.r / 255, rgb.g / 255, rgb.b / 255];
  let c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  return c;
}

const getLuminanceFromRGB = (r, g, b) => {
  return (0.2126 * r) + (0.7152 * g) + (0.0722 * b);
}

export const textShouldBeDark = (bgColor, darkText = '#000000', lightText = '#ffffff') => {
  if (!bgColor) return false;
  let bgRGB = parseScaledRGBFromHex(bgColor);
  var L = getLuminanceFromRGB(bgRGB[0], bgRGB[1], bgRGB[2]);

  let darkRGB = parseScaledRGBFromHex(darkText);
  let dL = getLuminanceFromRGB(darkRGB[0], darkRGB[2], darkRGB[2]);

  let lightRGB = parseScaledRGBFromHex(lightText);
  let lL = getLuminanceFromRGB(lightRGB[0], lightRGB[2], lightRGB[2]);

  // with black, was 0.179. calculated with badge background
  return (L > Math.sqrt((lL + 0.05)*(dL + 0.05)) - 0.05) ? true : false;
}

export const textColorFromBgColor = (bgColor, darkText = '#000000', lightText = '#ffffff') => {
  return textShouldBeDark(bgColor, darkText, lightText) ? darkText : lightText;
}

export const getJsonFromHead = (key) => {
  let head = document.getElementsByTagName('head')[0];
  let html = head.querySelector("#" + key);
  if (html && html.innerHTML) {
    let json;
    try {
      json = JSON.parse(html.innerHTML);
    } catch (e) { /*any type of parsing issue, return undefined*/ }
    return json;
  }
}

export default { updateAuthToken, getUserAuth, getCurrentUser, deepGet, deepSet, textShouldBeDark, parseRGBFromHex, getJsonFromHead };
