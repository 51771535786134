<template>
  <component :is="as">
    <b-spinner v-if="resendingVerification" style="width: 0.8em; height: 0.8em" small />
    <b-icon v-if="!resendingVerification && sentVerification" :title="sentVerification.error" font-scale="0.8" :icon="sentVerification.success ? 'check' : 'exclamation'" :class="{'text-success': sentVerification.success, 'text-danger': sentVerification.error}"/>
    <a v-if="!loggedInUser.verified" href @click.prevent="resendVerification" style="font-size: 0.8em" class="btn-link">
      <slot></slot>
    </a>
  </component>
</template>

<script>
import {BSpinner, BIcon} from "bootstrap-vue";
export default {
  name: "ResendVerificationLink",
  components: {BSpinner, BIcon},
  props: {
    as: {
      type: String,
      default: "span"
    }
  },
  data () {
    return {
      resendingVerification: false,
      sentVerification: undefined
    }
  },
  methods: {
    resendVerification () {
      this.resendingVerification = true;
      this.$store.dispatch('resendVerificationAction')
        .then(() => {
          this.sentVerification = {success: true};
          setTimeout(() => {
            this.sentVerification = undefined;
          }, 2000);
        })
        .catch(e => {
          console.log('error sending verification', e);
          this.sentVerification = {error: e.code}
        })
        .finally(() => {
          this.resendingVerification = false;
        })
    }
  }
}
</script>
