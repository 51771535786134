<template>
  <div>
    <div>
      <b-modal visible :busy="loading" hide-footer hide-header no-close-on-backdrop no-close-on-esc no-stack id="fbauthmodal">
        <div>
          <div v-if="$options.ACTION_TYPES.VERIFY_EMAIL === mode">
            <div v-if="loading">
              Validating e-mail <b-icon-three-dots animation="fade"></b-icon-three-dots>
            </div>
            <div v-else>
              <div v-if="success">
                E-mail has been verified!
                <div>You will be automatically redirected in {{countdown}}<b-icon-three-dots></b-icon-three-dots></div>
              </div>
              <div v-else>
                Uh oh! There was an error validating your e-mail!
                <div>Double-check the link or try <router-link :to="{name: 'Login'}">logging in</router-link> or going <router-link :to="{name: 'Home'}">home</router-link>.</div>
              </div>
            </div>
          </div>
          <div v-else-if="$options.ACTION_TYPES.RESET_PASSWORD === mode">
            <b-form class="reset-pass-form">
              <div style="margin-bottom: 8px">Select a New Password</div>
              <password-field v-model="pass" ref="newPass" />
              <div style="float: right; margin-top: 16px">
                <b-button variant="primary" :disabled="!$refs.newPass || !$refs.newPass.passwordState" @click="saveNewPass">Save</b-button>
              </div>
            </b-form>
          </div>
          <div class="text-danger" v-if="error">{{error}}</div>
          <div class="text-success" v-if="success">Password successfully reset! You'll be automatically redirected in {{countdown}}</div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {BModal, BIconThreeDots} from "bootstrap-vue";
import _ from "underscore";
import PasswordField from "@/components/PasswordField";

const actionTypes = {
  RESET_PASSWORD: 'resetPassword',
  RECOVER_EMAIL: 'recoverEmail',
  VERIFY_EMAIL: 'verifyEmail'
};

export default {
  name: "FirebaseAuthHandler",
  components: {
    BModal,
    BIconThreeDots,
    PasswordField
  },
  props: {
    mode: {
      type: String,
      required: true,
      validator: val => {
        return _.values(actionTypes).indexOf(val) >= 0;
      }
    },
    fbCode: {
      type: String,
      required: true
    },
    apiKey: {
      type: String,
      required: true
    },
    continueUrl: {
      type: String
    }
  },
  ACTION_TYPES: actionTypes,
  data () {
    return {
      loading: false,
      success: undefined,
      error: undefined,
      countdown: 4,
      pass: undefined
    }
  },
  created () {
    if (this.mode === this.$options.ACTION_TYPES.VERIFY_EMAIL) {
      this.success = false;
      this.loading = true;
      this.$store.dispatch('validateEmail', { oob_code: this.fbCode, public_fb_key: this.apiKey })
      .then(() => {
        this.success = true;
        this.doCountdown();
      })
      .catch(err => {
        console.error('error verifying e-mail', err);
        this.error = err;
      })
      .finally(() => {
        this.loading = false;
      });
    } else if (this.mode === this.$options.ACTION_TYPES.RESET_PASSWORD) {
      this.$store.dispatch('checkPasswordResetCode', this.fbCode)
      .catch(err => {
        console.error('error with reset code', err);
        this.error = err;
      })
    }
  },
  methods: {
    doCountdown () {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown -= 1;
          this.doCountdown();
        }, 1000);
      } else {
        this.$router.push({name: this.$store.state.user ? 'Home' : 'Login'});
      }
    },
    saveNewPass () {
      this.$store.dispatch('resetPassword', {password: this.pass, fbCode: this.fbCode})
        .then(() => {
          this.success = true;
          this.doCountdown();
        });
    }
  }
}
</script>

<style lang="scss">
/* Required to get it to go on top of side nav */
#fbauthmodal___BV_modal_outer_ {
  z-index: 2000 !important;
  .bi-three-dots {
    margin-bottom: -0.3em;
  }
}
</style>
