<template>
  <b-button v-bind="$attrs" @click.prevent="clicked" ref="btn">
    <slot v-if="!isLoading"></slot>
    <b-spinner v-else :variant="$attrs['spinner-variant']" style="width: 1rem; height: 1rem"></b-spinner>
  </b-button>
</template>

<script>
import {BSpinner} from "bootstrap-vue";
export default {
  name: "LoadingButton",
  inheritAttrs: false,
  components: {
    BSpinner
  },
  props: {
    loading: {
      type: Boolean
    }
  },
  watch: {
    loading (newVal) {
      this.isLoading = newVal;
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    clicked (e) {
      if (this.$attrs['async-func']) {
        this.isLoading = true;
        this.$attrs['async-func']()
        .then(() => {
          this.$emit('saved', e);
        })
        .finally(() => {
          this.isLoading = false;
        });
      }
      this.$emit('click', e);
    }
  }
}
</script>